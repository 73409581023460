import {
    all,
    call,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    select
} from 'redux-saga/effects';

import {
    GET_DOCUMENTS,
    LOGOUT_USER,
    UPLOAD_STORAGE_DOCUMENT,
    UPDATE_DOCUMENT,
    ADD_DOCUMENT_TO_TRANSACTION,
    // REFRESH_TRANSACTION_DOCUMENTS,
    REMOVE_DOCUMENT_FROM_TRANSACTION,
    REMOVE_DOCUMENT
} from '../actions/types';

import { confirmSaga } from './Modal';
import { eventChannel } from 'redux-saga';

import * as selectors from './Selectors';

import {
    storingUserDocuments,
    storingOrgDocuments,
    uploadDocumentSuccess,
    uploadDocumentFailure
} from '../actions/Documents';
import { setConfirmModalType } from '../actions/Modal';

import {
    db,
    rtdb,
    storage,
    timeStampNow,
    timeStampJs,
    fsFieldValue
} from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

// Consts
import { confirmationDialogTypes } from '../../utils/Constants';

const trxs = db.collection('transactions');
const annotatedDocs = db.collection('annotated_docs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Organization ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* docCollectionWatch(user) {
    const documentsRef = rtdb.ref(`documents/${user.id}`);
    const orgDocumentsRef = rtdb.ref(`orgs/${user.active_org_id}/documents`);

    const userDocCollectionChannel = eventChannel(emit => {
        const unsubscribeUserDocData = documentsRef.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var documents = [];
                documents = Object.values(querySnapshot.val());
                emit(documents);
            } else {
                const doc = { empty: true };
                emit({ doc });
            }
        });
        return unsubscribeUserDocData;
    });

    const orgDocCollectionChannel = eventChannel(emit => {
        const unsubscribeOrgDocData = orgDocumentsRef.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var documents = [];
                documents = Object.values(querySnapshot.val());
                emit(documents);
            } else {
                const doc = { empty: true };
                emit({ doc });
            }
        });
        return unsubscribeOrgDocData;
    });

    try {
        while (true) {
            const { userSignOut, userDocData, orgDocData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userDocData: take(userDocCollectionChannel),
                orgDocData: take(orgDocCollectionChannel)
            });

            if (userSignOut) {
                userDocCollectionChannel.close(); // Detach saga event emitter
                orgDocCollectionChannel.close(); // Detach saga event emitter
            }
            if (userDocData) yield put(storingUserDocuments(userDocData));
            if (orgDocData) yield put(storingOrgDocuments(orgDocData));
        }
    } catch (error) {
        log('Documents Error: getting documents collection data on User/Org (RTDB)', {
            error,
            user,
            function: 'docCollectionWatch'
        });
    } finally {
        documentsRef.off('value'); // Detach firebase listener
        orgDocumentsRef.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            documentsRef.off('value'); // Detach firebase listener
            orgDocumentsRef.off('value'); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Upload User Storage Document /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const generateUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

const uploadingUserDocumentRequest = async ({ src, uploader_id }) => {
    const docRef = generateUid();
    const storageRef = storage.ref();
    const fileRef = storageRef.child('users').child(uploader_id).child(docRef);
    try {
        const snapshot = await fileRef.put(src);
        const url = await snapshot.ref.getDownloadURL();
        return { downloadUrl: [url, docRef] };
    } catch (error) {
        return { error };
    }
};

export function* uploadingUserDocument({ payload }) {
    const { src, uploader_id, org_id, upload_type, type, title, fillable, library } =
        payload;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));

    const { downloadUrl, error } = yield call(() =>
        uploadingUserDocumentRequest({ src, uploader_id })
    );
    if (downloadUrl) {
        yield call(writingDocumentUrl, {
            url: downloadUrl[0],
            id: downloadUrl[1],
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            library
        });
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        log('Documents Error: storing user document (RTDB)', {
            error,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            library,
            function: 'uploadingUserDocument'
        });
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Remove User Storage (RTDB) Document //////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingDocumentFromRtdb = async ({ document }) => {
    const { id, uploader_id } = document;
    const rtdbDocumentsRef = rtdb.ref(`documents/${uploader_id}/${id}`);

    return rtdbDocumentsRef
        .remove()
        .then(() => ({ rtdbRes: true }))
        .catch(error => ({ rtdbError: error }));
};

const removingDocumentFromStorage = async ({ document }) => {
    const { id, uploader_id } = document;
    const documentRef = storage.ref().child(`users/${uploader_id}/${id}`);

    return documentRef
        .delete()
        .then(() => ({ storageRes: true }))
        .catch(error => ({ storageError: error }));
};

export function* removeUserDocument({ payload }) {
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });

    if (isConfirm) {
        const { document, type } = payload;
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { rtdbRes, rtdbError } = yield call(() =>
            removingDocumentFromRtdb({
                document
            })
        );

        if (rtdbRes) {
            const { storageRes, storageError } = yield call(() =>
                removingDocumentFromStorage({
                    document
                })
            );

            if (storageRes) {
                yield put(setConfirmModalType(confirmationDialogTypes.success));
            } else {
                yield put(setConfirmModalType(confirmationDialogTypes.failed));
                log(`Documents Error: removing document from ${type} (RTDB/Storage)`, {
                    error: storageError,
                    document,
                    function: 'removeUserDocument'
                });
            }
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Documents Error: removing document from ${type} (RTDB/Storage)`, {
                error: rtdbError,
                document,
                function: 'removeUserDocument'
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Firestore Doc /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const writingDocumentUrlRequest = ({
    url,
    id,
    src,
    uploader_id,
    org_id,
    upload_type,
    type,
    title,
    fillable,
    library,
    userData
}) => {
    return new Promise((resolve, reject) => {
        const pathType = () => {
            if (library) {
                return 'orgs';
            } else {
                return 'documents';
            }
        };

        const getRef = () => {
            if (pathType() === 'documents') {
                return rtdb.ref(`${pathType()}/${uploader_id}/${id}`);
            } else {
                return rtdb.ref(
                    `${pathType()}/${userData.active_org_id}/documents/${id}`
                );
            }
        };

        const docRef = getRef();
        const timeStamp = timeStampNow();

        docRef.set(
            {
                created_at: src?.lastModifiedDate
                    ? timeStampJs.fromDate(src.lastModifiedDate)
                    : timeStamp,
                edited_at: null,
                id,
                mime_type: type,
                name: src.name,
                org_id,
                title: title ? title : src.name,
                fillable,
                library,
                upload_type,
                uploaded_at: timeStamp,
                uploader_id,
                url
            },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        );
    });
};

export function* writingDocumentUrl({
    url,
    id,
    src,
    uploader_id,
    org_id,
    upload_type,
    type,
    title,
    fillable,
    library
}) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        writingDocumentUrlRequest({
            url,
            id,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            library,
            userData
        })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        log('Documents Error: uploading user document (RTDB)', {
            error,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            library,
            function: 'writingDocumentUrl'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Doc Meta Data /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updatingDocMetaRequest = ({ id, upload_type, title, library, userData }) => {
    return new Promise((resolve, reject) => {
        const pathType = () => {
            if (library) {
                return 'orgs';
            } else {
                return 'documents';
            }
        };

        const getRef = () => {
            if (pathType() === 'documents') {
                return rtdb.ref(`${pathType()}/${userData.id}/${id}`);
            } else {
                return rtdb.ref(
                    `${pathType()}/${userData.active_org_id}/documents/${id}`
                );
            }
        };

        const docRef = getRef();
        const timeStamp = timeStampNow();

        docRef.update(
            {
                id,
                title,
                upload_type,
                edited_at: timeStamp
            },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        );
    });
};

export function* updatingDocMeta({ payload }) {
    const { id, upload_type, title, library } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        updatingDocMetaRequest({
            id,
            library,
            upload_type,
            title,
            userData
        })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: updating document meta data (RTDB)', {
            error,
            id,
            upload_type,
            title,
            library,
            userData,
            function: 'updatingDocMeta'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Add Doc To Transaction(s) ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addingSingleDocumentToTransaction = async ({
    transactions,
    userData,
    document
}) => {
    return new Promise((resolve, reject) => {
        const trxId = transactions[0].id;
        const ref = trxs.doc(trxId);
        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: document.title
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: `Added a new document: `
        };

        const newTrxDoc = annotatedDocs.doc();

        const doc = {
            ...document,
            status: document.fillable ? 'incomplete' : null,
            locked: document.fillable ? false : null,
            added_at: timeStampNow(),
            trx_doc_id: newTrxDoc.id
        };

        ref.update({
            documents: fsFieldValue.arrayUnion(doc)
        })
            .then(() => {
                const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                const postRef = activityRef.push();
                postRef.set({ ...activity }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ res: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const addingMultipleDocumentsToTransaction = async ({
    transactions,
    userData,
    document
}) => {
    /*eslint-disable */
    var batch = db.batch();
    var count = 0;
    while (transactions.length) {
        const newTrxDoc = annotatedDocs.doc();
        const doc = {
            ...document,
            status: document.fillable ? 'incomplete' : null,
            locked: document.fillable ? false : null,
            added_at: timeStampNow(),
            trx_doc_id: newTrxDoc.id
        };
        const trxId = transactions[0].id;
        const activityRef = rtdb.ref(`trx_activity/${trxId}`);
        const postRef = activityRef.push();
        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: document.title
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: `Added a new document: `
        };

        const writePostBatch = async (activity, trxId) => {
            await postRef.set({ ...activity }, error => {
                if (error) {
                    return { error };
                }
                batch.update(trxs.doc(trxId), {
                    documents: fsFieldValue.arrayUnion(doc)
                });
                transactions.shift();
                count++;
            });
        };

        await writePostBatch(activity, trxId);

        if (count === 500 || !transactions.length) {
            return await batch
                .commit()
                .then(() => {
                    count = 0;
                    batch = db.batch();
                    if (!transactions.length) return { res: true };
                })
                .catch(error => {
                    return { error };
                });
        }
    }
};

export function* addingDocumentToTransaction({ payload }) {
    const { transactions, userData, document } = payload;
    const { res, error } = yield call(() =>
        transactions.length > 1
            ? addingMultipleDocumentsToTransaction({
                  transactions,
                  userData,
                  document
              })
            : addingSingleDocumentToTransaction({
                  transactions,
                  userData,
                  document
              })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: adding document to transaction(s) (FS)', {
            error,
            transactions,
            userData,
            document,
            function: 'addingDocumentToTransaction'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////// Remove Doc From Transaction(s) /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingDocumentFromTransactionRequest = async ({
    documents,
    userData,
    id,
    title
}) => {
    return new Promise((resolve, reject) => {
        const trxId = id;
        const ref = trxs.doc(trxId);
        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: title
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: `Removed a document: `
        };
        ref.update({
            documents
        })
            .then(async () => {
                const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                const postRef = activityRef.push();
                await postRef.set({ ...activity }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ res: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* removingDocumentFromTransaction({ payload }) {
    const { documents, userData, id, title } = payload;
    const { res, error } = yield call(() =>
        removingDocumentFromTransactionRequest({
            documents,
            userData,
            id,
            title
        })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: removing document from transaction (FS)', {
            error,
            documents,
            userData,
            id,
            title,
            function: 'removingDocumentFromTransaction'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Refresh Transaction Docs ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const checkForDocChanges = (doc, trxDoc) => {
//     if (
//         doc.title !== trxDoc.title ||
//         doc.upload_type !== trxDoc.upload_type ||
//         (doc.edited_at && doc.edited_at?.seconds !== trxDoc.edited_at?.seconds) ||
//         doc.fillable !== trxDoc.fillable
//     ) {
//         return true;
//     }

//     return false;
// };

// const buildUpdatedArray = documents => {
//     const dirtyDocs = [];
//     const cleanDocs = [];
//     const docCount = documents.length;

//     return new Promise((resolve, reject) => {
//         documents.forEach((doc, index) => {
//             const ref = rtdb.ref(`documents/${doc.uploader_id}/${doc.id}`);
//             ref.once('value')
//                 .then(snapshot => {
//                     if (checkForDocChanges(snapshot.val(), doc)) {
//                         const newDoc = {
//                             ...doc,
//                             title: snapshot.val().title ? snapshot.val().title : null,
//                             upload_type: snapshot.val().upload_type
//                                 ? snapshot.val().upload_type
//                                 : null,
//                             edited_at: snapshot.val().edited_at
//                                 ? snapshot.val().edited_at
//                                 : null,
//                             fillable: snapshot.val().fillable
//                                 ? snapshot.val().fillable
//                                 : null
//                         };
//                         dirtyDocs.push(newDoc);
//                         if (index === docCount - 1) {
//                             resolve({ clean: cleanDocs, dirty: dirtyDocs });
//                         }
//                     } else {
//                         cleanDocs.push(doc);
//                         if (index === docCount - 1) {
//                             resolve({ clean: cleanDocs, dirty: dirtyDocs });
//                         }
//                     }
//                 })
//                 .catch(error => {
//                     reject({ error });
//                 });
//         });
//     });
// };

// const refreshingTransactionDocumentsRequest = ({ documents, id }) => {
//     const ref = trxs.doc(id);
//     let updatedDocs;
//     return new Promise((resolve, reject) => {
//         buildUpdatedArray(documents).then(docs => {
//             if (!docs.error) {
//                 if (docs.dirty?.length) {
//                     updatedDocs = [].concat(docs.dirty, docs.clean);
//                     ref.update({
//                         documents: updatedDocs
//                     })
//                         .then(() => {
//                             resolve({ refreshedDocs: true });
//                         })
//                         .catch(error => {
//                             reject({ error });
//                         });
//                 } else {
//                     resolve({ refreshedDocs: true });
//                 }
//             } else {
//                 reject({ error: docs.error });
//             }
//         });
//     });
// };

// export function* refreshingTransactionDocuments(documents, id) {
//     if (documents && documents.length) {
//         const { refreshedDocs, error } = yield call(() =>
//             refreshingTransactionDocumentsRequest({
//                 documents,
//                 id
//             })
//         );
//         if (refreshedDocs) {
//             console.log('refreshed docs complete!!!');
//         } else {
//             log('Documents Error: refreshing transaction documents (FS)', {
//                 error,
//                 documents,
//                 id,
//                 function: 'refreshingTransactionDocuments'
//             });
//             yield put(uploadDocumentFailure());
//         }
//     } else {
//         console.log('no update needed');
//     }
// }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getDocCollection() {
    yield takeLatest(GET_DOCUMENTS, docCollectionWatch);
}

export function* uploadDocument() {
    yield takeLatest(UPLOAD_STORAGE_DOCUMENT, uploadingUserDocument);
}

export function* updateDocument() {
    yield takeLatest(UPDATE_DOCUMENT, updatingDocMeta);
}

export function* removeDocument() {
    yield takeLatest(REMOVE_DOCUMENT, removeUserDocument);
}

export function* addDocumentToTransaction() {
    yield takeLatest(ADD_DOCUMENT_TO_TRANSACTION, addingDocumentToTransaction);
}

export function* removeDocumentFromTransaction() {
    yield takeLatest(REMOVE_DOCUMENT_FROM_TRANSACTION, removingDocumentFromTransaction);
}

// export function* refreshTransactionDocuments() {
//     yield takeLatest(REFRESH_TRANSACTION_DOCUMENTS, refreshingTransactionDocuments);
// }

export default function* rootSaga() {
    yield all([
        fork(getDocCollection),
        fork(uploadDocument),
        fork(updateDocument),
        fork(removeDocument),
        fork(addDocumentToTransaction),
        fork(removeDocumentFromTransaction)
    ]);
}
