import {
    SET_COMPANY_CONTACTS,
    SET_USER_COLLECTION,
    SET_CONTACTS_FILTER,
    ADD_NEW_CONTACTS,
    ADD_NEW_CONTACTS_SUCCESS,
    ADD_NEW_CONTACTS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    companyContacts: [],
    filterType: {
        name: 'All Contacts',
        value: 'all'
    },
    uploadError: null
};
const filterTypes = {
    en: {
        all: {
            name: 'All Contacts',
            value: 'all'
        },
        active: {
            name: 'Active Contacts',
            value: 'active'
        },
        inactive: {
            name: 'Inactive Contacts',
            value: 'inactive'
        }
    },
    es: {
        all: {
            name: 'Todos Contactos',
            value: 'all'
        },
        active: {
            name: 'Contactos Activos',
            value: 'active'
        },
        inactive: {
            name: 'Contactos Inactivos',
            value: 'inactive'
        }
    }
};
const contactsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COMPANY_CONTACTS:
            return {
                ...state,
                companyContacts: action.payload
            };
        case SET_USER_COLLECTION:
            return {
                ...state,
                filterType: filterTypes[action.payload.language][state.filterType.value]
            };
        case SET_CONTACTS_FILTER:
            return {
                ...state,
                filterType: action.payload
            };
        case ADD_NEW_CONTACTS:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        default:
            return state;
    }
};

export default contactsReducer;
