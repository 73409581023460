// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import { AvatarEditModal } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useDropzone } from 'react-dropzone';

// Redux - Actions, Reducers, Sagas
import { uploadUserAvatar } from '../../store/actions/Settings';

// Router
import * as routes from '../../router/config/routes';

const SettingsMenu = ({ match, userData, uploadUserAvatar, loading, uploadError }) => {
    const [source, setSource] = useState(null);
    const [avatarModal, setAvatarModal] = useState(false);
    const [activeUpload, setActiveUpload] = useState(false);
    const [pristine, setPristine] = useState(false);
    const maxSize = 2097152;
    const {
        personal_info_link,
        organization_info_link,
        team_link,
        preferences_link,
        security_settings_link,
        billing_link,
        app_integratons_link,
        edit_label,
        file_size_warning
    } = useLang()['Settings']['SettingsMenu'];

    const settingsLinks = [
        {
            name: personal_info_link,
            route: `${match.url}${routes.PERSONAL_INFO}`,
            icon: `id-card`
        },
        {
            name: organization_info_link,
            route: `${match.url}${routes.ORGANIZATION_INFO}`,
            icon: `building`
        },
        {
            name: team_link,
            route: `${match.url}${routes.TEAM}`,
            icon: `user-friends`
        },
        {
            name: preferences_link,
            route: `${match.url}${routes.PREFERENCES}`,
            icon: `sliders-h`
        },
        {
            name: security_settings_link,
            route: `${match.url}${routes.SECURITY_SETTINGS}`,
            icon: `fingerprint`
        },
        {
            name: billing_link,
            route: `${match.url}${routes.BILLING}`,
            icon: `file-invoice-dollar`
        },
        {
            name: app_integratons_link,
            route: `${match.url}${routes.APP_INTEGRATIONS}`,
            icon: `puzzle-piece`
        }
    ];

    const toggleModal = useCallback(({ reset, cancel }) => {
        if (reset || cancel) {
            setActiveUpload(false);
            setAvatarModal(upload => !upload);
        } else {
            setAvatarModal(upload => !upload);
        }
    }, []);

    useEffect(() => {
        if (activeUpload) {
            if (!loading && !uploadError && !pristine) {
                toggleModal({ reset: true, cancel: false });
            } else if (loading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                // console.log('notify user of error');
            }
        } else {
            setPristine(true);
        }
    }, [activeUpload, loading, pristine, toggleModal, uploadError]);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const url = URL.createObjectURL(acceptedFiles[0]);
                setSource(url);
                setActiveUpload(true);
                toggleModal({ reset: false, cancel: false });
            }
        },
        [toggleModal, setActiveUpload]
    );

    const uploadAvatar = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            blob => {
                const url = URL.createObjectURL(blob);
                uploadUserAvatar({ img: url });
                setPristine(false);
            },
            'image/jpeg',
            0.25
        );
    };

    const { isDragActive, getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg',
        minSize: 0,
        maxSize
    });

    const isFileTooLarge =
        fileRejections?.length > 0 && fileRejections[0].file.size > maxSize;

    return (
        <>
            <AvatarEditModal
                source={source}
                setSource={setSource}
                avatarModal={avatarModal}
                toggleModal={toggleModal}
                uploadAvatar={uploadAvatar}
                loading={loading}
            />
            <div className="d-none d-xl-block col-3 pr-3">
                <div className="card" style={{ minHeight: '70vh', height: '100%' }}>
                    <div
                        className="card-body"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '0'
                        }}
                    >
                        {userData && (
                            <div className="d-flex u-xl-avatar mb-3 mt-4 mx-auto">
                                <div className="d-flex" {...getRootProps()}>
                                    <div
                                        className="u-dropzone"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            minWidth: '160px',
                                            minHeight: '160px',
                                            backgroundColor: 'transparent',
                                            padding: 0,
                                            borderRadius: 0,
                                            border: isDragActive
                                                ? '1px dashed #377dff'
                                                : 'none'
                                        }}
                                    >
                                        <input {...getInputProps()} />

                                        {userData?.user_avatar ? (
                                            <>
                                                <img
                                                    src={userData?.user_avatar}
                                                    className="img-fluid rounded-circle"
                                                    alt={`${userData?.first_name} ${userData?.last_name}`}
                                                />
                                                <div
                                                    className="d-flex position-relative justify-content-center align-items-center"
                                                    style={{
                                                        bottom: '1.5rem',
                                                        fontSize: '10px',
                                                        width: '47%',
                                                        height: '8.75%',
                                                        backgroundColor:
                                                            'rgba(0, 0, 0, 0.54)',
                                                        borderRadius: '5px',
                                                        color: '#ffffff'
                                                    }}
                                                >
                                                    <span>{edit_label}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary rounded-circle"
                                                    name={`${userData?.first_name} ${userData?.last_name}`}
                                                    unstyled
                                                />
                                                <div
                                                    className="d-flex position-relative justify-content-center align-items-center"
                                                    style={{
                                                        bottom: '1.5rem',
                                                        fontSize: '10px',
                                                        width: '47%',
                                                        height: '8.75%',
                                                        backgroundColor:
                                                            'rgba(0, 0, 0, 0.54)',
                                                        borderRadius: '5px',
                                                        color: '#ffffff'
                                                    }}
                                                >
                                                    <span>{edit_label}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isFileTooLarge && (
                            <div className="text-center text-danger text-small">
                                {file_size_warning}
                            </div>
                        )}
                        <div className="text-center mb-4">
                            <h1 className="h5 font-weight-medium mb-0">{`${userData?.first_name} ${userData?.last_name}`}</h1>
                            <p>{`${userData?.email}`}</p>
                        </div>
                        <hr className="mr-3 ml-3" />
                        <SimpleBar
                            id="settings-menu"
                            className="list-unstyled u-sidebar--account__list"
                            style={{ overflowY: 'auto', height: '100%' }}
                        >
                            {settingsLinks.map(item => (
                                <li
                                    className="u-sidebar--account__list-item"
                                    key={item.name}
                                >
                                    <NavLink
                                        to={item.route}
                                        className="nav-link u-header__nav-link"
                                        activeClassName="active"
                                    >
                                        <span className={`fas fa-${item.icon} mr-2`} />
                                        {item.name}
                                    </NavLink>
                                </li>
                            ))}
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ user, settings }) => {
    const { userData } = user;
    const { loading, uploadError } = settings;
    return { userData, loading, uploadError };
};

export default connect(mapStateToProps, { uploadUserAvatar })(SettingsMenu);
