// React
import React, { useState, useCallback } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import {
    UncontrolledCollapse,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';

// Router
import { withRouter } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { updateTransactionNotes } from '../../store/actions/Transactions';

const NoteList = ({ notes, trxId, updateTransactionNotes, isTransactionArchived }) => {
    const [openNotes, setOpenNotes] = useState([]);
    const [notesHeight, setNotesHeight] = useState(0);
    const [activeModal, setActiveModal] = useState(false);
    const [noteIndex, setNoteIndex] = useState();
    const [noteTitle, setNoteTitle] = useState();
    const { windowHeight } = useWindowSize();
    const { assurance_label, remove_label, cancel_label } =
        useLang()['TransactionDetails']['NoteList'];

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setNotesHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        // eslint-disable-next-line
        [windowHeight, isTransactionArchived]
    );

    const handleOpenNote = (id, title) => {
        let i;
        const activeNotes = [...openNotes];
        const found = activeNotes.some((note, index) => {
            i = index;
            return note === id;
        });
        found ? activeNotes.splice(i, 1) : activeNotes.push(id);
        setNoteTitle(title);
        setOpenNotes(activeNotes);
    };

    const openState = id => {
        const found = openNotes.some(note => {
            return note === id;
        });
        return found;
    };

    const handleRemoval = index => {
        setActiveModal(!activeModal);
        setNoteIndex(index);
    };

    const removeNote = () => {
        const notesArr = [...notes];
        notesArr.splice(noteIndex, 1);
        const noteData = {
            notes: notesArr,
            trxId,
            loading: false
        };
        updateTransactionNotes(noteData);
        setActiveModal(!activeModal);
    };

    return (
        <div ref={measuredRef}>
            <Modal isOpen={activeModal} toggle={() => setActiveModal(!activeModal)}>
                <ModalHeader toggle={() => setActiveModal(!activeModal)}>
                    {noteTitle}
                </ModalHeader>
                <ModalBody>{assurance_label}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={removeNote}>
                        {remove_label}
                    </Button>{' '}
                    <Button
                        color="secondary"
                        onClick={() => setActiveModal(!activeModal)}
                    >
                        {cancel_label}
                    </Button>
                </ModalFooter>
            </Modal>
            <SimpleBar style={{ height: notesHeight }}>
                {notes.map((note, index) => (
                    <div key={note.created_at.seconds} className="card">
                        <div className="card-header card-collapse">
                            <h5 className="mb-0">
                                <div
                                    type="button"
                                    id={`toggler${note.created_at.seconds}`}
                                    className={`btn btn-link btn-block d-flex justify-content-between card-btn ${
                                        openState(note.created_at.seconds)
                                            ? ''
                                            : 'collapsed'
                                    } p-3`}
                                    onClick={() =>
                                        handleOpenNote(
                                            note.created_at.seconds,
                                            note.title
                                        )
                                    }
                                >
                                    {note.title}
                                    <span className="card-btn-arrow">
                                        <span className="fas fa-arrow-down small" />
                                    </span>
                                </div>
                            </h5>
                        </div>
                        <UncontrolledCollapse
                            toggler={`#toggler${note.created_at.seconds}`}
                        >
                            <div className="row pl-3 pr-3">
                                <div className="card-body" style={{ width: '90%' }}>
                                    {note.content}
                                    {note.private && (
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <button
                                                type="button"
                                                className={`btn btn-icon ${
                                                    isTransactionArchived
                                                        ? 'btn-icon_disabled'
                                                        : 'transition-3d-hover'
                                                }`}
                                                disabled={isTransactionArchived}
                                            >
                                                <span className="fas fa-eye-slash btn-icon__inner text-primary" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    className={`btn btn-icon ${
                                        isTransactionArchived
                                            ? 'btn-icon_disabled'
                                            : 'transition-3d-hover'
                                    }`}
                                    onClick={() => handleRemoval(index)}
                                    disabled={isTransactionArchived}
                                >
                                    <span className="fas fa-trash text-primary btn-icon__inner" />
                                </button>
                            </div>
                        </UncontrolledCollapse>
                    </div>
                ))}
            </SimpleBar>
        </div>
    );
};

export default withRouter(connect(null, { updateTransactionNotes })(NoteList));
