import { all, fork, take, cancelled, takeLatest, race, put } from 'redux-saga/effects';

import {
    CANCEL_DETAILS_LISTENER,
    GET_TRANSACTION_DETAILS,
    LOGOUT_USER,
    SET_CLIENT_TRANSACTION_DETAILS
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import { settingTransactionActivity } from '../actions/TransactionActivity';

import { rtdb } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Get Transaction Activity //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* transactionActivityCollectionWatch({ payload }) {
    const { trxId, detailsFlag } = payload;
    const ref = rtdb.ref(`trx_activity/${trxId}`);

    const transactionActivityCollectionChannel = eventChannel(emit => {
        const unsubscribeTransactionActivityData = ref.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var transactionActivity = [];
                transactionActivity = Object.values(querySnapshot.val());
                emit(transactionActivity);
            } else {
                const doc = { exists: false };
                emit({ doc });
            }
        });
        return unsubscribeTransactionActivityData;
    });

    try {
        while (true) {
            const { cancelledDetails, userSignOut, transactionActivityData } = yield race(
                {
                    userSignOut: take(LOGOUT_USER),
                    cancelledDetails: take(CANCEL_DETAILS_LISTENER),
                    transactionActivityData: take(transactionActivityCollectionChannel)
                }
            );

            if (cancelledDetails || userSignOut) {
                transactionActivityCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(
                    settingTransactionActivity({
                        transactionActivity: transactionActivityData,
                        detailsFlag
                    })
                );
            }
        }
    } catch (error) {
        log('TransactionActivity Error: getting transaction activity data (RTDB)', {
            error,
            trxId,
            detailsFlag
        });
    } finally {
        ref.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            transactionActivityCollectionChannel.close(); // Detach saga event emitter
            ref.off('value'); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getTransactionActivity() {
    yield takeLatest(
        [GET_TRANSACTION_DETAILS, SET_CLIENT_TRANSACTION_DETAILS],
        transactionActivityCollectionWatch
    );
}

export default function* rootSaga() {
    yield all([fork(getTransactionActivity)]);
}
