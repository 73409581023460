// React
import React, { useEffect, useCallback } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Collapse } from 'reactstrap';

// Utils
import { formatDocumentDate } from '../../utils/Helpers';
import cx from 'classnames';

const DocumentDrop = ({
    dropState,
    documents,
    addedDocuments,
    setDropAction,
    userData,
    trxId,
    addDocumentToTrx,
    searchText,
    searchSuggestions,
    settingSearchSuggestions,
    lang
}) => {
    const { drag_drop_label, add_label, last_update_label } =
        useLang()['TransactionDetails']['DocumentDrop'];
    const handleDocumentAdd = doc => {
        const transactions = [{ id: trxId }];
        addDocumentToTrx({
            transactions,
            userData,
            document: doc
        });
    };

    const documentInputSearch = useCallback(
        input => {
            if (input.trim() !== '') {
                const filteredTitle = () => {
                    const titleSuggestions = [];
                    documents.filter(doc => {
                        if (
                            doc.title.toLowerCase() &&
                            doc.title.toLowerCase().includes(input.toLowerCase())
                        ) {
                            titleSuggestions.push({
                                ...doc
                            });
                        }
                        return true;
                    });
                    return titleSuggestions;
                };
                const finalSuggestions = [...filteredTitle()];
                settingSearchSuggestions(finalSuggestions);
            } else {
                settingSearchSuggestions([]);
            }
        },
        [documents, settingSearchSuggestions]
    );

    useEffect(() => {
        documentInputSearch(searchText);
    }, [searchText, documentInputSearch]);

    const documentList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : documents;

    if (!documents) return null;

    return (
        <Collapse
            isOpen={dropState}
            onEntered={() => setDropAction('opened')}
            onExited={() => setDropAction('closed')}
        >
            <div
                id="newDoc"
                className="pt-3 pb-3 px-4"
                style={{
                    backgroundColor: 'rgba(55, 125, 255, 0.08)'
                }}
            >
                <div
                    id="SVGIcon"
                    className="u-dropzone gradient-overlay-half-primary-v4 mb-5"
                    style={{ padding: 0 }}
                >
                    <div className="dz-message py-3">
                        <figure className="max-width-10 mx-auto mb-3">
                            <img
                                src="/images/drag-n-drop.svg"
                                alt="Drag N Drop"
                                data-parent="#SVGIcon"
                            />
                        </figure>
                        <span className="d-block">{drag_drop_label}</span>
                    </div>
                </div>
                <div
                    style={{
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    {documentList.map(doc => (
                        <div
                            className="d-flex justify-content-between align-items-center mb-3 pb-3 document-item"
                            key={doc.id}
                        >
                            <div className="media">
                                <div className="u-sm-avatar mt-1 mr-3">
                                    <img
                                        className="img-fluid"
                                        src="/images/pdf.svg"
                                        alt="Document"
                                    />
                                </div>
                                <div className="media-body">
                                    <span className="d-block text-dark">{doc.title}</span>
                                    <small className="d-block text-secondary">
                                        {`${last_update_label}: ${
                                            doc?.edited_at?.seconds
                                                ? formatDocumentDate(
                                                      doc.edited_at.seconds,
                                                      lang
                                                  )
                                                : formatDocumentDate(
                                                      doc?.created_at?.seconds,
                                                      lang
                                                  )
                                        }`}
                                    </small>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div
                                    className={cx(
                                        'btn btn-xs btn-outline-primary transition-3d-hover',
                                        {
                                            'bg-lilac': addedDocuments.some(
                                                item => doc.id === item.id
                                            )
                                        }
                                    )}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleDocumentAdd(doc)}
                                >
                                    <span className="fas fa-plus mr-1" />
                                    {add_label}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Collapse>
    );
};

export default DocumentDrop;
