// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import { TransactionUploadModal, DateTimePicker } from '../../components';
import MultiSelect, { Option, MultiValue, ValueContainer } from '../common/MultiSelect';

// Context
import { useLang } from '../../context/LangContext';

// Firebase
import { timeStampNow, timeStampJs } from '../../config/Firebase';

// Packages
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import {
    updateTransactionTasks,
    updateActivityPosts
} from '../../store/actions/Transactions';
import Loader from '../common/Loader';

const ActivityInput = ({
    tasks,
    userData,
    updateTransactionTasks,
    trxId,
    updateActivityPosts,
    loading,
    uploading,
    uploadError,
    team
}) => {
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [postText, setPostText] = useState('');
    const [taskDate, setTaskDate] = useState('');
    const [taskAssignees, setTaskAssignees] = useState([]);
    const [taskActive, setTaskActive] = useState(false);
    const [mimeCat, setMimeCat] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [source, setSource] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [postAttachment, setPostAttachment] = useState(null);
    const [fileTitle, setFileTitle] = useState('');
    const [activeUpload, setActiveUpload] = useState(false);
    const [pristine, setPristine] = useState(true);
    const [auto, setAuto] = useState(false);
    const photoTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
    const fileTypes = [
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'application/pdf',
        'application/zip',
        'application/gzip'
    ];
    const {
        added_task_label,
        upload_a_img_label,
        upload_a_file_label,
        image_label,
        file_label,
        whats_up_placeholder,
        task_title_placeholder,
        task_note_placeholder,
        date_time_placeholder,
        attachment_button_label,
        task_button_label,
        post_button_label,
        add_task_label,
        uploaded_label
    } = useLang()['TransactionDetails']['ActivityInput'];
    const { locale } = useLang()['DateTime'];
    const teamMembers = team.map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`
    }));

    const addTask = () => {
        const taskData = tasks && tasks.length ? [...tasks] : [];

        const formattedTask = {
            content: taskDescription,
            created_at: timeStampNow(),
            created_by: userData.id,
            date_time: timeStampJs.fromDate(taskDate),
            edited_at: null,
            status: 'open',
            title: taskTitle,
            assignees: taskAssignees
        };
        const formattedActivity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'task',
                name: taskTitle
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: added_task_label
        };
        taskData.push(formattedTask);
        updateTransactionTasks({
            tasks: taskData,
            trxId,
            posts: formattedActivity
        });
        setTaskDate(null);
        setTaskDescription('');
        setTaskTitle('');
        setTaskAssignees([]);
        setPostText('');
    };

    const addPost = attachment => {
        const formattedActivity = {
            archived_at: null,
            attachments: postAttachment
                ? [{ ...postAttachment }]
                : attachment
                ? [{ ...attachment }]
                : [],
            automated:
                postText.trim() !== ''
                    ? null
                    : {
                          type: mimeCat,
                          name: postAttachment?.title
                              ? postAttachment.title
                              : postAttachment?.src.name
                              ? postAttachment.src.name
                              : attachment?.title
                              ? attachment.title
                              : attachment.src.name
                      },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: setMessage()
        };
        updateActivityPosts({ posts: formattedActivity, trxId });
        if (attachment) setAuto(true);
        if (attachment || postAttachment) setPristine(false);
        setPostText('');
        setTaskDescription('');
        setTaskTitle('');
        setPostAttachment(null);
        setFileTitle('');
    };

    const toggleModal = useCallback(({ reset, auto, cancel }) => {
        if (reset && auto) {
            setActiveUpload(false);
            setUploadModal(upload => !upload);
        } else if (reset && !auto) {
            setActiveUpload(false);
        } else if (cancel) {
            setUploadModal(upload => !upload);
            setActiveUpload(false);
        } else {
            setUploadModal(upload => !upload);
        }
    }, []);

    useEffect(() => {
        if (activeUpload) {
            if (!uploading && !uploadError && !pristine) {
                toggleModal({ reset: true, auto: auto, cancel: false });
            } else if (uploading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                console.log('notify user of error');
            }
        } else {
            setPristine(true);
            setAuto(false);
        }
    }, [uploading, uploadError, activeUpload, pristine, auto, toggleModal]);

    const setMessage = () => {
        if (postText.trim() === '') {
            return `${
                postAttachment.file_type === 'photo'
                    ? upload_a_img_label
                    : upload_a_file_label
            }`;
        } else {
            return postText;
        }
    };

    const getMimeCat = useCallback(type => {
        const photoTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
        if (photoTypes.includes(type)) {
            return 'photo';
        } else {
            return 'file';
        }
    }, []);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const url = URL.createObjectURL(acceptedFiles[0]);
                setMimeCat(getMimeCat(acceptedFiles[0].type));
                setImageSrc(url);
                setSource(acceptedFiles[0]);
                if (!activeUpload) setActiveUpload(!activeUpload);
                toggleModal({ reset: null, auto: null, cancel: false });
            }
        },
        [getMimeCat, toggleModal, activeUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: [...photoTypes, ...fileTypes],
        minSize: 0
    });

    const isDisabled = () => {
        if (taskActive) {
            if (taskDate === '' || taskTitle === '') {
                return true;
            } else {
                return false;
            }
        } else {
            if (postText === '' && !postAttachment) {
                return true;
            } else {
                return false;
            }
        }
    };

    const handleSelect = selected => {
        setTaskAssignees(selected);
    };

    return (
        <div className="message-editor">
            <TransactionUploadModal
                source={source}
                setSource={setSource}
                uploadModal={uploadModal}
                toggleModal={toggleModal}
                loading={loading}
                mimeCat={mimeCat}
                setMimeCat={setMimeCat}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                postAttachment={postAttachment}
                setPostAttachment={setPostAttachment}
                uploadSend={addPost}
                fileTitle={fileTitle}
                setFileTitle={setFileTitle}
            />
            {!taskActive ? (
                <div>
                    <textarea
                        className="form-control"
                        style={{ resize: 'none' }}
                        placeholder={
                            postAttachment && postText === ''
                                ? `${
                                      postAttachment.file_type === 'photo'
                                          ? image_label
                                          : file_label
                                  } ${uploaded_label} ${
                                      postAttachment.title
                                          ? postAttachment.title
                                          : postAttachment.src.name
                                  }`
                                : whats_up_placeholder
                        }
                        rows="5"
                        value={postText}
                        onChange={e => setPostText(e.target.value)}
                    />
                </div>
            ) : (
                <form id="newTask" className="new-task-form pl-3">
                    <input
                        type="text"
                        className="form-control p-0"
                        placeholder={task_title_placeholder}
                        value={taskTitle}
                        onChange={e => setTaskTitle(e.target.value)}
                    />
                    <input
                        type="text"
                        className="form-control p-0"
                        placeholder={task_note_placeholder}
                        value={taskDescription}
                        onChange={e => setTaskDescription(e.target.value)}
                    />
                    <div className="d-flex align-items-center form-control p-0">
                        <MultiSelect
                            options={teamMembers}
                            isMulti
                            styles="plain"
                            placeholder="Assign to"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option,
                                MultiValue,
                                ValueContainer
                            }}
                            onChange={handleSelect}
                            allowSelectAll={true}
                            value={taskAssignees}
                        />
                    </div>
                    <DateTimePicker
                        myStyle={{
                            flexGrow: 1
                        }}
                        required
                        placeholder={date_time_placeholder}
                        className="js-range-datepicker form-control bg-white rounded-right flatpickr-input p-0"
                        name="trxMeta.fromDate"
                        id="trxMeta.fromDate"
                        aria-label="From Date"
                        value={taskDate}
                        options={{
                            enableTime: true,
                            dateFormat: 'F j  h:i K',
                            disableMobile: 'true',
                            onChange([date]) {
                                setTaskDate(date);
                            },
                            locale: locale
                        }}
                    />
                </form>
            )}
            <div className="d-flex mb-2 justify-content-start align-items-center">
                {loading && !uploadModal && (
                    <div className="row ml-3 align-items-center">
                        <Loader />
                    </div>
                )}

                <div {...getRootProps()} className="col-sm-auto">
                    <button
                        type="button"
                        className="btn btn-outline-primary btn-xs btn-pill"
                        onClick={() => {
                            setTaskActive(false);
                        }}
                    >
                        <input {...getInputProps()} />
                        <i className="fas fa-paperclip mr-1" />
                        {attachment_button_label}
                    </button>
                </div>
                <button
                    type="button"
                    className={`btn btn-outline-primary btn-xs btn-pill ${
                        taskActive ? 'active' : 'inactive'
                    }`}
                    onClick={() => {
                        setTaskActive(!taskActive);
                    }}
                >
                    <i className="fas fa-tasks mr-1" />
                    {task_button_label}
                </button>
                <button
                    type="button"
                    className="mr-4 ml-auto btn btn-sm btn-wide btn-primary btn-pill transition-3d-hover"
                    style={{ cursor: 'pointer' }}
                    onClick={() => (taskActive ? addTask() : addPost())}
                    disabled={isDisabled()}
                >
                    {taskActive ? add_task_label : post_button_label}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, transactions }) => {
    const { userData } = user;
    const { loading, uploading, uploadError } = transactions;
    return { userData, loading, uploading, uploadError };
};

export default withRouter(
    connect(mapStateToProps, { updateTransactionTasks, updateActivityPosts })(
        ActivityInput
    )
);
