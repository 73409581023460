// React
import React, { useEffect, useCallback, useState } from 'react';

// Components
import {
    ActionButtons,
    ConfirmationDialog,
    DatePicker,
    DetailsHeader,
    Loader,
    TextField
} from '../components';
import { Formik } from 'formik';
import { Label } from 'reactstrap';

// Packages
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import * as routes from '../router/config/routes';

// Redux - Actions, Reducers, Sagas
import {
    gettingTransactionDetails,
    updateTransactionDetails
} from '../store/actions/Transactions';
import { setConfirmModalType } from '../store/actions/Modal';

// Constants
import { confirmationDialogTypes, headerTypes } from '../utils/Constants';

// Context
import { useLang } from '../context/LangContext';

const EditTransaction = ({
    location,
    transactionDetails,
    gettingTransactionDetails,
    userData,
    setConfirmModalType,
    updateTransactionDetails
}) => {
    const history = useHistory();
    const { price_label, price_placeholder, closingDate_label, closingDate_placeholder } =
        useLang()['TransactionDetails']['EditTransactionDetails'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];
    const { locale } = useLang()['DateTime'];
    const transaction = transactionDetails;
    const [shouldConfirmDisplayed, setShouldConfirmDisplayed] = useState(false);

    const getTrxId = useCallback(() => {
        const pathname = window.location.pathname;
        if (location.state?.id) {
            if (!transactionDetails) {
                gettingTransactionDetails({
                    trxId: location.state.id,
                    detailsFlag: false
                });
            }
            return location.state.id;
        } else {
            const id = pathname.substr(pathname.lastIndexOf('/') + 1);
            gettingTransactionDetails({ trxId: id, detailsFlag: false });
            return id;
        }
    }, [transactionDetails, gettingTransactionDetails, location]);

    useEffect(() => {
        getTrxId();
    }, [getTrxId]);

    const backToTransactionDetails = () => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${transaction.id}`,
            state: { id: transaction.id }
        });
    };

    const cancelButtonClickHandler = () => {
        shouldConfirmDisplayed
            ? setConfirmModalType(confirmationDialogTypes.cancel)
            : backToTransactionDetails();
    };

    const initialValues = {
        closing_date: transactionDetails?.closing_date
            ? transactionDetails.closing_date.toDate()
            : '',
        price: transactionDetails?.price || ''
    };

    const validationSchema = Yup.object().shape({
        closing_date: Yup.string(),
        price: Yup.string().required(errorMessage.listingPrice.required)
    });

    if (!transaction) return <Loader />;
    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.cancel}
                confirmHandler={backToTransactionDetails}
                successHandler={backToTransactionDetails}
            />
            <DetailsHeader
                transaction={transaction}
                userData={userData}
                headerType={headerTypes.editTransaction}
                cancelButtonClickHandler={cancelButtonClickHandler}
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        <div
                            className="card position-relative"
                            style={{
                                minHeight: '70vh',
                                height: '100%',
                                marginBottom: '50px',
                                width: '100%'
                            }}
                        >
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize
                                onSubmit={values => {
                                    updateTransactionDetails({
                                        trxDetails: transactionDetails,
                                        userData,
                                        values
                                    });
                                }}
                            >
                                {({
                                    errors,
                                    values,
                                    dirty,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue
                                }) => (
                                    <>
                                        <div className="row mt-10">
                                            <div className="col-4 mb-2 offset-2">
                                                <div className="form-group">
                                                    <div className="js-focus-state">
                                                        <Label className="form-label">
                                                            {price_label}
                                                        </Label>

                                                        <div
                                                            className="input-group"
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'nowrap'
                                                            }}
                                                        >
                                                            <div
                                                                className="input-group-prepend"
                                                                style={{
                                                                    maxHeight: 50
                                                                }}
                                                            >
                                                                <span
                                                                    className="input-group-text"
                                                                    style={{
                                                                        borderColor:
                                                                            !!errors.price &&
                                                                            dirty
                                                                                ? '#de4437'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    <span className="fas fa-dollar-sign" />
                                                                </span>
                                                            </div>
                                                            <TextField
                                                                type="number"
                                                                myStyle={{
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 0,
                                                                    flexGrow: 1
                                                                }}
                                                                required
                                                                className="form-control"
                                                                name="price"
                                                                id="price"
                                                                placeholder={
                                                                    price_placeholder
                                                                }
                                                                value={values.price}
                                                                error={errors.price}
                                                                onChange={e => {
                                                                    setShouldConfirmDisplayed(
                                                                        true
                                                                    );
                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                invalid={
                                                                    !!errors.price &&
                                                                    dirty
                                                                }
                                                                hasDefaultFill
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-4 mb-2">
                                                <Label className="form-label">
                                                    {closingDate_label}
                                                </Label>

                                                <div
                                                    id="datepickerWrapperFrom"
                                                    className="js-focus-state u-datepicker input-group"
                                                    style={{
                                                        flexWrap: 'nowrap'
                                                    }}
                                                >
                                                    <div
                                                        className="input-group-prepend"
                                                        style={{
                                                            maxHeight: 50
                                                        }}
                                                    >
                                                        <span
                                                            id="calendarFromLabel"
                                                            className="input-group-text"
                                                            style={{
                                                                borderColor:
                                                                    !!errors.closing_date &&
                                                                    dirty
                                                                        ? '#de4437'
                                                                        : ''
                                                            }}
                                                        >
                                                            <span className="fas fa-calendar" />
                                                        </span>
                                                    </div>
                                                    <DatePicker
                                                        myStyle={{
                                                            flexGrow: 1
                                                        }}
                                                        className="js-range-datepicker form-control bg-white rounded-right flatpickr-input"
                                                        name="closing_date"
                                                        id="closing_date"
                                                        required
                                                        placeholder={
                                                            closingDate_placeholder
                                                        }
                                                        value={values.closing_date}
                                                        error={errors.closing_date}
                                                        onBlur={handleBlur}
                                                        invalid={
                                                            !!errors.closing_date && dirty
                                                        }
                                                        hasDefaultFill
                                                        options={{
                                                            enableTime: true,
                                                            dateFormat: 'F j  h:i K',
                                                            disableMobile: 'true',
                                                            onChange([date]) {
                                                                setShouldConfirmDisplayed(
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    'closing_date',
                                                                    date || ''
                                                                );
                                                            },
                                                            locale: locale
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <ActionButtons
                                            handleSubmit={handleSubmit}
                                            handleClose={cancelButtonClickHandler}
                                            cancelLabel={cancel_label}
                                            saveLabel={save_label}
                                        />
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ transactions, user }) => {
    const { userData } = user;
    const { transactionDetails } = transactions;
    return {
        transactionDetails,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        setConfirmModalType,
        updateTransactionDetails
    })(EditTransaction)
);
