import {
    CHECK_CONNECTION_EXISTENCE,
    STORE_CONNECTION_MATCHES,
    SET_CONNECTION_MATCH,
    CLEAR_CONNECTION_LINK,
    CLEAR_CONNECTION_PRIMARY,
    ADD_NEW_CONNECTION,
    ADD_NEW_CONNECTION_SUCCESS,
    ADD_NEW_CONNECTION_FAILURE,
    RESET_NEW_CONNECTION_DATA,
    SET_USER_CONNECTIONS
} from './types';

export const storingUserConnections = connections => {
    return {
        type: SET_USER_CONNECTIONS,
        payload: connections?.doc?.empty ? [] : connections
    };
};

export const checkConnectionExistence = data => {
    return {
        type: CHECK_CONNECTION_EXISTENCE,
        payload: data
    };
};

export const storeConnectionMatches = matches => {
    return {
        type: STORE_CONNECTION_MATCHES,
        payload: matches
    };
};

export const setConnectionMatch = match => {
    return {
        type: SET_CONNECTION_MATCH,
        payload: match
    };
};

export const clearConnectionLink = () => {
    return {
        type: CLEAR_CONNECTION_LINK
    };
};

export const clearConnectionPrimary = () => {
    return {
        type: CLEAR_CONNECTION_PRIMARY
    };
};

export const addNewConnection = connection => {
    return {
        type: ADD_NEW_CONNECTION,
        payload: connection
    };
};

export const addNewConnectionSuccess = () => {
    return {
        type: ADD_NEW_CONNECTION_SUCCESS
    };
};

export const addNewConnectionFailure = error => {
    return {
        type: ADD_NEW_CONNECTION_FAILURE,
        payload: error
    };
};

export const resetNewConnection = () => {
    return {
        type: RESET_NEW_CONNECTION_DATA
    };
};
