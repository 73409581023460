import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Label } from 'reactstrap';

import { useLang } from '../../context/LangContext';

// Redux - Actions, Reducers, Sagas
import { internalResetPassword } from '../../store/actions/Auth';

import { errorMessage, passwordRegExp } from '../../utils/Constants';

import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { confirmationDialogTypes } from '../../utils/Constants';

import TextField from '../common/TextField';
import ActionButtons from './shared/ActionButtons';

const SecuritySettings = ({
    loading,
    error,
    internalResetPassword,
    internalResetPasswordSuccess,
    internalResetPasswordFailure
}) => {
    const {
        main_heading,
        info_text,
        newPass_label,
        repeat_label,
        save_label,
        cancel_label
    } = useLang()['Settings']['SecuritySettings'];

    const formRef = useRef();

    const validationSchema = Yup.object({
        password: Yup.string()
            .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            .min(8, errorMessage.password.length)
            .required(errorMessage.password.required),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required(errorMessage.password.required)
    });

    return (
        <>
            {internalResetPasswordSuccess && (
                <ConfirmationDialog
                    modalDialogType={confirmationDialogTypes.success}
                    successHandler={() => formRef.current?.resetForm()}
                />
            )}
            {internalResetPasswordFailure && (
                <ConfirmationDialog
                    modalDialogType={confirmationDialogTypes.failed}
                    successHandler={() => formRef.current?.resetForm()}
                />
            )}
            <Formik
                innerRef={formRef}
                initialValues={{
                    password: '',
                    passwordConfirmation: ''
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    internalResetPassword({ password: values.password });
                }}
                validateOnChange
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                }) => (
                    <>
                        <div className="card-header">
                            <h1 className="h5 font-weight-medium mb-0 ml-2">
                                {main_heading}
                            </h1>
                        </div>
                        <div className="card-body">
                            <Form className="w-md-50 mx-md-auto">
                                <div className="mb-7 mt-7 text-center">
                                    <p>{info_text}</p>
                                </div>
                                <div className="js-form-message form-group">
                                    <Label
                                        className="form-label required-mark"
                                        for="password"
                                    >
                                        {newPass_label}
                                    </Label>
                                    <TextField
                                        required
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        id="password"
                                        placeholder="********"
                                        aria-label="********"
                                        error={errors.password}
                                        value={values.password}
                                        onBlur={handleBlur('password')}
                                        onChange={handleChange('password')}
                                        invalid={touched.password && !!errors.password}
                                    />
                                </div>
                                <div className="js-form-message form-group">
                                    <Label
                                        className="form-label required-mark"
                                        for="password"
                                    >
                                        {repeat_label}
                                    </Label>
                                    <TextField
                                        required
                                        type="password"
                                        className="form-control"
                                        name="passwordConfirmation"
                                        id="passwordConfirmation"
                                        placeholder="********"
                                        aria-label="********"
                                        error={errors.passwordConfirmation}
                                        value={values.passwordConfirmation}
                                        onBlur={handleBlur('passwordConfirmation')}
                                        onChange={handleChange('passwordConfirmation')}
                                        invalid={
                                            touched.passwordConfirmation &&
                                            !!errors.passwordConfirmation
                                        }
                                    />
                                </div>
                            </Form>
                        </div>

                        <ActionButtons
                            handleSubmit={handleSubmit}
                            saveLabel={save_label}
                            cancelLabel={cancel_label}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = ({ auth }) => {
    const { loading, error, internalResetPasswordSuccess, internalResetPasswordFailure } =
        auth;
    return {
        loading,
        error,
        internalResetPasswordSuccess,
        internalResetPasswordFailure
    };
};

export default connect(mapStateToProps, {
    internalResetPassword
})(SecuritySettings);
