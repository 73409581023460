// React
import React, { Fragment, useEffect } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import NumberFormat from 'react-number-format';
import { Button } from 'reactstrap';

const ConnectionMatches = ({
    matches,
    setFocusedIndex,
    setFocusedState,
    connectionType,
    selectConnectionMatch,
    getPrimaryPhone,
    clearConnectionPrimary,
    clearConnectionLink
}) => {
    const {
        match_label,
        incorrect_match_single,
        incorrect_match_multiple,
        select_match
    } = useLang()['Connections']['ConnectionMatches'];
    useEffect(() => {
        if (matches && !matches.length) {
            setFocusedState('connection_form');
        }
    }, [matches, setFocusedState]);
    return (
        <div
            className="container d-flex"
            style={{
                animation: 'fadeIn 1s'
            }}
        >
            {matches && matches.length && (
                <div
                    className="col"
                    style={{ paddingLeft: '14.5%', paddingRight: '14.5%' }}
                >
                    <label className="form-label">{match_label}</label>
                    {matches.map(match => (
                        <Fragment key={match.email}>
                            <div
                                className="row align-items-center justify-content-between"
                                style={{
                                    height: '73px',
                                    backgroundColor: '#ffffff',
                                    width: '100%',
                                    margin: 0,
                                    border: '1px solid rgba(231, 234, 243, 1)',
                                    borderRadius: 5
                                }}
                            >
                                <div className="col-3">
                                    <h2 className="h6 mb-0">{`${match.first_name} ${match.last_name}`}</h2>
                                </div>
                                <div className="col-3">
                                    <span
                                        className="font-size-1"
                                        style={{ color: 'rgba(55, 125, 255, 1)' }}
                                    >
                                        {`${match.email}`}
                                    </span>
                                </div>
                                <div className="col-3">
                                    <span
                                        className="font-size-1"
                                        style={{ color: 'rgba(55, 125, 255, 1)' }}
                                    >
                                        <NumberFormat
                                            value={getPrimaryPhone(match.phone)}
                                            displayType={'text'}
                                            format="###.###.####"
                                            mask="_"
                                        />
                                    </span>
                                </div>
                                <div className="col-3">
                                    <button
                                        onClick={() => {
                                            selectConnectionMatch({
                                                match,
                                                type: connectionType
                                            });
                                            setFocusedIndex(
                                                connectionType === 'primary' ? 0 : 1
                                            );
                                            setFocusedState('connection_form');
                                        }}
                                        className={
                                            'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                                        }
                                    >
                                        {select_match}
                                        <span className="fas fa-angle-right ml-1" />
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                    <div className="row justify-content-center m-5">
                        <Button
                            color="link"
                            style={{ color: 'rgba(222, 68, 55, 1)' }}
                            onClick={() => {
                                connectionType === 'primary'
                                    ? clearConnectionPrimary()
                                    : clearConnectionLink();
                                setFocusedState(`${connectionType}_existing_check`);
                            }}
                        >
                            {matches.length > 1
                                ? incorrect_match_multiple
                                : incorrect_match_single}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ConnectionMatches;
