// React
import React from 'react';

// Components
import { NewConnectionForm } from '../';

// Constants
import {
    phoneRegExp,
    nameRegExp,
    streetAddressRegExp,
    unitedStates
} from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';

// Redux - Actions, Reducers, Sagas
import { clearConnectionLink, addNewConnection } from '../../store/actions/Connections';

const AddNewConnection = ({
    userData,
    addNewConnection,
    focusedState,
    focusedIndex,
    setFocusedIndex,
    setFocusedState,
    setConnectionType,
    primaryConnection,
    linkConnection,
    getPrimaryPhone,
    clearConnectionLink,
    primaryEmail,
    primaryPhone,
    linkEmail,
    linkPhone,
    cancelLink,
    setCancelLink
}) => {
    const { errorMessage } = useLang()['Constants'];

    const getUsState = unitedState => {
        if (unitedState.length > 2) return unitedState;
        const state = unitedStates.find(state => state.value === unitedState);
        return state.name;
    };

    const initialValues = {
        parties: [
            {
                id: 0,
                firstName: primaryConnection?.first_name || '',
                lastName: primaryConnection?.last_name || '',
                fullLegalName: primaryConnection?.legal_name || '',
                email: primaryConnection?.email || primaryEmail || '',
                address: primaryConnection?.address?.address_1 || '',
                address2: primaryConnection?.address?.address_2 || '',
                phone: primaryConnection?.phone
                    ? getPrimaryPhone(primaryConnection?.phone) === primaryPhone
                        ? getPrimaryPhone(primaryConnection?.phone)
                        : primaryPhone || getPrimaryPhone(primaryConnection?.phone) || ''
                    : primaryPhone || '',
                city: primaryConnection?.address?.city || '',
                state: primaryConnection?.address?.state
                    ? getUsState(primaryConnection?.address?.state)
                    : '',
                zip: primaryConnection?.address?.zip || '',
                lat: primaryConnection?.address?.lat || '',
                lon: primaryConnection?.address?.lon || '',
                sameAddress: false
            }
        ]
    };

    const addedParty = {
        id: 1,
        firstName: '',
        lastName: '',
        fullLegalName: '',
        email: '',
        address: '',
        address2: '',
        phone: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        lon: '',
        sameAddress: false
    };

    const validationSchema = Yup.object({
        parties: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string()
                    .matches(nameRegExp.format, errorMessage.firstName.valid)
                    .required(errorMessage.firstName.required),
                lastName: Yup.string()
                    .matches(nameRegExp.format, errorMessage.lastName.valid)
                    .required(errorMessage.lastName.required),
                fullLegalName: Yup.string(),
                email: Yup.string()
                    .lowercase()
                    .email(errorMessage.email.valid)
                    .required(errorMessage.email.required),
                phone: Yup.string().matches(phoneRegExp.format, errorMessage.phone.valid),
                address: Yup.string().matches(
                    streetAddressRegExp,
                    errorMessage.address.valid
                ),
                address2: Yup.string(),
                city: Yup.string().matches(nameRegExp.format, errorMessage.city.valid),
                state: Yup.string(),
                zip: Yup.string(),
                sameAddress: Yup.bool(),
                lat: Yup.number(),
                lon: Yup.number()
            })
        )
    });

    const checkError = (errors, index, type) => {
        if (
            errors &&
            errors.parties &&
            errors.parties[index] &&
            errors.parties[index][type]
        ) {
            return errors.parties[index][type];
        }
    };

    const checkTouched = (touched, index, type) => {
        if (
            touched &&
            touched.parties &&
            touched.parties[index] &&
            touched.parties[index][type]
        ) {
            return true;
        } else {
            return null;
        }
    };

    return (
        <div
            className="container"
            style={{ display: focusedState !== 'connection_form' ? 'none' : '' }}
        >
            <div className="mx-lg-auto">
                <div className="card pt-5 pb-0">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                            addNewConnection({
                                connection: values.parties,
                                primary: primaryConnection,
                                link: linkConnection
                            });
                            setSubmitting(false);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldTouched,
                            setFieldValue,
                            touched,
                            values,
                            isSubmitting
                        }) => (
                            <>
                                <Form>
                                    <div
                                        style={{
                                            animation: 'fadeIn .5s'
                                        }}
                                    >
                                        <FieldArray
                                            name="parties"
                                            render={arrayHelpers => {
                                                const { parties } = values;

                                                return (
                                                    <NewConnectionForm
                                                        parties={parties}
                                                        checkError={checkError}
                                                        errors={errors}
                                                        values={values}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        handleSubmit={handleSubmit}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        checkTouched={checkTouched}
                                                        touched={touched}
                                                        isSubmitting={isSubmitting}
                                                        arrayHelpers={arrayHelpers}
                                                        addedParty={addedParty}
                                                        getUsState={getUsState}
                                                        focusedIndex={focusedIndex}
                                                        setFocusedIndex={setFocusedIndex}
                                                        setFocusedState={setFocusedState}
                                                        setConnectionType={
                                                            setConnectionType
                                                        }
                                                        primaryConnection={
                                                            primaryConnection
                                                        }
                                                        linkConnection={linkConnection}
                                                        getPrimaryPhone={getPrimaryPhone}
                                                        clearConnectionLink={
                                                            clearConnectionLink
                                                        }
                                                        primaryEmail={primaryEmail}
                                                        primaryPhone={primaryPhone}
                                                        linkEmail={linkEmail}
                                                        linkPhone={linkPhone}
                                                        cancelLink={cancelLink}
                                                        setCancelLink={setCancelLink}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, connections }) => {
    const { userData } = user;
    const { primaryConnection, linkContact } = connections;
    return { userData, primaryConnection, linkContact };
};

export default withRouter(
    connect(mapStateToProps, { addNewConnection, clearConnectionLink })(AddNewConnection)
);
