// React
import React, { useState, useCallback } from 'react';

// Components
import { ContactsSearchFilter } from '../components';
import { ContactList } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const Contacts = ({
    match,
    userData,
    loading,
    userContacts,
    companyContacts,
    filterType,
    uploadError
}) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [searchSuggestions, settingSearchSuggestions] = useState(null);

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const contactsByFilter = () => {
        const contacts = getContactsByType();
        return contacts && contacts.length
            ? contacts.sort((a, b) => {
                  if (a.last_name && a.first_name && b.last_name && b.first_name) {
                      const result = a.last_name.localeCompare(b.last_name);
                      return result !== 0
                          ? result
                          : a.first_name.localeCompare(b.first_name);
                  } else if (!a.last_name || !a.first_name) {
                      return 1;
                  } else if (!b.last_name || !b.first_name) {
                      return -1;
                  }
                  return true;
              })
            : [];
    };

    const getContactsByType = () => {
        if (filterType.value === 'external') {
            return userContacts;
        } else if (filterType.value === 'internal') {
            return companyContacts;
        } else {
            return [].concat(userContacts, companyContacts);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <ContactsSearchFilter
                loading={loading}
                settingSearchSuggestions={settingSearchSuggestions}
                contacts={contactsByFilter()}
                userContacts={userContacts}
                companyContacts={companyContacts}
                filterType={filterType}
                uploadError={uploadError}
            />
            <div ref={measuredRef}>
                <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                    <div className="container space-bottom-1">
                        <ContactList
                            userData={userData}
                            match={match}
                            searchSuggestions={searchSuggestions}
                            contacts={contactsByFilter()}
                            filterType={filterType}
                        />
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, contacts }) => {
    const { userData } = user;
    const { loading, userContacts, companyContacts, filterType, uploadError } = contacts;
    return { userData, loading, userContacts, companyContacts, filterType, uploadError };
};

export default withRouter(connect(mapStateToProps, {})(Contacts));
