// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Components
import { ContactRow } from '../../components';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { Alert } from 'reactstrap';

const ContactList = ({ searchSuggestions, contacts }) => {
    const { windowWidth } = useWindowSize();

    const contactsList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : contacts;

    const { no_contacts_label } = useLang()['CompanyContacts']['ContactList'];

    return (
        <div className="mb-4" style={{ width: '100%' }}>
            {contactsList && contactsList.length ? (
                <>
                    {contactsList.map(contact => (
                        <ContactRow
                            contact={contact}
                            key={
                                contact.id
                                    ? contact.id
                                    : `${contact.first_name}${contact.last_name}${contact.phone_number}${contact.email}`
                            }
                            windowWidth={windowWidth}
                        />
                    ))}
                </>
            ) : (
                <Alert color="info" className="mb-0">
                    {no_contacts_label}
                </Alert>
            )}
        </div>
    );
};

export default ContactList;
