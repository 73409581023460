// React
import React from 'react';

// Packages
import ContentLoader from 'react-content-loader';

const ConnectionRowSkeleton = props => {
    return (
        <ContentLoader
            viewBox="0 0 1168 82"
            height={82}
            width={'100%'}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            speed={2}
            style={{ position: 'absolute', index: 10000, animation: 'fadeIn .25s' }}
            {...props}
        >
            <circle cx="3.5%" cy="41px" r="25" />
            <rect x="9%" y="29px" rx=".3125" ry=".3125" width="180px" height="24px" />
            <rect x="32%" y="19px" width="160px" rx=".3125" ry=".3125" height="20px" />
            <rect x="32%" y="43px" width="160px" rx=".3125" ry=".3125" height="20px" />
            <rect x="55%" y="19px" width="160px" rx=".3125" ry=".3125" height="20px" />
            <rect x="55%" y="43px" width="160px" rx=".3125" ry=".3125" height="20px" />
            <rect x="80%" y="16px" width="160px" rx=".3125" ry=".3125" height="50px" />
        </ContentLoader>
    );
};

export default ConnectionRowSkeleton;
