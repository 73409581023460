import {
    all,
    fork,
    takeLatest,
    put,
    call,
    take,
    race,
    select,
    cancelled
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    GET_CONTACTS,
    ADD_NEW_CONTACTS,
    LOGOUT_USER,
    REMOVE_CONTACT
} from '../actions/types';

import {
    addContactsSuccess,
    addContactsFailure,
    storingCompanyContacts
} from '../actions/CompanyContacts';

import { db, rtdb, fsFieldValue } from '../../config/Firebase';

import * as selectors from './Selectors';

import { confirmSaga } from './Modal';
import { confirmationDialogTypes } from '../../utils/Constants';
import { setConfirmModalType } from '../actions/Modal';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Contacts Collection Watch //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* contactsCollectionWatch(user) {
    const companyContactsRef = rtdb.ref(`orgs/${user.active_org_id}/contacts`);

    const companyContactsCollectionChannel = eventChannel(emit => {
        const unsubscribecompanyContactsData = companyContactsRef.on(
            'value',
            querySnapshot => {
                if (querySnapshot && querySnapshot.val()) {
                    var companyContacts = [];
                    companyContacts = Object.values(querySnapshot.val());
                    emit(companyContacts);
                } else {
                    const doc = { empty: true };
                    emit({ doc });
                }
            }
        );
        return unsubscribecompanyContactsData;
    });

    try {
        while (true) {
            const { userSignOut, companyContactsData } = yield race({
                userSignOut: take(LOGOUT_USER),
                companyContactsData: take(companyContactsCollectionChannel)
            });

            if (userSignOut) {
                companyContactsCollectionChannel.close(); // Detach saga event emitter
            }
            if (companyContactsData)
                yield put(storingCompanyContacts(companyContactsData));
        }
    } catch (error) {
        log('Company Contacts Error: getting company contacts data (RTDB)', {
            error,
            user,
            function: 'contactsCollectionWatch'
        });
    } finally {
        companyContactsRef.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            companyContactsRef.off('value'); // Detach firebase listener
        }
    }
}

const addingSingleContactsRequest = async ({ contacts, userData }) => {
    // // const ref = users.doc(userData.id);
    // const ref = rtdb.ref(`contacts/${userData.id}`);
    // const {
    //     address,
    //     address2,
    //     city,
    //     state,
    //     zip,
    //     lat,
    //     lon,
    //     firstName,
    //     lastName,
    //     email,
    //     phone,
    //     phoneExt,
    //     notes
    // } = contacts[0];
    // const contact = {
    //     address: {
    //         address_1: address !== '' ? address : null,
    //         address_2: address2 !== '' ? address2 : null,
    //         city: city !== '' ? city : null,
    //         state: state !== '' ? state : null,
    //         zip: zip !== '' ? zip : null,
    //         lat: lat !== '' ? lat : null,
    //         lon: lon !== '' ? lon : null
    //     },
    //     email: email !== '' ? email : null,
    //     id: null,
    //     first_name: firstName !== '' ? firstName : null,
    //     last_name: lastName !== '' ? lastName : null,
    //     phone: phone !== '' ? phone : null,
    //     phoneExt: phoneExt !== '' ? phoneExt : null,
    //     notes: notes !== '' ? notes : null
    // };
    // return ref
    //     .update({
    //         contacts: fsFieldValue.arrayUnion(contact)
    //     })
    //     .then(() => {
    //         return { res: true };
    //     })
    //     .catch(error => {
    //         return { error };
    //     });
};

const addingMultipleContactsRequest = async ({ contacts, userData }) => {
    const addressHold = {
        address_1: contacts[0].address !== '' ? contacts[0].address : null,
        address_2: contacts[0].address2 !== '' ? contacts[0].address2 : null,
        city: contacts[0].city !== '' ? contacts[0].city : null,
        state: contacts[0].state !== '' ? contacts[0].state : null,
        zip: contacts[0].zip !== '' ? contacts[0].zip : null,
        lat: contacts[0].lat !== '' ? contacts[0].lat : null,
        lon: contacts[0].lon !== '' ? contacts[0].lon : null
    };
    while (contacts.length) {
        let count = 0;
        let batch = db.batch();
        const {
            address,
            address2,
            city,
            state,
            zip,
            lat,
            lon,
            firstName,
            lastName,
            email,
            phone,
            phoneExt,
            notes,
            sameAddress
        } = contacts[0];
        const contact = {
            address: sameAddress
                ? addressHold
                : {
                      address_1: address !== '' ? address : null,
                      address_2: address2 !== '' ? address2 : null,
                      city: city !== '' ? city : null,
                      state: state !== '' ? state : null,
                      zip: zip !== '' ? zip : null,
                      lat: lat !== '' ? lat : null,
                      lon: lon !== '' ? lon : null
                  },
            email: email !== '' ? email : null,
            id: null,
            first_name: firstName !== '' ? firstName : null,
            last_name: lastName !== '' ? lastName : null,
            phone: phone !== '' ? phone : null,
            phoneExt: phoneExt !== '' ? phoneExt : null,
            notes: notes !== '' ? notes : null
        };
        batch.update(users.doc(userData.id), {
            contacts: fsFieldValue.arrayUnion(contact)
        });
        contacts.shift();
        if (++count >= 500 || !contacts.length) {
            return await batch
                .commit()
                .then(() => {
                    count = 0;
                    batch = db.batch();
                    if (!contacts.length) return { res: true };
                })
                .catch(error => {
                    return { error };
                });
        }
    }
};

export function* addingNewContacts({ payload }) {
    const { contacts } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        contacts.length > 1
            ? addingMultipleContactsRequest({ contacts, userData })
            : addingSingleContactsRequest({ contacts, userData })
    );
    if (res) {
        yield put(addContactsSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Contacts Error: storing new contact(s) (FS)', {
            error,
            contacts
        });
        yield put(addContactsFailure());
    }
}

const removingContactFromRtdb = async ({ payload }) => {
    let contactRef;
    const { userData, contact, isInternalContact } = payload;
    if (isInternalContact) {
        contactRef = rtdb.ref(`contacts/${userData.id}/${contact.id}`);
    } else {
        contactRef = rtdb.ref(`orgs/${userData.active_org_id}/contacts/${contact.id}`);
    }

    return contactRef
        .remove()
        .then(() => ({ res: true }))
        .catch(error => ({ err: error }));
};

export function* removingContact({ payload }) {
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, err } = yield call(() =>
            removingContactFromRtdb({
                payload
            })
        );
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Contacts Error: removing contact (FS)`, {
                error: err,
                contacts: payload.contacts,
                function: 'removingContactFromDB'
            });
        }
    }
}

export function* getContactsCollection() {
    yield takeLatest(GET_CONTACTS, contactsCollectionWatch);
}

export function* addNewContacts() {
    yield takeLatest(ADD_NEW_CONTACTS, addingNewContacts);
}

export function* removeContact() {
    yield takeLatest(REMOVE_CONTACT, removingContact);
}

export default function* rootSaga() {
    yield all([fork(getContactsCollection), fork(addNewContacts), fork(removeContact)]);
}
