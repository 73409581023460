import React from 'react';

const AppIntegrations = () => {
    return (
        <>
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0 ml-2">App Integrations</h1>
            </div>
            <div className="card-body">
                <p>“It&rsquo;s not a faith in technology. It&rsquo;s faith in people.”</p>
            </div>
        </>
    );
};

export default AppIntegrations;
