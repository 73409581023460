import React from 'react';

const ActionButtons = ({
    handleClose,
    handleSubmit,
    saveLabel,
    cancelLabel,
    disabled
}) => (
    <div className="card-footer">
        <div className="col-12 actionsContainer">
            <button
                className="btn btn-link text-danger mr-4"
                onClick={handleClose}
                type="button"
            >
                {cancelLabel}
            </button>
            <button
                className="btn btn-primary mr-2"
                onClick={handleSubmit}
                type="submit"
                disabled={disabled}
            >
                {saveLabel}
            </button>
        </div>
    </div>
);

export default ActionButtons;
