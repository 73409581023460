import { all, fork, takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_PROPERTY_MLS } from '../actions/types';
import { getMlsDataSuccess } from '../actions/Property';

// Loggers
import { log } from '../../utils/Loggers';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Get Property Data //////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getPropertyDataRequest = async ({ mlsNumber }) => {
    return await axios({
        method: 'GET',
        url: `https://api.bridgedataoutput.com/api/v2/gamls2/listings/${mlsNumber}`,

        headers: {
            Authorization: `Bearer 67bd5e93223b6e35219ee4941002cc2e`,
            'Content-Type': 'application/json'
        }
    })
        .then(property => {
            const data = property?.data?.bundle ? property.data.bundle : {};
            return { data: data };
        })
        .catch(err => {
            return { error: err };
        });
};

export function* getPropertyData({ payload }) {
    const { mlsNumber } = payload;
    const { data, error } = yield call(() => getPropertyDataRequest({ mlsNumber }));
    if (data) {
        // console.log(data);
        // const size = new TextEncoder().encode(JSON.stringify(data)).length;
        // const kiloBytes = size / 1024;
        // const megaBytes = kiloBytes / 1024;
        // console.log('KB: ', kiloBytes);
        // console.log('MB: ', megaBytes);
        yield put(getMlsDataSuccess(data));
    } else {
        yield put(getMlsDataSuccess(null));
        // Error Handling for sentry with put and maybe UI message
        log('Property Error: getting property data (API)', {
            error,
            mlsNumber
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* gettingPropertyData() {
    yield takeLatest(GET_PROPERTY_MLS, getPropertyData);
}

export default function* rootSaga() {
    yield all([fork(gettingPropertyData)]);
}
