import {
    SET_COMPANY_CONTACTS,
    ADD_NEW_CONTACTS,
    SET_CONTACTS_FILTER,
    ADD_NEW_CONTACTS_SUCCESS,
    ADD_NEW_CONTACTS_FAILURE,
    REMOVE_CONTACT
} from './types';

export const storingCompanyContacts = contacts => {
    return {
        type: SET_COMPANY_CONTACTS,
        payload: contacts?.doc?.empty ? [] : contacts
    };
};

export const addNewContacts = contacts => {
    return {
        type: ADD_NEW_CONTACTS,
        payload: contacts
    };
};

export const settingContactsFilter = filter => {
    return {
        type: SET_CONTACTS_FILTER,
        payload: filter
    };
};

export const addContactsSuccess = () => {
    return {
        type: ADD_NEW_CONTACTS_SUCCESS
    };
};

export const addContactsFailure = () => {
    return {
        type: ADD_NEW_CONTACTS_FAILURE
    };
};

export const removeContact = data => {
    return {
        type: REMOVE_CONTACT,
        payload: data
    };
};
