import React from 'react';

// Components
import TextField from '../../common/TextField';
import { Field } from 'formik';
import PhoneDropdown from './PhoneDropdown';

// Context
import { useLang } from '../../../context/LangContext';

const PhonesField = ({ form, remove, push }) => {
    const { office, addPhone_label } = useLang()['Settings']['OrganizationInfo'];

    const PhoneTypes = {
        office: office?.phoneTypes?.office,
        mobile: office?.phoneTypes?.mobile,
        fax: office?.phoneTypes?.fax
    };
    const fieldChangeHandler = (value, index, phoneValues, setFieldValue, fieldName) => {
        setFieldValue(`phones[${index}]`, {
            ...phoneValues[index],
            [fieldName]: value
        });
    };
    return (
        <>
            {form.values.phones?.map((phone, index) => (
                <div
                    className="input-group position-relative d-flex mb-2"
                    key={phone.number}
                >
                    <TextField
                        className="borderColor rounded-left form-control"
                        name={`phones[${index}]`}
                        type="text"
                        placeholder={office?.placeholders?.enterPhone}
                        value={phone.number}
                        error={
                            form.errors.phones &&
                            form.errors.phones[index] &&
                            form.errors.phones[index].number
                        }
                        onChange={event =>
                            fieldChangeHandler(
                                event.target.value,
                                index,
                                form.values.phones,
                                form.setFieldValue,
                                'number'
                            )
                        }
                        myStyle={{
                            flexGrow: '1',
                            borderRadius: '5px 0 0 5px'
                        }}
                        onBlur={form.handleBlur}
                        invalid={
                            form.errors.phones &&
                            form.errors.phones[index] &&
                            form.errors.phones[index].number &&
                            form.dirty
                        }
                        hasDefaultFill
                    />
                    <Field
                        name={`phones[${index}]`}
                        component={PhoneDropdown}
                        onChange={value =>
                            fieldChangeHandler(
                                value,
                                index,
                                form.values.phones,
                                form.setFieldValue,
                                'type'
                            )
                        }
                        index={index}
                        options={PhoneTypes}
                        placeholder={office?.placeholders?.enterPhone}
                    />

                    {!phone.primary && (
                        <i
                            className="fa-regular fa-xmark position-absolute pointer removePhoneIcon"
                            onClick={() => remove(index)}
                        />
                    )}
                </div>
            ))}
            {!form.errors.phones && (
                <div
                    className="d-flex align-items-center mt-2 pointer"
                    style={{
                        width: 'fit-content'
                    }}
                    onClick={() =>
                        push({ type: PhoneTypes.office, number: '', primary: false })
                    }
                >
                    <i
                        className="fa-regular fa-circle-plus"
                        style={{
                            color: 'rgb(55, 125, 255)',
                            marginRight: '4px'
                        }}
                    />
                    <span className="addPhone">{addPhone_label}</span>
                </div>
            )}
        </>
    );
};

export default PhonesField;
