import React, { useEffect, useState } from 'react';

const ConnectionProgress = ({ setFocusedState, loading }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!loading) {
            setProgress(100);
        }
    }, [loading, setProgress]);

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                setFocusedState('connection_matches');
            }, 1500);
        }
    }, [progress, setFocusedState]);

    return (
        <div
            className="container d-flex"
            style={{
                animation: 'fadeIn 1s'
            }}
        >
            <div className="col">
                <div className="row justify-content-center">
                    <div
                        className="progress"
                        style={{ width: '300px', height: '10px', borderRadius: 5 }}
                    >
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                                width: `${progress}%`,
                                transition: 'width 1500ms ease-in-out'
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ConnectionProgress;
