// React
import React from 'react';

// Assets
import PDF from '../../assets/svg/PDF.svg';

// Context
import { useLang } from '../../context/LangContext';

// Router
import { Link } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Components
import DropdownMenuComponent from '../common/DropdownMenu';

// Packages
import { useHistory } from 'react-router-dom';

const DocumentCard = ({ document, removeUserDocument }) => {
    const { title, upload_type, id, url } = document;
    const history = useHistory();
    const path = title.trim();
    const type = history.location.pathname;
    const endPath = path.replace(/ /g, '_');
    const { seller_label, buyer_label, other_label } =
        useLang()['Documents']['DocumentCard'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => removeUserDocument({ document, type })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () =>
                history.push({
                    pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${endPath}`,
                    state: { title, upload_type, id, url }
                })
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="col-6">
                    <span className={`badge badge-pill badge-${upload_type}`}>
                        {upload_type !== 'seller'
                            ? upload_type === 'buyer'
                                ? buyer_label
                                : other_label
                            : seller_label}
                    </span>
                </div>
                <div className="justify-content-end pt-1">
                    <DropdownMenuComponent dropItems={dropItems} />
                </div>
            </div>

            <Link
                className="font-size-1 d-block text-truncate"
                to={{
                    pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${endPath}`,
                    state: { title, upload_type, id, url }
                }}
            >
                <div
                    className="mt-5"
                    style={{ width: '100%', height: '185px', maxHeight: '185px' }}
                >
                    <img
                        src={PDF}
                        className="card-img-top w-100"
                        style={{ width: '75%', height: '75%' }}
                        alt="document"
                    />
                </div>

                <div className="card-body pt-0 pl-4 pr-4 pb-4">
                    <div
                        className="mb-2"
                        style={{ width: '100%', textAlign: 'center', color: '#377dff' }}
                    >
                        {title && <h2 className="h6 mb-0 ellipsis">{title}</h2>}
                    </div>
                </div>
            </Link>
        </>
    );
};

export default DocumentCard;
