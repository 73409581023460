// React
import React from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';

// Components
import { DetailsHeader } from '../components';
import DropdownMenuComponent from '../components/common/DropdownMenu';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Context
import { useLang } from '../context/LangContext';

// Packages
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

// Redux
import { connect } from 'react-redux';
import { removeContact } from '../store/actions/CompanyContacts';

// Router
import * as routes from '../router/config/routes';

// Utils
import { confirmationDialogTypes, headerTypes, userTypes } from '../utils/Constants';

const ContactDetails = ({ companyContacts, location, removeContact, userData }) => {
    const history = useHistory();

    const getContactId = () => {
        const pathname = window.location.pathname;
        if (location.state?.id) return location.state.id;
        return pathname.substr(pathname.lastIndexOf('/') + 1);
    };

    const avatar = location.state?.imgUrl;
    const contacts = companyContacts;
    const contact =
        contacts &&
        contacts.find(contact => {
            return contact?.id?.toString() === getContactId();
        });

    const { button_label } = useLang()['ContactDetails'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => removeContact({ userData, contact })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () => {}
        }
    ];

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.delete}
                successHandler={() =>
                    history.push(
                        `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONNECTIONS}`
                    )
                }
            />
            {contact && (
                <>
                    <DetailsHeader
                        contact={contact}
                        headerType={headerTypes.contactDetails}
                    />
                    <main id="content" role="main" className="push-main">
                        <div className="bg-light">
                            <div
                                id="main"
                                className="contact-frame size container mt-5 p-5 pt-3 pt-xl-4"
                            >
                                {userData?.type === userTypes.owner && (
                                    <div className="row justify-content-end pt-1">
                                        <DropdownMenuComponent dropItems={dropItems} />
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-2 contact-frame__avatars">
                                        <div className="row d-flex justify-content-center">
                                            {avatar ? (
                                                <img
                                                    src={avatar}
                                                    className="contact-frame__avatar"
                                                    alt={`${contact?.first_name} ${contact?.last_name}`}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary contact-frame__avatar rounded-circle"
                                                    name={`${contact.first_name} ${contact.last_name}`}
                                                    unstyled
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-10 mt-2 contact__details">
                                        <h2 className="contact-frame__fullname mb-3">
                                            {contact.first_name} {contact.last_name}
                                        </h2>
                                        <div className="row d-flex align-items-start ml-0 mt-2">
                                            <div className="col">
                                                {contact.phone && (
                                                    <p className="row contact__details-row">
                                                        <FontAwesomeIcon
                                                            className="contact__details-icon"
                                                            icon={faPhone}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a href={`tel:${contact.phone}`}>
                                                            <NumberFormat
                                                                value={contact.phone}
                                                                displayType={'text'}
                                                                format="###.###.####"
                                                                mask="_"
                                                            />
                                                        </a>
                                                    </p>
                                                )}
                                                {contact.email && (
                                                    <p className="row contact__details-row">
                                                        <FontAwesomeIcon
                                                            className="contact__details-icon"
                                                            icon={faEnvelope}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a
                                                            href={`mailto:${contact.email}`}
                                                        >
                                                            {contact.email}
                                                        </a>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-6 mb-2 justify-content-end">
                                    <button className="btn btn-success transition-3d-hover">
                                        {button_label}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ contacts, user }) => {
    const { companyContacts } = contacts;
    const { userData } = user;
    return { companyContacts, userData };
};

export default withRouter(connect(mapStateToProps, { removeContact })(ContactDetails));
