// React
import React, { useState, useEffect, Fragment } from 'react';

// Components
import { TextField, StateCountrySelect } from '..';

// Constants
import { lookupRegExp } from '../../utils/Constants';
import { stateProvince } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Loggers
import { log } from '../../utils/Loggers';

// Packages
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { Label, Button } from 'reactstrap';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const NewConnectionForm = ({
    parties,
    checkError,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    arrayHelpers,
    handleSubmit,
    handleChange,
    handleBlur,
    checkTouched,
    touched,
    addedParty,
    getUsState,
    focusedIndex,
    setFocusedIndex,
    setFocusedState,
    setConnectionType,
    getPrimaryPhone,
    primaryConnection,
    linkConnection,
    clearConnectionLink,
    primaryEmail,
    primaryPhone,
    linkEmail,
    linkPhone,
    cancelLink,
    setCancelLink
}) => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [lookupResults, setLookupResults] = useState(null);
    const [resultsIndex, setResultsIndex] = useState(null);
    const {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        legal_name_label,
        legal_name_placeholder,
        email_address_label,
        email_address_placeholder,
        phone_number_label,
        phone_number_placeholder,
        address_label,
        address_placeholder,
        city_label,
        city_placeholder,
        unit_label,
        unit_placeholder,
        state_label,
        zip_label,
        zip_placeholder,
        mailing_address_same_label,
        remove_connection_label,
        link_button,
        primary_button,
        add_party_text,
        add_party_subtext,
        save_button
    } = useLang()['Connections']['NewConnectionForm'];

    const addressInputHandler = (address, index) => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    setLookupResults(result);
                    setResultsIndex(index);
                })
                .catch(error => {
                    log('Smarty Streets Error: error in address lookup results', {
                        error,
                        address,
                        function: 'addressInputHandler'
                    });
                });
        } else {
            setLookupResults(null);
            setResultsIndex(null);
        }
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = ''; // Only applies to Puerto Rico addresses
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components, metadata } = result.lookups[0].result[0] || null;
                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                } `;
                const address2 = components.secondaryNumber
                    ? `${components.secondaryNumber} `
                    : '';

                if (result.lookups[0].result[0]) {
                    setFieldTouched(`parties.${resultsIndex}.address`, true);
                    setFieldValue(`parties.${resultsIndex}.address`, address1);
                    setFieldTouched(`parties.${resultsIndex}.address2`, true);
                    setFieldValue(`parties.${resultsIndex}.address2`, address2);
                    setFieldTouched(`parties.${resultsIndex}.city`, true);
                    setFieldValue(`parties.${resultsIndex}.city`, components.cityName);
                    setFieldTouched(`parties.${resultsIndex}.state`, true);
                    setFieldValue(
                        `parties.${resultsIndex}.state`,
                        getUsState(components.state)
                    );
                    setFieldTouched(`parties.${resultsIndex}.zip`, true);
                    setFieldValue(`parties.${resultsIndex}.zip`, components.zipCode);
                    setFieldValue(`parties.${resultsIndex}.lat`, metadata.latitude);
                    setFieldValue(`parties.${resultsIndex}.lon`, metadata.longitude);
                    setLookupResults(null);
                    setResultsIndex(null);
                }
            })
            .catch(error => {
                log('Smarty Streets Error: error in address lookup details', {
                    error,
                    address,
                    function: 'getAddressFromLookup'
                });
            });
    };

    useEffect(() => {
        if (linkConnection) {
            setFieldTouched(`parties.1.firstName`, true);
            setFieldValue(`parties.1.firstName`, linkConnection.first_name || '');
            setFieldTouched(`parties.1.lastName`, true);
            setFieldValue(`parties.1.lastName`, linkConnection.last_name || '');
            setFieldTouched(`parties.1.fullLegalName`, true);
            setFieldValue(`parties.1.fullLegalName`, linkConnection.legal_name || '');
            setFieldTouched(`parties.1.email`, true);
            setFieldValue(`parties.1.email`, linkConnection.email || linkEmail || '');
            setFieldTouched(`parties.1.address`, true);
            setFieldValue(`parties.1.address`, linkConnection?.address?.address_1 || '');
            setFieldTouched(`parties.1.address2`, true);
            setFieldValue(`parties.1.address2`, linkConnection?.address?.address_2 || '');
            setFieldTouched(`parties.1.phone`, true);
            setFieldValue(
                `parties.1.phone`,
                linkConnection?.phone
                    ? getPrimaryPhone(linkConnection.phone) === linkPhone
                        ? getPrimaryPhone(linkConnection.phone)
                        : linkPhone || getPrimaryPhone(linkConnection.phone)
                    : linkPhone || ''
            );
            setFieldTouched(`parties.1.city`, true);
            setFieldValue(`parties.1.city`, linkConnection?.address?.city || '');
            setFieldTouched(`parties.1.state`, true);
            setFieldValue(
                `parties.1.state`,
                linkConnection?.address?.state
                    ? getUsState(linkConnection?.address?.state)
                    : ''
            );
            setFieldTouched(`parties.1.zip`, true);
            setFieldValue(`parties.1.zip`, linkConnection?.address?.zip || '');
            setFieldTouched(`parties.1.lat`, true);
            setFieldValue(`parties.1.lat`, linkConnection?.address?.lat || '');
            setFieldTouched(`parties.1.lon`, true);
            setFieldValue(`parties.1.lon`, linkConnection?.address?.lon || '');
        } else {
            if (linkEmail) {
                setFieldTouched(`parties.1.email`, true);
                setFieldValue(`parties.1.email`, linkEmail);
            }
            if (linkPhone) {
                setFieldTouched(`parties.1.phone`, true);
                setFieldValue(`parties.1.phone`, linkPhone);
            }
        }
    }, [
        getPrimaryPhone,
        getUsState,
        linkConnection,
        linkEmail,
        linkPhone,
        setFieldTouched,
        setFieldValue,
        primaryConnection
    ]);

    useEffect(() => {
        if (cancelLink) {
            arrayHelpers.remove(1);
            setCancelLink(false);
            setFocusedIndex(0);
            setFocusedState('connection_form');
        }
    }, [cancelLink, setCancelLink, arrayHelpers, setFocusedState, setFocusedIndex]);

    const phoneDisabled = index => {
        const connection = index === 0 ? primaryConnection : linkConnection;
        const phone = index === 0 ? primaryPhone : linkPhone;
        if (connection) {
            if (phone) {
                if (phone === getPrimaryPhone(connection.phone)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (connection && getPrimaryPhone(connection.phone)) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    };

    return (
        <div
            id="contentStepForm"
            className="card-body p-5 w-lg-75 mx-lg-auto"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            {parties && parties.length > 0
                ? parties.map((party, index) => (
                      <Fragment key={party.id}>
                          {focusedIndex === index && (
                              <div key={party.id}>
                                  <div className="row">
                                      <div className="col-sm-6 mb-0">
                                          <div className="js-form-message form-group">
                                              <Label className="form-label">
                                                  {first_name_label}
                                                  <span className="text-danger ml-1">
                                                      *
                                                  </span>
                                              </Label>
                                              <TextField
                                                  type="text"
                                                  className="form-control"
                                                  name={`parties.${index}.firstName`}
                                                  id={`parties.${index}.firstName`}
                                                  placeholder={first_name_placeholder}
                                                  aria-label={first_name_placeholder}
                                                  error={checkError(
                                                      errors,
                                                      index,
                                                      'firstName'
                                                  )}
                                                  value={values.parties[index].firstName}
                                                  onBlur={e => {
                                                      handleBlur(
                                                          `parties.${index}.firstName`
                                                      );
                                                      setFieldTouched(
                                                          `parties.${index}.firstName`,
                                                          true
                                                      );
                                                      setFieldValue(
                                                          `parties.${index}.firstName`,
                                                          e.target.value.trim()
                                                      );
                                                  }}
                                                  onChange={e => {
                                                      handleChange(e);
                                                  }}
                                                  invalid={
                                                      !!checkError(
                                                          errors,
                                                          index,
                                                          'firstName'
                                                      ) &&
                                                      checkTouched(
                                                          touched,
                                                          index,
                                                          'firstName'
                                                      )
                                                  }
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 mb-0">
                                          <div className="js-form-message form-group">
                                              <Label className="form-label">
                                                  {last_name_label}
                                                  <span className="text-danger ml-1">
                                                      *
                                                  </span>
                                              </Label>
                                              <TextField
                                                  type="text"
                                                  className="form-control"
                                                  name={`parties.${index}.lastName`}
                                                  id={`parties.${index}.lastName`}
                                                  placeholder={last_name_placeholder}
                                                  aria-label={last_name_placeholder}
                                                  error={checkError(
                                                      errors,
                                                      index,
                                                      'lastName'
                                                  )}
                                                  value={values.parties[index].lastName}
                                                  onBlur={e => {
                                                      handleBlur(
                                                          `parties.${index}.lastName`
                                                      );
                                                      setFieldTouched(
                                                          `parties.${index}.lastName`,
                                                          true
                                                      );
                                                      setFieldValue(
                                                          `parties.${index}.lastName`,
                                                          e.target.value.trim()
                                                      );
                                                  }}
                                                  onChange={e => {
                                                      handleChange(e);
                                                  }}
                                                  invalid={
                                                      !!checkError(
                                                          errors,
                                                          index,
                                                          'lastName'
                                                      ) &&
                                                      checkTouched(
                                                          touched,
                                                          index,
                                                          'lastName'
                                                      )
                                                  }
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 mb-0">
                                          <div className="js-form-message form-group">
                                              <Label className="form-label">
                                                  {legal_name_label}
                                              </Label>
                                              <TextField
                                                  type="text"
                                                  className="form-control"
                                                  name={`parties.${index}.fullLegalName`}
                                                  id={`parties.${index}.fullLegalName`}
                                                  placeholder={legal_name_placeholder}
                                                  aria-label={legal_name_placeholder}
                                                  error={checkError(
                                                      errors,
                                                      index,
                                                      'fullLegalName'
                                                  )}
                                                  value={
                                                      values.parties[index].fullLegalName
                                                  }
                                                  onBlur={e => {
                                                      handleBlur(
                                                          `parties.${index}.fullLegalName`
                                                      );
                                                      setFieldTouched(
                                                          `parties.${index}.fullLegalName`,
                                                          true
                                                      );
                                                      setFieldValue(
                                                          `parties.${index}.fullLegalName`,
                                                          e.target.value.trim()
                                                      );
                                                  }}
                                                  onChange={e => {
                                                      handleChange(e);
                                                  }}
                                                  invalid={
                                                      !!checkError(
                                                          errors,
                                                          index,
                                                          'fullLegalName'
                                                      ) &&
                                                      checkTouched(
                                                          touched,
                                                          index,
                                                          'fullLegalName'
                                                      )
                                                  }
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-sm-6 mb-3">
                                          <div className="js-form-message form-group">
                                              <Label className="form-label">
                                                  {email_address_label}
                                                  <span className="text-danger ml-1">
                                                      *
                                                  </span>
                                              </Label>
                                              <TextField
                                                  type="text"
                                                  className="form-control"
                                                  name={`parties.${index}.email`}
                                                  id={`parties.${index}.email`}
                                                  placeholder={email_address_placeholder}
                                                  aria-label={email_address_placeholder}
                                                  error={checkError(
                                                      errors,
                                                      index,
                                                      'email'
                                                  )}
                                                  value={values.parties[index].email}
                                                  onBlur={e => {
                                                      handleBlur(
                                                          `parties.${index}.email`
                                                      );
                                                      setFieldTouched(
                                                          `parties.${index}.email`,
                                                          true
                                                      );
                                                      setFieldValue(
                                                          `parties.${index}.email`,
                                                          e.target.value.trim()
                                                      );
                                                  }}
                                                  onChange={e => {
                                                      handleChange(e);
                                                  }}
                                                  invalid={
                                                      !!checkError(
                                                          errors,
                                                          index,
                                                          'email'
                                                      ) &&
                                                      checkTouched(
                                                          touched,
                                                          index,
                                                          'email'
                                                      )
                                                  }
                                                  disabled={true}
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 mb-3">
                                          <div className="js-form-message form-group">
                                              <Label className="form-label">
                                                  {phone_number_label}
                                              </Label>
                                              <TextField
                                                  type="text"
                                                  className="form-control"
                                                  name={`parties.${index}.phone`}
                                                  id={`parties.${index}.phone`}
                                                  placeholder={phone_number_placeholder}
                                                  aria-label={phone_number_placeholder}
                                                  error={checkError(
                                                      errors,
                                                      index,
                                                      'phone'
                                                  )}
                                                  value={values.parties[index].phone}
                                                  onBlur={e => {
                                                      handleBlur(
                                                          `parties.${index}.phone`
                                                      );
                                                      setFieldTouched(
                                                          `parties.${index}.phone`,
                                                          true
                                                      );
                                                      setFieldValue(
                                                          `parties.${index}.phone`,
                                                          e.target.value.trim()
                                                      );
                                                  }}
                                                  onChange={e => {
                                                      handleChange(e);
                                                  }}
                                                  invalid={
                                                      !!checkError(
                                                          errors,
                                                          index,
                                                          'phone'
                                                      ) &&
                                                      checkTouched(
                                                          touched,
                                                          index,
                                                          'phone'
                                                      )
                                                  }
                                                  disabled={phoneDisabled(index)}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  {!values.parties[index].sameAddress && (
                                      <div
                                          style={{
                                              animation: 'fadeIn .5s'
                                          }}
                                      >
                                          <div className="row">
                                              <>
                                                  <div className="col-md-8 mb-3">
                                                      <div className="js-form-message form-group">
                                                          <Label
                                                              className="form-label"
                                                              htmlFor="listingAddress"
                                                          >
                                                              {address_label}
                                                          </Label>
                                                          <TextField
                                                              type="text"
                                                              myStyle={{
                                                                  flexGrow: 1
                                                              }}
                                                              className="form-control"
                                                              name={`parties.${index}.address`}
                                                              id={`parties.${index}address`}
                                                              placeholder={
                                                                  address_placeholder
                                                              }
                                                              aria-label={
                                                                  address_placeholder
                                                              }
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'address'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .address
                                                              }
                                                              onBlur={e => {
                                                                  handleBlur(
                                                                      `parties.${index}.address`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.address`,
                                                                      true
                                                                  );
                                                                  setFieldValue(
                                                                      `parties.${index}.address`,
                                                                      e.target.value.trim()
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                                  addressInputHandler(
                                                                      e.target.value,
                                                                      index
                                                                  );
                                                              }}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'address'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'address'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>

                                                  {lookupResults &&
                                                  lookupResults.length &&
                                                  resultsIndex === index ? (
                                                      <ul className="auto-complete-container">
                                                          {lookupResults.map(result => (
                                                              <li
                                                                  key={result.text}
                                                                  className="auto-complete-item"
                                                                  onClick={() => {
                                                                      getAddressFromLookup(
                                                                          result
                                                                      );
                                                                  }}
                                                              >
                                                                  {result.text}
                                                              </li>
                                                          ))}
                                                      </ul>
                                                  ) : null}
                                              </>
                                              <div className="col-md-4 mb-3">
                                                  <div className="js-form-message form-group">
                                                      <Label
                                                          className="form-label"
                                                          htmlFor="listingAddress"
                                                      >
                                                          {unit_label}
                                                      </Label>

                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.address2`}
                                                          id={`parties.${index}address2`}
                                                          placeholder={unit_placeholder}
                                                          aria-label={unit_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'address2'
                                                          )}
                                                          value={
                                                              values.parties[index]
                                                                  .address2
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.address2`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.address2`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.address2`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'address2'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'address2'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-6 mb-3">
                                                  <div className="js-form-message form-group">
                                                      <Label className="form-label">
                                                          {city_label}
                                                      </Label>
                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.city`}
                                                          id={`parties.${index}.city`}
                                                          placeholder={city_placeholder}
                                                          aria-label={city_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'city'
                                                          )}
                                                          value={
                                                              values.parties[index].city
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.city`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.city`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.city`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'city'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'city'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                              <div className="col-md-3 mb-3">
                                                  <div className="form-group">
                                                      <div className="js-focus-state">
                                                          <Label className="form-label">
                                                              {state_label}
                                                          </Label>
                                                          <StateCountrySelect
                                                              className="form-control custom-select"
                                                              name={`parties.${index}.state`}
                                                              id={`parties.${index}.state`}
                                                              placeholder=""
                                                              aria-label={state_label}
                                                              nestedOptions={true}
                                                              options={stateProvince}
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'state'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .state
                                                              }
                                                              onBlur={() => {
                                                                  handleBlur(
                                                                      `parties.${index}.state`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.state`,
                                                                      true
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                              }}
                                                              touched={checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'state'
                                                              )}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'state'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'state'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-3 mb-3">
                                                  <div className="js-form-message form-group">
                                                      <Label className="form-label">
                                                          {zip_label}
                                                      </Label>
                                                      <TextField
                                                          type="text"
                                                          myStyle={{
                                                              flexGrow: 1
                                                          }}
                                                          className="form-control"
                                                          name={`parties.${index}.zip`}
                                                          id={`parties.${index}.zip`}
                                                          placeholder={zip_placeholder}
                                                          aria-label={zip_placeholder}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'zip'
                                                          )}
                                                          value={
                                                              values.parties[index].zip
                                                          }
                                                          onBlur={e => {
                                                              handleBlur(
                                                                  `parties.${index}.zip`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.zip`,
                                                                  true
                                                              );
                                                              setFieldValue(
                                                                  `parties.${index}.zip`,
                                                                  e.target.value.trim()
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'zip'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'zip'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  )}
                                  {index !== 0 && values.parties[0].address && (
                                      <div className="row">
                                          <div className="col-sm-6">
                                              <div
                                                  className="custom-control custom-checkbox"
                                                  onClick={() =>
                                                      setFieldValue(
                                                          `parties.${index}.sameAddress`,
                                                          !values.parties[index]
                                                              .sameAddress
                                                      )
                                                  }
                                              >
                                                  <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      name={`parties.${index}.sameAddress`}
                                                      id={`parties.${index}.sameAddress`}
                                                      readOnly
                                                      checked={
                                                          values.parties[index]
                                                              .sameAddress
                                                      }
                                                  />
                                                  <label
                                                      className="custom-control-label"
                                                      htmlFor="stylishCheckbox2"
                                                  >
                                                      {mailing_address_same_label}
                                                  </label>
                                              </div>
                                          </div>
                                      </div>
                                  )}
                                  <hr
                                      style={{
                                          marginBottom: 32
                                      }}
                                  />
                              </div>
                          )}
                      </Fragment>
                  ))
                : null}
            <div className="row">
                {parties.length === 1 ? (
                    <div className="col-sm-6">
                        <Button
                            style={{
                                padding: 0
                            }}
                            color="link"
                            className="addParty d-inline-block u-text-muted"
                            type="button"
                            onClick={() => {
                                arrayHelpers.push(addedParty);
                                setConnectionType('link');
                                setFocusedIndex(1);
                                setFocusedState('link_existing_check');
                            }}
                        >
                            <div>
                                <span className="mr-2">+</span>
                                {add_party_text}
                            </div>
                        </Button>
                        <span
                            style={{
                                color: 'rgba(140, 152, 164, 1)',
                                display: 'block',
                                marginLeft: '20px',
                                fontSize: '13px'
                            }}
                        >
                            {add_party_subtext}
                        </span>
                    </div>
                ) : (
                    <div className="col-sm-6">
                        <Button
                            color="link"
                            className="addParty d-inline-block u-text-muted text-danger p-0"
                            type="button"
                            onClick={() => {
                                clearConnectionLink();
                                arrayHelpers.remove(1);
                                setFocusedIndex(0);
                            }}
                        >
                            <span className="mr-2 text-danger">-</span>
                            {remove_connection_label}
                        </Button>
                    </div>
                )}
                <div className="d-flex col-sm-6 justify-content-end">
                    <div className="d-flex col-8 justify-content-end pr-0">
                        {values.parties.length > 1 && (
                            <Button
                                className="text-small p-0"
                                color="link"
                                onClick={() =>
                                    setFocusedIndex(focusedIndex === 0 ? 1 : 0)
                                }
                            >
                                {focusedIndex === 0 ? link_button : primary_button}
                            </Button>
                        )}
                    </div>
                    <div className="d-flex col-4 justify-content-end pr-0">
                        <Button color="primary" type="button" onClick={handleSubmit}>
                            {save_button}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewConnectionForm;
