import React from 'react';
import { useLang } from '../../context/LangContext';

/**
 *
 * @type       - transaction type (buyer, seller)
 * @status     - transaction status (active, pending, closed etc.)
 * @isPill     - modifier class to make badges more rounded
 * @isVertical - for list view (badges are on top of each other)
 */
const TransactionBadge = ({ type, status, isPill, isVertical }) => {
    const {
        active_label,
        pending_label,
        closed_label,
        archived_label,
        buyer_label,
        seller_label
    } = useLang()['Transactions'];

    const renderStatusBadge = () => {
        switch (status) {
            case 'active':
                return (
                    <span
                        className={`badge ${isPill ? 'badge-pill' : 'w-50'} badge-active`}
                    >
                        {active_label}
                    </span>
                );
            case 'pending':
                return (
                    <span
                        className={`badge ${isPill ? 'badge-pill' : 'w-50'} badge-active`}
                    >
                        {pending_label}
                    </span>
                );
            case 'closed':
                return (
                    <span
                        className={`badge ${isPill ? 'badge-pill' : 'w-50'} badge-closed`}
                    >
                        {closed_label}
                    </span>
                );

            case 'archived':
                return (
                    <span
                        className={`badge ${
                            isPill ? 'badge-pill' : 'w-50'
                        } badge-archived`}
                    >
                        {archived_label}
                    </span>
                );
            default:
                return '';
        }
    };

    return (
        <>
            {isVertical ? (
                <div className="d-flex flex-column align-items-start col-2">
                    <span className={`badge badge-${type} w-50 mb-1`}>
                        {type === 'buyer' ? buyer_label : seller_label}
                    </span>
                    {renderStatusBadge()}
                </div>
            ) : (
                <div className="position-absolute top-0 left-0 pt-2 pl-3 col-12">
                    <div className="justify-content-between align-items-center">
                        <div className="d-flex justify-content-between">
                            <span className={`badge badge-pill badge-${type}`}>
                                {type === 'buyer' ? buyer_label : seller_label}
                            </span>
                            {renderStatusBadge()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TransactionBadge;
