import React, { useState, useCallback } from 'react';
import { AppHeader } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';

const Messages = props => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );
    return (
        <div style={{ maxHeight: windowHeight, overflow: 'hidden' }}>
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                            <h4 className="container space-bottom-1 mt-5">
                                Future home of message center. A place for internal
                                messages.
                            </h4>
                        </SimpleBar>
                    </div>
                </div>
            </main>
            ;
        </div>
    );
};
export default Messages;
