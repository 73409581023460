// React
import React from 'react';

// Components
import { AppHeader } from '../components';

// Pages
import { Connections, Documents, Library, Transactions } from '../pages';

// Packages
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';

//Router
import * as routes from '../router/config/routes';

const Dashboard = props => {
    return (
        <>
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <Switch>
                    <Redirect
                        exact
                        from={`${props.match.url}`}
                        to={`${props.match.url}${routes.TRANSACTIONS}`}
                    />
                    <Route
                        exact
                        path={`${props.match.url}${routes.CONNECTIONS}`}
                        render={props => <Connections {...props} />}
                    />
                    <Route
                        path={`${props.match.url}${routes.DOCUMENTS}`}
                        render={props => <Documents {...props} />}
                    />
                    <Route
                        path={`${props.match.url}${routes.LIBRARY}`}
                        render={props => <Library {...props} />}
                    />
                    <Route
                        exact
                        path={`${props.match.url}${routes.TRANSACTIONS}`}
                        render={props => <Transactions {...props} />}
                    />
                </Switch>
            </main>
        </>
    );
};

export default withRouter(Dashboard);
