// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import { AppHeader, ContactsSearchFilter, ContactList } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const CompanyContacts = ({
    history,
    match,
    userData,
    loading,
    companyContacts,
    filterType,
    uploadError
}) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [searchSuggestions, settingSearchSuggestions] = useState(null);
    const [filteredContacts, setFilteredContacts] = useState(null);

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );
    const getContactsByType = useCallback(() => {
        if (filterType.value === 'active') {
            return companyContacts.filter(
                contact => contact.active && contact.id !== userData.id
            );
        } else if (filterType.value === 'inactive') {
            return companyContacts.filter(
                contact => !contact.active && contact.id !== userData.id
            );
        } else {
            return companyContacts.filter(contact => contact.id !== userData.id);
        }
    }, [filterType.value, companyContacts, userData]);

    const contactsByFilter = useCallback(() => {
        const contacts = getContactsByType();
        contacts.sort((a, b) => {
            if (a.last_name && a.first_name && b.last_name && b.first_name) {
                const result = a.last_name.localeCompare(b.last_name);
                return result !== 0 ? result : a.first_name.localeCompare(b.first_name);
            } else if (!a.last_name || !a.first_name) {
                return 1;
            } else if (!b.last_name || !b.first_name) {
                return -1;
            }
            return true;
        });
        return contacts;
    }, [getContactsByType]);

    useEffect(() => {
        if (companyContacts && companyContacts.length) {
            setFilteredContacts(contactsByFilter());
        }
    }, [companyContacts, filterType, contactsByFilter]);

    return (
        <>
            <AppHeader history={history} match={match} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <ContactsSearchFilter
                        loading={loading}
                        settingSearchSuggestions={settingSearchSuggestions}
                        contacts={filteredContacts}
                        companyContacts={companyContacts}
                        filterType={filterType}
                        uploadError={uploadError}
                        userId={userData?.id}
                    />
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                            <div className="container space-bottom-1">
                                <ContactList
                                    searchSuggestions={searchSuggestions}
                                    contacts={filteredContacts}
                                    filterType={filterType}
                                />
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ user, contacts }) => {
    const { userData } = user;
    const { loading, companyContacts, filterType, uploadError } = contacts;
    return { userData, loading, companyContacts, filterType, uploadError };
};

export default withRouter(connect(mapStateToProps, {})(CompanyContacts));
