// React
import React, { useState, useEffect, useMemo } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';

// Redux - Actions, Reducers, Sagas
import {
    setTransactionList,
    settingTransactionFilter,
    settingSearchSuggestions
} from '../../store/actions/Transactions';

import * as routes from '../../router/config/routes';
import { trxStatus, userTypes } from '../../utils/Constants';

const TransactionsSearchFilter = ({
    transactionList,
    setTransactionList,
    userTransactions,
    archivedTransactions,
    closedTransactions,
    filterType,
    settingTransactionFilter,
    filteredTransactions,
    settingSearchSuggestions,
    searchSuggestions,
    userData
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hideSuggestions, setHideSuggestions] = useState();
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const {
        input_label,
        all_label,
        buyers_label,
        sellers_label,
        closed_label,
        archived_label,
        name_label,
        address_label
    } = useLang()['Transactions']['TransactionsSearchFilter'];

    const filterTypes = useMemo(
        () =>
            [
                {
                    name: `${all_label}`,
                    value: trxStatus.active
                },
                {
                    name: `${buyers_label}`,
                    value: 'buyer'
                },
                {
                    name: `${sellers_label}`,
                    value: 'seller'
                },
                {
                    name: `${closed_label}`,
                    value: trxStatus.closed
                },
                {
                    name: archived_label,
                    value: trxStatus.archived
                }
            ].filter(
                el =>
                    userData?.type !== userTypes.client ||
                    [trxStatus.active, trxStatus.closed].includes(el.value)
            ),
        [all_label, buyers_label, sellers_label, closed_label, archived_label, userData]
    );

    useEffect(() => {
        return () => {
            settingSearchSuggestions([]);
        };
    }, [settingSearchSuggestions]);

    const countByFilter = useMemo(() => {
        const buyerTrxNumber = userTransactions?.filter(
            trx => trx.type === 'buyer'
        ).length;
        const sellerTrxNumber = userTransactions?.filter(
            trx => trx.type === 'seller'
        ).length;
        return {
            [trxStatus.active]: userTransactions?.length || 0,
            buyer: buyerTrxNumber,
            seller: sellerTrxNumber,
            [trxStatus.closed]: closedTransactions?.length || 0,
            [trxStatus.archived]: archivedTransactions?.length || 0
        };
    }, [userTransactions, closedTransactions, archivedTransactions]);

    const transactionInputSearch = input => {
        if (input.trim() !== '') {
            const match = input.toString().match(/[0-9-]+/g);
            const transactions = [...filteredTransactions];
            if (
                input.toLowerCase().includes('mls') ||
                input.toLowerCase().includes('mls#') ||
                input.toLowerCase().includes('mls #')
            ) {
                // MLS Number
                const mls = match && match[0];
                const filteredMls = () => {
                    const mlsSuggestions = [];
                    mls &&
                        transactions.filter(trx => {
                            if (trx.mls && trx.mls.includes(mls)) {
                                mlsSuggestions.push({
                                    id: trx.id,
                                    type: 'mls',
                                    name: 'MLS#',
                                    value: trx.mls,
                                    transaction: trx
                                });
                            }
                            return true;
                        });
                    return mlsSuggestions;
                };

                const totalSuggestions = [...filteredMls()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            } else if (!isNaN(input[0]) || input.includes('-')) {
                // MLS number or Address
                const mls = match && match[0];
                const filteredMls = () => {
                    const mlsSuggestions = [];

                    transactions.filter(trx => {
                        if (trx.mls && trx.mls.includes(mls)) {
                            mlsSuggestions.push({
                                id: trx.id,
                                type: 'mls',
                                name: 'MLS#',
                                value: trx.mls,
                                transaction: trx
                            });
                        }
                        return true;
                    });
                    return mlsSuggestions;
                };
                const filteredAddress = () => {
                    const addressSuggestions = [];
                    transactions.filter(trx => {
                        const address = trx.address.address_1.toLowerCase();
                        if (address.includes(input.toLowerCase()))
                            addressSuggestions.push({
                                id: trx.id,
                                type: 'address',
                                name: `${address_label}`,
                                value: trx.address.address_1,
                                transaction: trx
                            });
                        return true;
                    });
                    return addressSuggestions;
                };

                const totalSuggestions = [...filteredMls(), ...filteredAddress()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            } else {
                // Name
                const filteredName = () => {
                    const nameSuggestions = [];
                    transactions.filter(trx => {
                        const firstName = trx.primary_client.first_name.toLowerCase();
                        const lastName = trx.primary_client.last_name.toLowerCase();
                        const fullName =
                            `${trx.primary_client.first_name} ${trx.primary_client.last_name} `.toLowerCase();
                        if (
                            firstName.includes(input.toLowerCase()) ||
                            lastName.includes(input.toLowerCase()) ||
                            fullName.includes(input.toLowerCase())
                        ) {
                            nameSuggestions.push({
                                id: trx.id,
                                type: 'name',
                                name: `${name_label}`,
                                value: `${trx.primary_client.first_name} ${trx.primary_client.last_name}`,
                                transaction: trx
                            });
                        }
                        return true;
                    });
                    return nameSuggestions;
                };

                const totalSuggestions = [...filteredName()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            }
        } else {
            settingSearchSuggestions([]);
        }
    };

    if (!userTransactions) return null;
    return (
        <div id="transactionFilters">
            <div className="container space-lg-1 space-md-0 space-sm-0">
                <div className="row mx-gutters-2">
                    <div className="col-md mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <label className="sr-only" htmlFor="searchPropertySr">
                                Search property
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchProperty"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchPropertySr"
                                    onFocus={() => setHideSuggestions(false)}
                                    placeholder={input_label}
                                    aria-label={input_label}
                                    aria-describedby="searchProperty"
                                    onChange={e => transactionInputSearch(e.target.value)}
                                />
                                {!hideSuggestions &&
                                searchSuggestions &&
                                searchSuggestions.length ? (
                                    <ul
                                        className="auto-complete-container"
                                        style={{
                                            position: 'absolute',
                                            zIndex: 1000,
                                            marginTop: '43px'
                                        }}
                                    >
                                        {searchSuggestions.map(suggestion => (
                                            <li
                                                key={suggestion.id}
                                                className="auto-complete-item"
                                            >
                                                <Link
                                                    className="auto-complete-item"
                                                    to={{
                                                        pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${suggestion.id}`,
                                                        state: {
                                                            id: suggestion.id
                                                        }
                                                    }}
                                                >
                                                    {`${suggestion.name}: ${suggestion.value}`}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-auto ml-md-auto mb-3 mb-lg-0">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret color="soft-primary" size="sm">
                                        {`${filterType.name} (${
                                            countByFilter[filterType.value]
                                        })`}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {filterTypes.map(filter => (
                                            <DropdownItem
                                                key={filter.value}
                                                active={filterType.value === filter.value}
                                                onClick={() =>
                                                    settingTransactionFilter({ filter })
                                                }
                                            >
                                                {`${filter.name}
                                                    (${countByFilter[filter.value]})`}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    color="soft-primary"
                                    size="sm"
                                    active={transactionList === 'grid' ? true : false}
                                    type="button"
                                    onClick={() => setTransactionList('grid')}
                                >
                                    <span className="fas fa-th-large" />
                                </Button>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    color="soft-primary"
                                    size="sm"
                                    type="button"
                                    active={transactionList === 'table' ? true : false}
                                    onClick={() => setTransactionList('table')}
                                >
                                    <span className="fas fa-list" />
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ transactions, user }) => {
    const {
        transactionList,
        userTransactions,
        archivedTransactions,
        closedTransactions,
        filterType,
        filteredTransactions,
        searchSuggestions
    } = transactions;

    const { userData } = user;
    return {
        transactionList,
        userTransactions,
        archivedTransactions,
        closedTransactions,
        filterType,
        filteredTransactions,
        searchSuggestions,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, {
        setTransactionList,
        settingTransactionFilter,
        settingSearchSuggestions
    })(TransactionsSearchFilter)
);
