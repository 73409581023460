import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';

import { UPDATE_USER_AVATAR } from '../actions/types';

import { uploadUserAvatarSuccess } from '../actions/Settings';

import { storage, db } from '../../config/Firebase';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User Avatar //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateUserAvatarRequest = async ({ img, userData }) => {
    const ref = storage.ref();
    const avatarRef = ref.child('users').child(userData.id).child('avatar.jpg');
    try {
        const blob = await fetch(img).then(r => r.blob());
        const snapshot = await avatarRef.put(blob);
        const url = await snapshot.ref.getDownloadURL();
        return { downloadUrl: url };
    } catch (error) {
        return { error };
    }
};

export function* updateUserAvatar({ payload }) {
    const { img } = payload;
    const userData = yield select(selectors._userData);
    const { downloadUrl, error } = yield call(() =>
        updateUserAvatarRequest({ img, userData })
    );
    if (downloadUrl) {
        yield call(updateUserUrl, { url: downloadUrl });
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Profile Error: adding/updating user avatar (FBS)', {
            error,
            img
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User URL /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateUserUrlRequest = async ({ url, userData }) => {
    const ref = users.doc(userData.id);
    return new Promise((resolve, reject) => {
        ref.update({
            user_avatar: url
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateUserUrl({ url }) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() => updateUserUrlRequest({ url, userData }));
    if (res) {
        yield put(uploadUserAvatarSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Profile Error: adding/updating user avatar url (FS)', {
            error,
            url
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* updatingUserAvatar() {
    yield takeLatest(UPDATE_USER_AVATAR, updateUserAvatar);
}

export default function* rootSaga() {
    yield all([fork(updatingUserAvatar)]);
}
