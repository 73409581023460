// React
import React from 'react';

// Components
import { TextField } from '..';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

// Constants
import { phoneRegExp, sanitizePhone } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Loggers

// Packages
import { connect } from 'react-redux';
import { Label } from 'reactstrap';

// Redux - Actions, Reducers, Sagas
// import { setNewContactData } from '../store/actions/Transactions';
import { checkConnectionExistence } from '../../store/actions/Connections';

// Router
// import { useHistory } from 'react-router-dom';
// import * as routes from '../router/config/routes';
import { withRouter } from 'react-router-dom';

const ExistingConnectionCheck = ({
    checkConnectionExistence,
    focusedState,
    setFocusedState,
    connectionType,
    setCancelLink,
    primaryEmail
}) => {
    const { errorMessage } = useLang()['Constants'];
    const {
        email_address_label,
        email_address_placeholder,
        phone_number_label,
        phone_number_placeholder
    } = useLang()['Connections']['ExistingConnectionCheck'];
    const initialValues = {
        contactMeta: {
            email: '',
            phone: ''
        }
    };

    const validationSchema = Yup.object({
        contactMeta: Yup.object().shape({
            email: Yup.string()
                .lowercase()
                .email(errorMessage.email.valid)
                .required(errorMessage.email.required)
                .notOneOf([primaryEmail], errorMessage.emailPrimaryLinkMatch),
            phone: Yup.string().matches(phoneRegExp.format, errorMessage.phone.valid)
        })
    });

    const checkError = (errors, type) => {
        if (errors && errors.contactMeta && errors.contactMeta[type]) {
            return errors.contactMeta[type];
        }
        return null;
    };

    const checkTouched = (touched, type) => {
        if (touched && touched.contactMeta && touched.contactMeta[type]) {
            return true;
        }
        return null;
    };

    // useEffect(() => {
    //     if (!isDisabled) setShouldConfirmDisplayed(true);
    // }, [isDisabled, setShouldConfirmDisplayed]);

    return (
        <div
            className="container d-flex"
            style={{
                animation: 'fadeIn 1s'
            }}
        >
            <div className="col">
                <Formik
                    className="row justify-content-center align-items-center"
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        checkConnectionExistence({
                            connection: {
                                email: values.contactMeta.email.toLowerCase().trim(),
                                phone:
                                    sanitizePhone(values.contactMeta.phone.trim()) === ''
                                        ? null
                                        : sanitizePhone(values.contactMeta.phone.trim()),
                                type: connectionType
                            }
                        });
                        setSubmitting(false);
                        setFocusedState('connection_progress');
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldTouched,
                        setFieldValue,
                        touched,
                        values,
                        isSubmitting
                    }) => (
                        <>
                            <Form className="row justify-content-start">
                                <div
                                    className="col-4 js-form-message form-group mr-4"
                                    style={{ marginLeft: '14.5%' }}
                                >
                                    <Label className="form-label">
                                        {email_address_label}
                                        <span className="text-danger ml-1">*</span>
                                    </Label>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name={`contactMeta.email`}
                                        id={`contactMeta.email`}
                                        placeholder={email_address_placeholder}
                                        aria-label={email_address_placeholder}
                                        error={checkError(
                                            errors,

                                            'email'
                                        )}
                                        value={values.contactMeta.email}
                                        onBlur={e => {
                                            handleBlur(`contactMeta.email`);
                                            setFieldTouched(`contactMeta.email`, true);
                                            setFieldValue(
                                                `contactMeta.email`,
                                                e.target.value.trim()
                                            );
                                        }}
                                        onChange={e => {
                                            handleChange(e);
                                        }}
                                        invalid={
                                            !!checkError(
                                                errors,

                                                'email'
                                            ) &&
                                            checkTouched(
                                                touched,

                                                'email'
                                            )
                                        }
                                    />
                                </div>

                                <div className="col-4 js-form-message form-group">
                                    <Label className="form-label">
                                        {phone_number_label}
                                    </Label>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name={`contactMeta.phone`}
                                        id={`contactMeta.phone`}
                                        placeholder={phone_number_placeholder}
                                        aria-label={phone_number_placeholder}
                                        error={checkError(errors, 'phone')}
                                        value={values.contactMeta.phone}
                                        onBlur={e => {
                                            handleBlur(`contactMeta.phone`);
                                            setFieldTouched(`contactMeta.phone`, true);
                                            setFieldValue(
                                                `contactMeta.phone`,
                                                e.target.value.trim()
                                            );
                                        }}
                                        onChange={e => {
                                            handleChange(e);
                                        }}
                                        invalid={
                                            !!checkError(
                                                errors,

                                                'phone'
                                            ) &&
                                            checkTouched(
                                                touched,

                                                'phone'
                                            )
                                        }
                                    />
                                </div>
                            </Form>
                            <div className="justify-content-center mt-10">
                                <div className="row justify-content-center">
                                    <button
                                        style={{ width: '250px' }}
                                        type="submit"
                                        className="btn btn-md btn-primary transition-3d-hover mb-4"
                                        disabled={isSubmitting}
                                        onClick={handleSubmit}
                                    >
                                        {/* {next_button_label} */} Lets Go
                                    </button>
                                </div>
                                <div className="row justify-content-center">
                                    {connectionType === 'link' && (
                                        <button
                                            type="link"
                                            className="btn btn-md text-danger"
                                            onClick={() => setCancelLink(true)}
                                        >
                                            Cancel linking a connection
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default withRouter(
    connect(null, { checkConnectionExistence })(ExistingConnectionCheck)
);
