import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Context
import { useLang } from '../../../context/LangContext';

import OfficesList from './OfficesList';
import Office from './Office';

const OrganizationInfo = ({ userData, activeOrg, orgs }) => {
    const lang = useLang()['Settings']['OrganizationInfo'];
    const officeLang = lang.office;
    const [organization, setOrganization] = useState(null);
    const [userOrgs, setUserOrgs] = useState(null);
    const [activeOrgId, setActiveOrgId] = useState(null);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (userData && activeOrg) {
            setOrganization(orgs.length > 1 ? null : activeOrg);
            setLocation(
                orgs.length > 1 ? null : activeOrg.locations[userData.active_location_id]
            );
            setActiveOrgId(activeOrg.id);
        }
    }, [userData, activeOrg, orgs, setOrganization, setLocation, setActiveOrgId]);

    useEffect(() => {
        if (orgs) {
            setUserOrgs(orgs);
        }
    }, [orgs, setUserOrgs]);

    const handleOfficeChange = (organization, office) => {
        setOrganization(organization);
        setLocation(office);
    };

    if (!activeOrg && !userData) return null;

    return (
        <>
            <div className="card-header d-flex align-items-center justify-content-between">
                <h1 className="h5 font-weight-medium mb-0 ml-2">{lang.heading}</h1>
                <div className="d-flex align-items-center justify-content-end card-header__actions">
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center mr-2"
                        style={{ maxHeight: '36px' }}
                    >
                        {lang.addOrg_label}
                    </button>
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center"
                        style={{ maxHeight: '36px' }}
                    >
                        {lang.addOffice_label}
                    </button>
                </div>
            </div>
            {orgs?.length > 1 ? (
                <OfficesList
                    organization={organization}
                    location={location}
                    lang={lang}
                    orgs={userOrgs}
                    activeOrgId={activeOrgId}
                    handleOfficeChange={handleOfficeChange}
                />
            ) : (
                <Office
                    organization={organization}
                    location={location}
                    officeLang={officeLang}
                    orgs={userOrgs}
                    handleOfficeChange={handleOfficeChange}
                />
            )}
        </>
    );
};

const mapStateToProps = ({ user, org }) => {
    const { userData } = user;
    const { activeOrg, orgs } = org;
    return { userData, activeOrg, orgs };
};

export default connect(mapStateToProps)(OrganizationInfo);
