import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import userSagas from './User';
import transactionSagas from './Transactions';
import preferenceSagas from './Preferences';
import settingsSagas from './Settings';
import superAdminSagas from './SuperAdmin';
import propertySagas from './Property';
import documentSagas from './Documents';
import contactsSagas from './CompanyContacts';
import connectionsSagas from './Connections';
import langSagas from './Lang';
import transactionActivitySagas from './TransactionActivity';

export default function* rootSaga() {
    yield all([
        authSagas(),
        userSagas(),
        transactionSagas(),
        preferenceSagas(),
        settingsSagas(),
        superAdminSagas(),
        propertySagas(),
        documentSagas(),
        contactsSagas(),
        connectionsSagas(),
        langSagas(),
        transactionActivitySagas()
    ]);
}
