// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Link } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const DetailsButton = ({ id, contactAvatar }) => {
    const { button_label } = useLang()['CompanyContacts']['ContactRow'];

    return (
        <Link
            to={{
                pathname: `${routes.AUTHENTICATED}${routes.CONTACT_DETAIL}/${id}`,
                state: { id, imgUrl: contactAvatar }
            }}
            className={'btn btn-block btn-soft-primary transition-3d-hover ml-auto'}
        >
            {button_label}
            <span className="fas fa-angle-right ml-1" />
        </Link>
    );
};

export default DetailsButton;
