import {
    all,
    fork,
    takeLatest,
    put,
    call,
    take,
    race,
    select,
    cancelled
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    LOGOUT_USER,
    CHECK_CONNECTION_EXISTENCE,
    ADD_NEW_CONNECTION
} from '../actions/types';

import {
    storingUserConnections,
    storeConnectionMatches,
    addNewConnectionSuccess,
    addNewConnectionFailure
} from '../actions/Connections';

import { db, rtdb, func } from '../../config/Firebase';

import * as selectors from './Selectors';

import { confirmationDialogTypes } from '../../utils/Constants';
import { setConfirmModalType } from '../actions/Modal';

// Helpers
import { FS_USER_SCHEMA, RTDB_CONNECTION_SCHEMA } from '../../utils/Constants';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');

const createNewConnections = func.httpsCallable('createNewClientRequest');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Contacts Collection Watch //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* connectionsCollectionWatch(user) {
    const userConnectionsRef = rtdb.ref(`connections/${user.id}`);
    const userConnectionsCollectionChannel = eventChannel(emit => {
        const unsubscribeUserConnectionsData = userConnectionsRef.on(
            'value',
            querySnapshot => {
                if (querySnapshot && querySnapshot.val()) {
                    var userContacts = [];
                    userContacts = Object.values(querySnapshot.val());
                    emit(userContacts);
                } else {
                    const doc = { empty: true };
                    emit({ doc });
                }
            }
        );
        return unsubscribeUserConnectionsData;
    });

    try {
        while (true) {
            const { userSignOut, userConnectionsData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userConnectionsData: take(userConnectionsCollectionChannel)
            });

            if (userSignOut) {
                userConnectionsCollectionChannel.close(); // Detach saga event emitter
            }
            if (userConnectionsData)
                yield put(storingUserConnections(userConnectionsData));
        }
    } catch (error) {
        log('Connections Error: getting connections collection data on User (RTDB)', {
            error,
            user,
            function: 'connectionsCollectionWatch'
        });
    } finally {
        userConnectionsRef.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            userConnectionsRef.off('value'); // Detach firebase listener
        }
    }
}

const checkingConnectionExistenceRequest = async ({ connection, userData }) => {
    const email = connection.email;
    return new Promise((resolve, reject) => {
        users
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                const matches = [];
                if (querySnapshot.size > 0) {
                    querySnapshot.forEach(doc => {
                        if (doc.data()) {
                            matches.push(doc.data());
                        }
                    });
                    resolve({ res: matches });
                } else {
                    resolve({ res: matches });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};

export function* checkingConnectionExistence({ payload }) {
    const { connection } = payload;
    const { res, error } = yield call(() =>
        checkingConnectionExistenceRequest({ connection })
    );
    if (res) {
        yield put(storeConnectionMatches(res));
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Connections Error: checking connection existence (FS)', {
            error,
            connection
        });
    }
}

const writeNewDBConnections = ({ connections, user, data, allConnections }) => {
    return new Promise((resolve, reject) => {
        const processed = [];
        connections.forEach((member, index) => {
            const memberData = data.processed.filter(
                connection => connection.formId === member.id
            )[0];
            const altMember = allConnections.filter(
                connection => connection.formId !== member.id
            )[0];
            const doc = users.doc(`/${memberData.id}`);
            const newConnection = Object.assign(FS_USER_SCHEMA, {
                address: member.sameAddress
                    ? {
                          address_1: altMember.address,
                          address_2: altMember.address2,
                          city: altMember.city,
                          state: altMember.state,
                          zip: altMember.zip,
                          lat: altMember.lat,
                          lon: altMember.lon
                      }
                    : {
                          address_1: member.address,
                          address_2: member.address2,
                          city: member.city,
                          state: member.state,
                          zip: member.zip,
                          lat: member.lat,
                          lon: member.lon
                      },
                email: member.email,
                first_name: member.firstName,
                id: memberData.id,
                invite_code: memberData.code,
                last_name: member.lastName,
                legal_name: member.fullLegalName,
                phone: member.phone
                    ? [
                          {
                              number: member.phone,
                              type: 'mobile',
                              primary: true
                          }
                      ]
                    : null,
                role: 'user',
                type: 'client'
            });
            return doc
                .set({ ...newConnection })
                .then(() => {
                    const connectionRef = rtdb.ref(
                        `connections/${user.id}/${memberData.id}`
                    );
                    const newConnection = Object.assign(RTDB_CONNECTION_SCHEMA, {
                        address: member.sameAddress
                            ? {
                                  address_1: altMember.address,
                                  address_2: altMember.address2,
                                  city: altMember.city,
                                  state: altMember.state,
                                  zip: altMember.zip,
                                  lat: altMember.lat,
                                  lon: altMember.lon
                              }
                            : {
                                  address_1: member.address,
                                  address_2: member.address2,
                                  city: member.city,
                                  state: member.state,
                                  zip: member.zip,
                                  lat: member.lat,
                                  lon: member.lon
                              },
                        email: member.email,
                        first_name: member.firstName,
                        id: memberData.id,
                        last_name: member.lastName,
                        legal_name: member.fullLegalName,
                        phone: member.phone
                    });
                    return connectionRef.set({ ...newConnection }, error => {
                        if (error) {
                            log(
                                'Connections Error: storing new user connection(s) (RTDB)',
                                {
                                    error
                                }
                            );
                            reject(error);
                        } else {
                            processed.push({
                                first_name: member.firstName,
                                id: memberData.id,
                                last_name: member.lastName
                            });
                        }
                        if (processed.length === connections.length) {
                            resolve({ data: { processed: processed } });
                        }
                    });
                })
                .catch(error => {
                    log('Connections Error: storing new user connection(s) (FS)', {
                        error
                    });
                    reject(error);
                });
        });
    });
};

const addNewAuthUserConnections = async ({ connections, user, allConnections }) => {
    const newConnections = await createNewConnections({ connections });
    if (newConnections) {
        const processed = await writeNewDBConnections({
            connections: connections,
            user: user,
            data: newConnections.data,
            allConnections: allConnections
        });
        return processed;
    }
};

const addExistingUserConnections = ({ connections, user, allConnections }) => {
    return new Promise((resolve, reject) => {
        const processed = [];
        connections.forEach((member, index) => {
            const { connection, docData } = member;
            const { id } = user;
            const altMember = allConnections.filter(
                altConnection => altConnection.formId !== connection.id
            )[0];
            const contactRef = rtdb.ref(`connections/${id}/${docData.id}`);
            const newConnection = Object.assign(RTDB_CONNECTION_SCHEMA, {
                address: connection.sameAddress
                    ? {
                          address_1: altMember.address,
                          address_2: altMember.address2,
                          city: altMember.city,
                          state: altMember.state,
                          zip: altMember.zip,
                          lat: altMember.lat,
                          lon: altMember.lon
                      }
                    : {
                          address_1: connection.address,
                          address_2: connection.address2,
                          city: connection.city,
                          state: connection.state,
                          zip: connection.zip,
                          lat: connection.lat,
                          lon: connection.lon
                      },
                email: connection.email,
                id: docData.id,
                first_name: connection.firstName,
                last_name: connection.lastName,
                notes: docData.notes ? docData.notes : '',
                phone: connection.phone,
                legal_name: connection.fullLegalName
            });
            contactRef.set({ ...newConnection }, error => {
                if (error) {
                    log(
                        'Connections Error: storing existing users new connection(s) (RTDB)',
                        {
                            error
                        }
                    );
                    reject({ error });
                } else {
                    processed.push({
                        first_name: connection.firstName,
                        id: docData.id,
                        last_name: connection.lastName
                    });
                    if (index + 1 === connections.length) {
                        resolve({
                            data: {
                                processed: processed
                            }
                        });
                    }
                }
            });
        });
    });
};

const addRTDBConnection = ({ connections, user }) => {
    return new Promise((resolve, reject) => {
        connections.forEach((member, index) => {
            const { id } = user;
            const contactRef = rtdb.ref(`connections/${id}/${member.id}/connection`);
            contactRef.set(
                {
                    first_name: connections[index === 0 ? 1 : 0].first_name,
                    last_name: connections[index === 0 ? 1 : 0].last_name,
                    id: connections[index === 0 ? 1 : 0].id
                },
                error => {
                    if (error) {
                        log(
                            'Connections Error: creating connection key of connection(s) (RTDB)',
                            {
                                error
                            }
                        );
                        reject({ error });
                    } else {
                        if (index + 1 === connections.length) {
                            resolve(true);
                        }
                    }
                }
            );
        });
    });
};

const classifiedConnections = async ({ connection, primary, link }) => {
    return new Promise((resolve, reject) => {
        const existing = [];
        const nonExisting = [];
        connection.forEach(member => {
            if ((primary && member.id === 0) || (link && member.id === 1)) {
                existing.push({
                    connection: member,
                    docData: member.id === 0 ? primary : link
                });
            } else {
                nonExisting.push(member);
            }
            if (member.id + 1 === connection.length) resolve({ existing, nonExisting });
        });
    });
};

const processNewConnections = async ({ connection, primary, link, user }) => {
    const connections = await classifiedConnections({ connection, primary, link });
    return await {
        processNonExisting: connections.nonExisting.length
            ? await addNewAuthUserConnections({
                  connections: connections.nonExisting,
                  user,
                  allConnections: connection
              })
            : { data: { processed: [] } },
        processExisting: connections.existing.length
            ? await addExistingUserConnections({
                  connections: connections.existing,
                  user,
                  allConnections: connection
              })
            : { data: { processed: [] } }
    };
};

const addingNewConnectionsRequest = ({ connection, primary, link, user }) => {
    return new Promise((resolve, reject) => {
        processNewConnections({ connection, primary, link, user }).then(res => {
            const { processExisting, processNonExisting } = res;
            if (processExisting && processNonExisting) {
                if (connection.length > 1) {
                    addRTDBConnection({
                        connections: [
                            ...processExisting?.data?.processed,
                            ...processNonExisting?.data?.processed
                        ],
                        user
                    }).then(res => {
                        resolve({ res: true });
                    });
                } else {
                    resolve({ res: true });
                }
            }
        });
    });
};

export function* addingNewConnections({ payload }) {
    const { connection, primary, link } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        addingNewConnectionsRequest({ connection, primary, link, user: userData })
    );
    if (res) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
        yield put(addNewConnectionSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Connections Error: storing new connection(s) (FS/RTDB)', {
            error,
            connection,
            primary,
            link,
            user: userData
        });
        yield put(addNewConnectionFailure());
    }
}

export function* checkConnectionExistence() {
    yield takeLatest(CHECK_CONNECTION_EXISTENCE, checkingConnectionExistence);
}

export function* addNewConnections() {
    yield takeLatest(ADD_NEW_CONNECTION, addingNewConnections);
}

export default function* rootSaga() {
    yield all([fork(checkConnectionExistence), fork(addNewConnections)]);
}
