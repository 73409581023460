import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import SimpleBar from 'simplebar-react';

// Hooks
import { useUserAvatars } from '../../../hooks/useUserAvatars';

// Components
import StatusDropdown from '../OrganizationInfo/StatusDropdown';
import MultiSelect, { Option, MultiValue } from '../../common/MultiSelect';

export const TeamList = ({ lang, orgs = {} }) => {
    const statusesItems = [
        {
            name: 'Active'
        },
        {
            name: 'Inactive'
        }
    ];

    const teamMemberTypes = [
        { value: 'agent', label: 'Agent' },
        { value: 'broker', label: 'Broker' }
    ];

    const teamMembers = Object.values(orgs)
        .map(org =>
            Object.values(org)
                .map(location => location)
                .map(member => member.team)
        )
        .flat(2)
        .filter(
            (member, index, arr) => arr.findIndex(tmp => tmp.id === member.id) === index
        );

    const [imgByIds, setImgByIds] = useState([]);
    const [types, setTypes] = useState([]);
    const ids = teamMembers.map(member => member.id);
    useUserAvatars(ids, setImgByIds);

    const handleSelect = selected => {
        setTypes(selected);
    };

    return (
        <SimpleBar className="container pl-5" style={{ overflowY: 'auto' }}>
            <div className="row d-flex justify-content-center mb-2">
                <div className="col-4">
                    <h6 className="font-weight-medium">{lang.name_label}</h6>
                </div>
                <div className="col-3 pl-0">
                    <h6 className="font-weight-medium">{lang.status_label}</h6>
                </div>
                <div className="col-5 pl-0">
                    <h6 className="font-weight-medium">{lang.type_label}</h6>
                </div>
            </div>
            {teamMembers.map(member => (
                <div
                    key={member.id}
                    className="row d-flex align-items-center w-100 mt-3 mb-4"
                >
                    <div className="col-4 d-flex flex-row align-items-center">
                        <div>
                            {imgByIds.map(avatar => (
                                <React.Fragment key={avatar.id}>
                                    {avatar.id === member.id && (
                                        <img
                                            src={avatar.url}
                                            alt="User Avatar"
                                            className="rounded-circle contact-frame__avatar--small mr-3"
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div>
                            <h6 className="h6 font-weight-medium mb-0 pointer">
                                {member?.first_name} {member?.last_name}
                            </h6>
                            <p className="small mb-0">{member?.email}</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <Formik
                            initialValues={{
                                status: statusesItems[0].name
                            }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <Field
                                        name="status"
                                        component={StatusDropdown}
                                        options={statusesItems}
                                        onChange={status => {
                                            setFieldValue('status', status);
                                            console.log(values);
                                        }}
                                        statuses
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="col-5 d-flex align-items-center">
                        <MultiSelect
                            options={teamMemberTypes}
                            isMulti
                            placeholder={lang.type_label}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option,
                                MultiValue
                            }}
                            onChange={handleSelect}
                            allowSelectAll={true}
                            value={types}
                        />
                        <FontAwesomeIcon
                            className="pointer mb-0 ml-5"
                            icon={faLock}
                            color="#8c98a4"
                        />
                    </div>
                </div>
            ))}
        </SimpleBar>
    );
};
