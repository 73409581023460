/******************************************************************************************************************/
/******************************************* Multilingual Term Library ********************************************/
/******************************************************************************************************************/

import { english } from 'flatpickr/dist/l10n/default.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

const library = {
    en: {
        lang_name: `English`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {
                new_connections_label: `Add New Connection`,
                create_button_label: `Save Connection`,
                cancel_button_label: `Cancel`
            },

            AddDocToTrxModal: {
                add_label: `Add to transaction`,
                search_placeholder: `Search transactions`,
                select_all_label: `Select all`,
                mls_label: `MLS#`,
                add_button_label: `Add document`,
                cancel_button_label: `Cancel`,
                already_exists_message: `This Document already exists in all of your active Transactions.`,
                no_active_message: `You do not have any active Transactions.  Documents can only be added to active Transactions`,
                transaction_button_label: `New Transaction`,
                close_button_label: `Close`
            },
            AddDocumentModal: {
                drag_click_label: 'Drag files here or click to upload',
                upload_label: `Upload document`,
                name_label: `Name: `,
                size_label: `Size: `,
                add_title_label: `Add title`,
                optional_label: `(Optional)`,
                title_placeholder: `Title`,
                doc_type_label: `Document type`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                add_button_label: `Add document`,
                cancel_button_label: `Cancel`
            },
            AddTaskModal: {
                add_task_label: `Add a Task`,
                add_task_tip: `Creating a new task allows the task to be optionally added to any new transaction during initial creation.`,
                trx_type_label: `Transaction Type`,
                buying_label: `Buying`,
                listing_label: `Listing`,
                task_title_label: `Task Title`,
                task_title_placeholder: `Title`,
                task_desc_label: `Task Description`,
                task_desc_placeholder: `Description`,
                default_expire_label: `Default Epiration Time`,
                days_label: `Days`,
                hour_label: `Hour`,
                min_label: `Minute`,
                ordinal_label: `AM/PM`,
                create_button_label: `Create Task`,
                cancel_button_label: `Cancel`
            },
            AppHeader: {},
            AvatarEditModal: {
                change_avatar_label: `Change your avatar`,
                update_button_label: `Update avatar`,
                cancel_button_label: `Cancel`
            },
            ClickWrapper: {},
            CreatableSelectField: {
                add_custom_label: `Add custom source`
            },
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {
                title_placeholder: `Title`,
                description_placeholder: `Description`,
                date_time_placeholder: `Date and time`,
                open_button_label: `Open Task`,
                complete_button_label: `Task Complete`,
                delete_button_label: `Delete Task`,
                update_button_label: `Update Task`
            },
            Loader: {},
            MainHeader: {
                transactions_link: `Transactions`,
                connections_link: `Connections`,
                forms_link: `Forms Library`,
                documents_link: `My Documents`,
                profile_link: `Profile`,
                preferences_link: `Preferences`,
                messages_link: `Messages`,
                transaction_button_label: `New Transaction`,
                connection_button_label: 'Add Connection',
                library_button_label: 'Add Form',
                document_button_label: 'Add Document'
            },
            RemoveTaskModal: {
                remove_task_label: `Remove a Task`,
                assurance_label: `Are you sure you want to remove this task?`,
                delete_button_label: `Delete Task`,
                cancel_button_label: `Cancel`
            },
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {
                home_link: `Home`,
                settings_link: `Settings`,
                messages_link: `Messages`,
                contacts_link: `Company Contacts`,
                logout_link: `Logout`
            },
            StateCountrySelect: {
                select_label: `Select`
            },
            TextField: {},
            TransactionUploadModal: {
                image_label: `Image`,
                file_label: `File`,
                attach_file_label: `attachment`,
                add_title_label: `Add Title (Optional)`,
                add_title_placeholder: `Title`,
                name_label: `Name: `,
                size_label: `Size: `,
                attach_button_label: `Attach`,
                attach_send_button_label: `Attach and `,
                cancel_button_label: `Cancel`
            },
            ConfirmationDialog: {
                /**
                 * Instruction:
                 * To add a new type of Confirmation Dialog you should add translations for all states in which
                 * dialog can be for this type or it will use common translation. To create specific one follow
                 * next naming rule: `{confirmationDialogType}_[title/text/confirmButtonText/cancelButtonText]`.
                 */
                Confirmation: {
                    // Common
                    title: `Are you sure?`,

                    // Specific
                    cancel_text: `All changes will be lost.`,
                    delete_text: `This action cannot be undone!`,
                    activateTrx_text: `All parties will now have access to this transaction.`,
                    reopenTrx_text: `This transaction has been closed, please confirm you want to reopen it.`,
                    closeTrx_text: `Please enter a closing date`,
                    archiveTrx_text: `This action will disable all editing and remove access to all parties. You can reset to Active if needed in the future.`,

                    // Buttons
                    // Common
                    confirmButtonText: `Confirm!`,
                    cancelButtonText: `Cancel`,
                    // Specific
                    delete_confirmButtonText: `Yes, delete it!`,
                    cancel_confirmButtonText: `Yes, cancel!`,
                    cancel_cancelButtonText: `No, stay!`,

                    // Additional items
                    closeTrx_inputPlaceholder: `Select a date`,
                    closeTrx_validationError: `Please pick a closing date.`
                },
                Loading: {
                    // Common
                    title: `Processing...`,
                    text: `Please, wait.`
                },
                Success: {
                    // Common
                    title: `Success!`,
                    text: `Your changes have been updated.`,
                    confirmButtonText: `OK`,

                    // Specific
                    delete_title: `Deleted!`,
                    delete_text: `Selected item has been deleted.`,
                    uploadDocument_text: `Your document has been uploaded.`,
                    uploadConnection_text: `Your connection(s) have been uploaded.`
                },
                Failed: {
                    // Common
                    title: `Ooops...`,
                    text: `Something went wrong!`,
                    confirmButtonText: `OK`
                }
            },
            Dropdown: {
                delete_label: 'Delete',
                edit_label: 'Edit'
            },
            ActionButtons: {
                cancel_label: 'Cancel',
                save_label: 'Save changes'
            }
        },
        CompanyContacts: {
            ContactDetailsHistory: {},
            ContactList: { no_contacts_label: `You have no contacts!` },
            ContactRow: { button_label: `Details` },
            ContactRowSkeleton: {},
            ContactsSearchFilter: {
                all_label: `All Contacts`,
                active_label: `Active Contacts`,
                inactive_label: `Inactive Contacts`,
                input_label: `Search contacts`
            }
        },
        ContactDetails: { button_label: `Send message` },
        Constants: {
            errorMessage: {
                emailPasswordCombo: `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser: `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                emailPrimaryLinkMatch: `Email address cannot be the same as Primary connection.`,
                password: {
                    lowerCase: `Must contain at least one lowercase letter`,
                    upperCase: `Must contain at least one uppercase letter`,
                    numeric: `Must contain at least one number`,
                    length: `Must be at least 8 characters long`,
                    required: `Please enter a password`
                },
                email: {
                    valid: `Please enter a valid email address`,
                    required: `Email address is required`
                },
                phone: {
                    valid: `Please enter a valid phone number`,
                    required: `Phone number is required`
                },
                phoneExt: {
                    valid: `Enter a valid ext.`
                },
                address: {
                    required: `Address is required`,
                    valid: `Please enter a valid address`
                },
                firstName: {
                    valid: `Please enter a valid first name`,
                    required: `First name is required`
                },
                middleInitial: {
                    valid: `Please enter a valid middle initial`
                },
                lastName: {
                    valid: `Please enter a valid last name`,
                    required: `Last name is required`
                },
                city: {
                    valid: `Please enter a valid city`,
                    required: `City is required`
                },
                state: {
                    required: `State required`
                },
                zip: {
                    required: `Zip/Postal code is required`
                },
                country: {
                    required: `Country is required`
                },
                fromDate: {
                    required: `Start date is required`
                },
                toDate: {
                    required: `End date is required`
                },
                listingPrice: {
                    required: `Listing price is required`
                },
                commission: {
                    required: `Commission is required`,
                    valid: `Commission must be a number`
                },
                split: {
                    required: `Agent split is required`,
                    valid: `Agent split must be a number`
                },
                fee: {
                    required: `Transaction fee is required`,
                    valid: `Transaction fee must be a number`
                },
                businessSrc: {
                    required: `Source is required`
                },
                emailOrPhone: {
                    required: `Email or phone is required`
                },
                firstOrLastName: {
                    required: `First or last name is required`
                },
                licNumber: {
                    required: `Lic number is required`
                },
                mlsId: {
                    required: `MLS id is required`
                }
            }
        },
        Connections: {
            AddNewConnection: {},
            ConnectionList: { no_connections_label: `You have no connections!` },
            ConnectionMatches: {
                match_label: `Potential Match`,
                incorrect_match_single: `This is not the correct match.`,
                incorrect_match_multiple: `These are not the correct matches.`,
                select_match: `Select`
            },
            ConnectionProgress: {},
            ConnectionRow: { button_label: `Details` },
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {
                input_label: `Search connections`
            },
            ExistingConnectionCheck: {
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`
            },
            NewConnectionForm: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Legal Name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                mailing_address_same_label: `Mailing address same as primary connection?`,
                connection_notes_label: `Connection Notes`,
                connection_notes_placeholder: `Connection notes`,
                remove_connection_label: `Remove link`,
                add_another_connection_label: `Add another connection`,
                link_button: `Link connection`,
                primary_button: `Primary connection`,
                add_party_text: `Add a linked party`,
                add_party_subtext: `spouse, partner, parent, etc..`,
                save_button: `Save`
            }
        },
        ConnectionDetails: {
            notes_heading: 'Notes',
            history_heading: 'History',
            status: {
                buyer: 'Buyer',
                seller: 'Seller'
            },
            button_label: 'New Transaction'
        },
        Dashboard: {},
        DateTime: { locale: english },
        Documents: {
            DocumentCard: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`
            },
            DocumentsGrid: {},
            DocumentRow: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                button_label: `View Document`,
                file_name_label: `File name`,
                upload_date_label: `Upload date`
            },
            DocumentsSearchFilter: {
                input_label: `Search documents`,
                all_label: `All Documents`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                dropdown_label: `Document Title`
            }
        },
        Landing: {
            title_1: `Welcome. This could be the start of something `,
            title_2: `big!`,
            button_label: `Let's Get Started`
        },
        Library: {},
        Login: {
            title_1: `Welcome `,
            title_2: `back`,
            sub_title: `Login to access your account.`,
            email_label: `EMAIL ADDRESS`,
            email_placeholder: `Email address`,
            password_label: `PASSWORD`,
            forgot_password: `Forgot Password?`,
            no_account: `Don't have an account? `,
            no_account_link: `Learn more`,
            button_label: `Let's Go`
        },
        Messages: {},
        NewConnection: {
            title: `Yes! Making connections.`,
            sub_text: `Let's start with the basics to check if your new contact may already have an account.  If not you'll be able to invite them or enter their information manually.`
        },
        NewDocument: {
            back_button_label: 'Go back'
        },
        NewTransaction: {
            BuyerFlowStep1: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                middle_initial_label: `MI`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Full legal name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `Mailing address same as above?`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                add_party_label: `Add another party`,
                remove_party_label: `Remove party`,
                next_button_label: `Next`
            },
            BuyerFlowStep2: {
                closing_date_label: `Closing Date`,
                closing_date_placeholder: `Closing date`,
                purchase_price_label: `Purchase Price`,
                purchase_price_placeholder: `Price`,
                business_src_label: `Business Source`,
                business_src_placeholder: `Select`,
                upload_img_label: `Upload images`,
                drag_drop_label: `Browse your device and upload images`,
                max_size_label: `Maximum file size is 2MB`,
                img_optional_label: `This is optional. We'll grab more images from MLS later.`,
                back_button_label: `Back`,
                next_button_label: `Next`
            },
            BuyerFlowStep3: {
                commission_label: `Buying Commission`,
                commission_placeholder: `0`,
                split_label: `Agent Split`,
                split_placeholder: `0`,
                fee_label: `Transaction Fee`,
                fee_placeholder: `0`,
                forms_label: `Initial forms`,
                tasks_label: `Initial tasks`,
                back_button_label: `Back`,
                done_button_label: `Done`
            },
            BuyerSelectForm: {
                select_label: `Select`,
                property_info_label: `Property information`,
                mls_option_label: `Enter MLS number.`,
                address_option_label: `Not in MLS? Enter the address.`,
                property_address_label: `Property Address`,
                address_placeholder: `Start typing address`,
                city_label: `City`,
                city_placeholder: `City`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                state_label: `State`,
                zip_label: `Zip Code`,
                zip_placeholder: `Zip code`,
                mls_number_label: `MLS Number`,
                mls_number_placeholder: `Enter MLS ID`,
                property_found_label: `Property found!`,
                property_not_found_label: `Property not found.`,
                button_label: `Let's go`,
                mls_button_label: `Find`
            },
            BuyerTransactionFlow: {},
            FormHeader: {
                step_1_label: `Parties`,
                step_2_label: `Details`,
                step_3_label: `Set Up`
            },
            ListingFlowStep1: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                middle_initial_label: `MI`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Full legal name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `Mailing address same as listing address?`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                add_party_label: `Add another party`,
                remove_party_label: `Remove party`,
                next_button_label: `Next`
            },
            ListingFlowStep2: {
                listing_start_label: `Listing Start Date`,
                listing_start_placeholder: `From`,
                listing_expiration_label: `Expiration Date`,
                listing_expiration_placeholder: `To`,
                listing_price_label: `Listing Price`,
                listing_price_placeholder: `Price`,
                business_src_label: `Business Source`,
                business_src_placeholder: `Select`,
                upload_img_label: `Upload images`,
                drag_drop_label: `Browse your device and upload images`,
                max_size_label: `Maximum file size is 2MB`,
                img_optional_label: `This is optional. We'll grab more images from MLS later.`,
                back_button_label: `Back`,
                next_button_label: `Next`
            },
            ListingFlowStep3: {
                commission_label: `Selling Commission`,
                commission_placeholder: `0`,
                split_label: `Agent Split`,
                split_placeholder: `0`,
                fee_label: `Transaction Fee`,
                fee_placeholder: `0`,
                forms_label: `Initial forms`,
                tasks_label: `Initial tasks`,
                back_button_label: `Back`,
                done_button_label: `Done`
            },
            ListingSelectForm: {
                select_label: `Select`,
                property_info_label: `Property information`,
                manual_option_label: `Address not found? Enter it manually.`,
                mls_option_label: `Have an MLS number?`,
                address_option_label: `Have an address?`,
                address_label: `Address Lookup`,
                address_placeholder: `Start typing address`,
                property_address_label: `Property Address`,
                city_label: `City`,
                city_placeholder: `City`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                state_label: `State`,
                zip_label: `Zip Code`,
                zip_placeholder: `Zip code`,
                description_label: `Lot/Land Description`,
                description_placeholder: `Description`,
                mls_number_label: `Mls Number`,
                mls_number_placeholder: `Enter MLS ID`,
                property_found_label: `Property found!`,
                property_not_found_label: `Property not found.`,
                or_label: `Or`,
                cancel_link: `Cancel`,
                button_label: `Let's go`,
                mls_button_label: `Find`
            },
            ListingTransactionFlow: {},
            NewTransactionHeader: { back_button_label: `Go back` },
            TransactionTypeSelect: {
                title_label: `Woohoo! Let's do this.`,
                listing_button_label: `Listing`,
                buyer_button_label: `Buyer`
            }
        },
        PdfViewer: {
            ControllerHeader: {
                fillable_button_label: `Make Fillable`,
                edit_button_label: `Edit fields`,
                add_trx_button_label: `Add to Transaction`
            },
            ViewController: {
                of_label: `of`
            }
        },
        Profile: {},
        Settings: {
            save_label: 'Save changes',
            cancel_label: 'Cancel',
            SettingsMenu: {
                app_integratons_link: `App Integrations`,
                billing_link: `Billing`,
                organization_info_link: `Organization Info`,
                personal_info_link: `Personal Info`,
                preferences_link: `Preferences`,
                security_settings_link: `Security Settings`,
                team_link: `Team`,
                edit_label: `edit`,
                file_size_warning: `File is too large.`
            },
            OrganizationInfo: {
                heading: 'Organization info',
                name_label: 'Office Name',
                status_label: 'Status',
                type_label: 'Office Type',
                addOffice_label: 'Add office',
                addOrg_label: 'Add org',
                addPhone_label: 'Add phone',
                officeStatuses: {
                    active_label: 'Active',
                    inactive_label: 'Inactive'
                },
                officeTypes: {
                    main_label: 'Main',
                    branch_label: 'Branch',
                    satellite_label: 'Satellite'
                },
                office: {
                    labels: {
                        licNumber: 'Lic number',
                        mlsId: 'MLS id',
                        officeLogo: 'Office logo',
                        officeLogo_tooltip:
                            'For best results, the logo file should be either a .png or .svg with a minimum  height of 98px.',
                        officeName: 'Office name',
                        officeAddress: 'Office address',
                        officeMLS: 'Office MLS id',
                        phone: 'Phone',
                        uploadPhoto: 'Upload photo',
                        remove: 'Remove'
                    },
                    placeholders: {
                        enterNumber: 'Enter number',
                        enterId: 'Enter id',
                        enterName: 'Enter office name',
                        enterPhone: 'Enter phone number',
                        enterAddress: 'Enter address'
                    },
                    phoneTypes: {
                        office: 'office',
                        mobile: 'mobile',
                        fax: 'fax'
                    }
                }
            },
            PersonalInfo: {
                main_title: 'Personal Info',
                lic_number: 'Lic number',
                mls_id: 'MLS id',
                tooltip_mls_id:
                    'Your MLS ID will only be used for keeping your MLS listings in sync with your transactions in Jada',
                name_label: 'Name',
                email_label: 'Email',
                phone_label: 'Phone',
                about_label: 'About',
                add_phone_label: 'Add phone',
                placeholder_licence: 'Enter your licence number',
                placeholder_mls_id: 'Enter your id',
                placeholder_first_name: 'Enter your first name',
                placeholder_last_name: 'Enter your last name',
                placeholder_email: 'Enter your email',
                placeholder_phone: 'Enter your number',
                placeholder_editor: 'Enter text',
                dropdown_title: 'Mobile',
                dropdown_item_office: 'Office',
                dropdown_item_mobile: 'Mobile',
                dropdown_item_fax: 'Fax',
                modal_title: 'Please confirm your password',
                modal_password_placeholder: 'Enter your password',
                modal_button_cancel: 'Cancel',
                modal_button_save: 'Save changes'
            },
            SecuritySettings: {
                main_heading: 'Security Settings',
                info_text: 'Update your password for account access.',
                newPass_label: 'New password',
                repeat_label: 'Re-type New Password',
                save_label: 'Save changes',
                cancel_label: 'Cancel'
            },
            Team: {
                main_title: 'Team',
                header: {
                    add_member_label: 'Add member'
                },
                teamList: {
                    name_label: 'Name',
                    status_label: 'Status',
                    type_label: 'Account type',
                    statuses: {
                        active_label: 'Active',
                        inactive_label: 'Inactive'
                    }
                }
            },
            AppIntegrations: {},
            Billing: {},
            Preferences: {}
        },
        TransactionDetails: {
            Activity: {},
            ActivityInput: {
                added_task_label: `Added a new task: `,
                upload_a_img_label: `Uploaded an image: `,
                upload_a_file_label: `Uploaded a file: `,
                image_label: `Image`,
                file_label: `File`,
                whats_up_placeholder: `What's happening...`,
                task_title_placeholder: `Task Title`,
                task_note_placeholder: `Task Note`,
                date_time_placeholder: `Date and time`,
                attachment_button_label: `Attachment`,
                task_button_label: `Task`,
                post_button_label: `Post`,
                add_task_label: `Add Task`,
                uploaded_label: `uploaded:`
            },
            ActivityThread: {
                no_messages_label: `No Messages yet.`
            },
            AddSearch: {
                all_label: `All`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                add_label: `Add`,
                search_placeholder: `Search`
            },
            DetailsHeader: {
                TransactionStatus: {
                    active: 'Active',
                    pending: 'Pending',
                    closed: 'Closed',
                    archived: 'Archived'
                },
                edit_transaction: 'Edit Transaction'
            },
            DocumentDrop: {
                drag_drop_label: `Drag files here to upload`,
                add_label: `Add`,
                last_update_label: `Last updated`
            },
            DocumentList: {
                view_label: `View`,
                download_label: `Download`,
                archive_label: `Archive`,
                fill_label: `Fill Fields`,
                not_started_label: `Not started`,
                completed_label: `Completed`
            },
            Documents: {},
            DocumentView: {
                of_label: `of`
            },
            NoteDrop: {
                note_title_placeholder: `Note title`,
                note_content_placeholder: `Note content`,
                private_label: `Private`,
                add_note_button_label: `Add Note`
            },
            NoteList: {
                assurance_label: `Are you sure you want to remove this note?`,
                remove_label: `Remove`,
                cancel_label: `Cancel`
            },
            Notes: {},
            TabNavigation: {
                activity_label: `Activity`,
                documents_label: `Documents`,
                notes_label: `Notes`
            },
            Tasks: {
                complete_status_message: `Updated task status to Complete: `,
                open_status_message: `Updated a task status to Open: `,
                updated_status_message: `Updated a task: `,
                deleted_status_message: `Deleted a task: `,
                all_tasks_button_label: `All Tasks`,
                less_tasks_button_label: `Less Tasks`,
                hide_history_button_label: `Hide Task History`,
                show_history_button_label: `Show Task History`,
                add_task_button_label: `Add a Task`,
                no_tasks_label: `No scheduled tasks`
            },
            TransactionInterface: {},
            TransactionMeta: {
                seller_agent_label: `Seller's Agent`,
                buyer_agent_label: `Buyer's Agent`,
                days_market_label: `Days on market`,
                closing_in_label: `Closing in`,
                days_label: `days`,
                no_close_date_label: `No closing date`,
                team_label: `Team members:`,
                seller_label: `Seller`,
                buyer_label: `Buyer`,
                primary_agent_label: `Buyer's agent`,
                primary_broker_label: `Buyer's broker`
            },
            EditTransactionDetails: {
                price_label: `Edit Price`,
                price_placeholder: `Price`,
                closingDate_label: `Edit Closing Date`,
                closingDate_placeholder: `Closing date`
            }
        },
        Transactions: {
            active_label: `Active`,
            pending_label: `Pending`,
            closed_label: `Closed`,
            archived_label: `Archived`,
            buyer_label: `Buyer`,
            seller_label: `Seller`,
            CardSkeleton: {},
            TransactionCard: {
                button_label: `Details`
            },

            TransactionList: {
                none_found_label: `No transactions found!`
            },

            TransactionRow: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                button_label: `Details`
            },

            TransactionsSearchFilter: {
                input_label: `Search transactions`,
                all_label: `All Active`,
                buyers_label: `Buyers`,
                sellers_label: `Sellers`,
                closed_label: `Closed`,
                archived_label: 'Archived',
                name_label: `Name`,
                address_label: `Address`
            }
        }
    },

    es: {
        lang_name: `Spanish`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {
                new_connections_label: `Nuevos conexiones`,
                create_button_label: `Crear conexiones`,
                cancel_button_label: `Cancelar`
            },
            AddDocToTrxModal: {
                add_label: `Agregar a la transacción`,
                search_placeholder: `Transacciones de búsqueda`,
                select_all_label: `Seleccionar todo`,
                mls_label: `MLS#`,
                add_button_label: `Agregar documento`,
                cancel_button_label: `Cancelar`,
                already_exists_message: `Este documento ya existe en todas sus transacciones activas.`,
                no_active_message: `No tiene transacciones activas. Los documentos solo se pueden agregar a transacciones activas.`,
                transaction_button_label: `Nueva Transacción`,
                close_button_label: `Cerrar`
            },
            AddDocumentModal: {
                drag_click_label: 'Arrastre los archivos aquí o haga clic para cargar',
                upload_label: `Subir documento`,
                name_label: `Nombre: `,
                size_label: `Tamaño: `,
                add_title_label: `Agregar título `,
                optional_label: `(Opcional)`,
                title_placeholder: `Título`,
                doc_type_label: `Tipo de documento`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                add_button_label: `Agregar documentot`,
                cancel_button_label: `Cancelar`
            },
            AddTaskModal: {
                add_task_label: `Agregar una tarea`,
                add_task_tip: `La creación de una nueva tarea permite que la tarea se agregue opcionalmente a cualquier nueva transacción durante la creación inicial.`,
                trx_type_label: `Tipo De Transacción`,
                buying_label: `Comprar`,
                listing_label: `Listado`,
                task_title_label: `Título De La Tarea`,
                task_title_placeholder: `Título`,
                task_desc_label: `Descripción De La Tarea`,
                task_desc_placeholder: `Descripción`,
                default_expire_label: `Tiempo De Epiración Predeterminado`,
                days_label: `Dias`,
                hour_label: `Horas`,
                min_label: `Minutos`,
                ordinal_label: `AM/PM`,
                create_button_label: `Crear Tarea`,
                cancel_button_label: `Cancelar`
            },
            AppHeader: {},
            AvatarEditModal: {
                change_avatar_label: `Cambia tu avatar`,
                update_button_label: `Actualizar avatar`,
                cancel_button_label: `Cancelar`
            },
            ClickWrapper: {},
            CreatableSelectField: {
                add_custom_label: `Agregar fuente personalizada`
            },
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {
                title_placeholder: `Título`,
                description_placeholder: `Descripción`,
                date_time_placeholder: `Fecha y hora`,
                open_button_label: `Tarea Abierta`,
                complete_button_label: `Tarea Completada`,
                delete_button_label: `Eliminar Tarea`,
                update_button_label: `Tarea De Actualización`
            },
            Loader: {},
            MainHeader: {
                transactions_link: `Transacciones`,
                connections_link: `Conexiones`,
                forms_link: `Formularios`,
                documents_link: `Mis Documentos`,
                profile_link: `Perfil`,
                preferences_link: `Preferencias`,
                messages_link: `Mensaje`,
                transaction_button_label: `Nueva Transacción`,
                connection_button_label: 'Nueva Conexión',
                library_button_label: 'Nueva Formo',
                document_button_label: 'Nueva Documento'
            },
            RemoveTaskModal: {
                remove_task_label: `Eliminar una Tarea`,
                assurance_label: `¿Estás seguro de que quieres eliminar esta tarea?`,
                delete_button_label: `Eliminar Tarea`,
                cancel_button_label: `Cancelar`
            },
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {
                home_link: `Inicio`,
                settings_link: `Ajustes`,
                messages_link: `Mensaje`,
                contacts_link: `Contactos de la empresa`,
                logout_link: `Cerrar sesión`
            },
            StateCountrySelect: {
                select_label: `Selección`
            },
            TextField: {},
            TransactionUploadModal: {
                image_label: `Imagen`,
                file_label: `Archivo`,
                attach_file_label: `adjunto`,
                add_title_label: `Añadir Título (Opcional)`,
                add_title_placeholder: `Título`,
                name_label: `Nombre: `,
                size_label: `Tamaño: `,
                attach_button_label: `Adjuntar`,
                attach_send_button_label: `Adjuntar y `,
                cancel_button_label: `Cancelar`
            },
            ConfirmationDialog: {
                /**
                 * Instruction:
                 * To add a new type of Confirmation Dialog you should add translations for all states in which
                 * dialog can be for this type or it will use common translation. To create specific one follow
                 * next naming rule: `{confirmationDialogType}_[title/text/confirmButtonText/cancelButtonText]`.
                 */
                Confirmation: {
                    // Common
                    title: `Estas seguro?`,

                    // Specific
                    cancel_text: `Todos los cambios se perderán.`,
                    delete_text: `Esta acción no se puede deshacer!`,
                    activateTrx_text: `Todas las partes ahora tendrán acceso a esta transacción.`,
                    reopenTrx_text: `Esta transacción ha sido cerrada, por favor confirme que desea volver a abrirla.`,
                    closeTrx_text: `Por favor, introduzca una fecha de cierre`,
                    archiveTrx_text: `Esta acción deshabilitará todas las ediciones y eliminará el acceso a todas las partes. Puede restablecer a Activo si es necesario en el futuro.`,

                    // Buttons
                    // Common
                    confirmButtonText: `Confirmar!`,
                    cancelButtonText: `Cancelar`,
                    // Specific
                    delete_confirmButtonText: `Sí, bórralo!`,
                    cancel_confirmButtonText: `Sí, cancelar!`,
                    cancel_cancelButtonText: `No quedarse!`,

                    // Additional items
                    closeTrx_inputPlaceholder: `Seleccione una fecha`,
                    closeTrx_validationError: `Elija una fecha de cierre.`
                },
                Loading: {
                    // Common
                    title: `Procesando...`,
                    text: `Espere por favor.`
                },
                Success: {
                    // Common
                    title: `Éxito!`,
                    text: `Tus cambios han sido actualizados.`,
                    confirmButtonText: `OK`,

                    // Specific
                    delete_title: `Eliminado!`,
                    delete_text: `El elemento seleccionado ha sido eliminado.`,
                    uploadDocument_title: `Subido!`,
                    uploadDocument_text: `Su documento ha sido subido.`,
                    uploadConnection_text: `Tus conexiones han sido subidos`
                },
                Failed: {
                    // Common
                    title: `Uuups...`,
                    text: `Algo salió mal!`,
                    confirmButtonText: `OK`
                }
            },
            Dropdown: {
                delete_label: 'Eliminar',
                edit_label: 'Editar'
            },
            ActionButtons: {
                cancel_label: 'Cancelar',
                save_label: 'Guardar cambios'
            }
        },
        CompanyContacts: {
            ContactDetailsHistory: {},
            ContactList: { no_contacts_label: `¡No tienes contactos!` },
            ContactRow: { button_label: `Detalles` },
            ContactRowSkeleton: {},
            ContactsSearchFilter: {
                all_label: `Todos Contactos`,
                active_label: `Contactos Activos`,
                inactive_label: `Contactos Inactivos`,
                input_label: `Buscar contactos`
            }
        },
        ContactDetails: { button_label: `Enviar mensaje` },
        Constants: {
            errorMessage: {
                emailPasswordCombo: `La combinación de correo electrónico / contraseña es incorrecta. Puede optar por restablecer su contraseña haciendo clic en el enlace a continuación.`,
                emailNoUser: `Esta dirección de correo electrónico no pertenece a una cuenta de Jada. Cree una cuenta haciendo clic en el enlace a continuación.`,
                emailPrimaryLinkMatch: `La dirección de correo electrónico no puede ser la misma que la conexión Principal.`,
                password: {
                    lowerCase: `Debe contener al menos una letra minúscula`,
                    upperCase: `Debe contener al menos una letra mayúscula`,
                    numeric: `Debe contener al menos un número`,
                    length: `Debe tener al menos 8 caracteres de longitud`,
                    required: `Porfavor ingrese una contraseña`
                },
                email: {
                    valid: `Por favor, introduce una dirección de correo electrónico válida`,
                    required: `Se requiere Dirección de correo electrónico`
                },
                phone: {
                    valid: `Por favor ingrese un número de teléfono válido`,
                    required: `Se requiere el número de teléfono`
                },
                phoneExt: {
                    valid: `Ingrese una extensión válida`
                },
                address: {
                    required: `La dirección es necesaria`,
                    valid: `Por favor introduce una dirección válida`
                },
                firstName: {
                    valid: `Por favor, ingrese un nombre válido`,
                    required: `Se requiere el primer nombre`
                },
                middleInitial: {
                    valid: `Ingrese una inicial del segundo nombre válida`
                },
                lastName: {
                    valid: `Por favor ingrese un apellido válido`,
                    required: `Se requiere apellido`
                },
                city: {
                    valid: `
                    Por favor introduzca una ciudad válida`,
                    required: `Ciudad es requerida`
                },
                state: {
                    required: `Estado requerido`
                },
                zip: {
                    required: `Se requiere el código postal`
                },
                country: {
                    required: `El país es obligatorio`
                },
                fromDate: {
                    required: `Se requiere la fecha de inicio`
                },
                toDate: {
                    required: `Se requiere la fecha de finalización`
                },
                listingPrice: {
                    required: `Se requiere el precio de lista`
                },
                commission: {
                    required: `Se requiere comisión`,
                    valid: `La comisión debe ser un número`
                },
                split: {
                    required: `Se requiere la división del agente`,
                    valid: `La división del agente debe ser un número`
                },
                fee: {
                    required: `Se requiere tarifa de transacción`,
                    valid: `La tarifa de transacción debe ser un número`
                },
                businessSrc: {
                    required: `Se requiere la fuente`
                },
                emailOrPhone: {
                    required: `Se requiere correo electrónico o teléfono`
                },
                firstOrLastName: {
                    required: `Se requiere nombre o apellido`
                },
                licNumber: {
                    required: `Se requiere número de licencia`
                },
                mlsId: {
                    required: `Se requiere identificación MLS`
                }
            }
        },
        Connections: {
            AddNewConnection: {},
            ConnectionList: { no_connections_label: `¡No tienes conexiones!` },
            ConnectionMatches: {
                match_label: `Coincidencia Potencial`,
                incorrect_match_single: `Esta no es la coincidencia correcta.`,
                incorrect_match_multiple: `Estas no son las coincidencias correctas.`,
                select_match: `Seleccione`
            },
            ConnectionRow: { button_label: `Detalles` },
            ConnectionRowSkeleton: {},
            ConnectionProgress: {},
            ConnectionsSearchFilter: {
                input_label: `Buscar conexiones`
            },
            ExistingConnectionCheck: {
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`
            },
            NewConnectionForm: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre Legal`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                mailing_address_same_label: `¿La misma dirección postal que la conexión principal?`,
                connection_notes_label: `Notas De Conexión`,
                connection_notes_placeholder: `Notas de conexión`,
                remove_connection_label: `Remover enlace`,
                add_another_connection_label: `Agregar otro conexión`,
                link_button: `Conexión de enlace`,
                primary_button: `Conexión principal`,
                add_party_text: `Agregar una parte vinculada`,
                add_party_subtext: `cónyuge, pareja, padre, etc..`,
                save_button: `Guardar`
            }
        },
        ConnectionDetails: {
            notes_heading: 'Notas',
            history_heading: 'Historia',
            status: {
                buyer: 'Comprador',
                seller: 'Vendedor'
            },
            button_label: 'Nueva transacción'
        },
        Dashboard: {},
        DateTime: { locale: Spanish },
        Documents: {
            DocumentCard: {
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`
            },
            DocumentsGrid: {},
            DocumentRow: {
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                button_label: `Ver Documento`,
                file_name_label: `Nombre del fichero`,
                upload_date_label: `Fecha de carga`
            },
            DocumentsSearchFilter: {
                input_label: `Buscar documentos`,
                all_label: `Todos Documentos`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                dropdown_label: `Título del documento`
            }
        },
        Landing: {
            title_1: `Bienvenido. Este podría ser el comienzo de algo `,
            title_2: `grande!`,
            button_label: `Empecemos`
        },
        Library: {},
        Login: {
            title_1: `Bienvenido `,
            title_2: `de nuevo`,
            sub_title: `Entre para acceder a su cuenta.`,
            email_label: `CORREO ELECTRÓNICO`,
            email_placeholder: `Correo electrónico`,
            password_label: `CONTRASEÑA`,
            forgot_password: `¿Olvidó su contraseña?`,
            no_account: `¿No tienes una cuenta? `,
            no_account_link: `Aprenda más`,
            button_label: `Vamos`
        },
        NewConnection: {
            title: `¡Sí! Hacer conexiones.`,
            sub_text: `Comencemos con lo básico para verificar si su nuevo contacto ya tiene una cuenta. De lo contrario, podrá invitarlos o ingresar su información manualmente.`
        },
        NewDocument: {
            back_button_label: 'Regresa'
        },
        NewTransaction: {
            BuyerFlowStep1: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                middle_initial_label: `MI`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre legal completo`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `¿La dirección postal es la misma que la anterior?`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                add_party_label: `Agregar otra parte a la transacción`,
                remove_party_label: `Eliminar a la parte de la transacción`,
                next_button_label: `Próximo`
            },
            BuyerFlowStep2: {
                closing_date_label: `Fecha De Cierre`,
                closing_date_placeholder: `Fecha de cierre`,
                purchase_price_label: `Precio De Compra`,
                purchase_price_placeholder: `Precio`,
                business_src_label: `Fuente De Negocio`,
                business_src_placeholder: `Selección`,
                upload_img_label: `Subir imagenes`,
                drag_drop_label: `Explore su dispositivo y cargue imágenes`,
                max_size_label: `El tamaño máximo del archivo es 2 MB`,
                img_optional_label: `Esto es opcional. Tomaremos más imágenes de MLS más tarde.`,
                back_button_label: `Atrás`,
                next_button_label: `Próximo`
            },
            BuyerFlowStep3: {
                commission_label: `Comisión De Compra`,
                commission_placeholder: `0`,
                split_label: `División De Agentes`,
                split_placeholder: `0`,
                fee_label: `Tarifa De Transacción`,
                fee_placeholder: `0`,
                forms_label: `Formularios iniciales`,
                tasks_label: `Tareas iniciales`,
                back_button_label: `Atrás`,
                done_button_label: `Hecho`
            },
            BuyerSelectForm: {
                select_label: `Selección`,
                property_info_label: `Información de la propiedad`,
                mls_option_label: `Ingrese el número MLS.`,
                address_option_label: `¿No estás en la MLS? Ingrese la dirección.`,
                property_address_label: `Dirección de Propiedad`,
                address_placeholder: `Empezar a escribir dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                mls_number_label: `Número MLS`,
                mls_number_placeholder: `Engrese un MLS ID`,
                property_found_label: `Propiedad encontrada!`,
                property_not_found_label: `Propiedad no encontrada.`,
                button_label: `Vamos`,
                mls_button_label: `Encontrar`
            },
            BuyerTransactionFlow: {},
            FormHeader: {
                step_1_label: `Partes`,
                step_2_label: `Detalles`,
                step_3_label: `Configurar`
            },
            ListingFlowStep1: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                middle_initial_label: `MI`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre legal completo`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `¿La dirección postal es la misma que la dirección de la lista?`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                add_party_label: `Agregar otra parte a la transacción`,
                remove_party_label: `Eliminar a la parte de la transacción`,
                next_button_label: `Próximo`
            },
            ListingFlowStep2: {
                listing_start_label: `Fecha De Inicio De La Lista`,
                listing_start_placeholder: `De`,
                listing_expiration_label: `Fecha De Caducidad`,
                listing_expiration_placeholder: `Para`,
                listing_price_label: `Precio De Lista`,
                listing_price_placeholder: `Precio`,
                business_src_label: `Fuente De Negocio`,
                business_src_placeholder: `Selección`,
                upload_img_label: `Subir imagenes`,
                drag_drop_label: `Explore su dispositivo y cargue imágenes`,
                max_size_label: `El tamaño máximo del archivo es 2 MB`,
                img_optional_label: `Esto es opcional. Tomaremos más imágenes de MLS más tarde.`,
                back_button_label: `Atrás`,
                next_button_label: `Próximo`
            },
            ListingFlowStep3: {
                commission_label: `Comisión De Venta`,
                commission_placeholder: `0`,
                split_label: `División De Agentes`,
                split_placeholder: `0`,
                fee_label: `Tarifa De Transacción`,
                fee_placeholder: `0`,
                forms_label: `Formularios iniciales`,
                tasks_label: `Tareas iniciales`,
                back_button_label: `Atrás`,
                done_button_label: `Hecho`
            },
            ListingSelectForm: {
                select_label: `Selección`,
                property_info_label: `Información de la propiedad`,
                manual_option_label: `¿Dirección no encontrada? Introdúzcalo manualmente.`,
                mls_option_label: `¿Tienes un número MLS?`,
                address_option_label: `¿Tienes una dirección?`,
                address_label: `Búsqueda de direcciones`,
                property_address_label: `Dirección de Propiedad`,
                address_placeholder: `Empezar a escribir dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                description_label: `Descripción de Lote/Tierra`,
                description_placeholder: `Descripción`,
                mls_number_label: `Número MLS`,
                mls_number_placeholder: `Engrese un MLS ID`,
                property_found_label: `Propiedad encontrada!`,
                property_not_found_label: `Propiedad no encontrada.`,
                or_label: `O`,
                cancel_link: `Cancelar`,
                button_label: `Vamos`,
                mls_button_label: `Encontrar`
            },
            ListingTransactionFlow: {},
            NewTransactionHeader: { back_button_label: `Regresa` },
            TransactionTypeSelect: {
                title_label: `¡Woohoo! Hagámoslo.`,
                listing_button_label: `Listado`,
                buyer_button_label: `Comprador`
            }
        },
        PdfViewer: {
            ControllerHeader: {
                fillable_button_label: `Hacer Rellenable`,
                edit_button_label: `Editar campos`,
                add_trx_button_label: `Agregar a la Transacción`
            },
            ViewController: {
                of_label: `de`
            }
        },
        Preferences: {},
        Profile: {},
        Settings: {
            save_label: 'Guardar cambios',
            cancel_label: 'Cancelar',
            SettingsMenu: {
                app_integratons_link: `App Integrations`,
                billing_link: `Billing`,
                organization_info_link: `Organization Info`,
                personal_info_link: `Personal Info`,
                preferences_link: `Preferences`,
                security_settings_link: `Security Settings`,
                team_link: `Team`,
                edit_label: `editar`,
                file_size_warning: `El archivo es demasiado grande.`
            },
            OrganizationInfo: {
                heading: 'Información de la organización',
                name_label: 'Nombre de la oficina',
                status_label: 'Estado',
                type_label: 'Tipo de oficina',
                addOffice_label: 'Agregar oficina',
                addOrg_label: 'Agregar organización',
                addPhone_label: 'Añadir teléfono',
                officeStatuses: {
                    active_label: 'Activo',
                    inactive_label: 'Inactivo'
                },
                officeTypes: {
                    main_label: 'Principal',
                    branch_label: 'Rama',
                    satellite_label: 'Satélite'
                },
                office: {
                    labels: {
                        officeLogo: 'Logotipo de la oficina',
                        officeLogo_tooltip:
                            'Para mejores resultados, el archivo del logotipo debe ser ya sea un .png o .svg con un minimo altura de 98px.',
                        officeName: 'Nombre de la oficina',
                        officeAddress: 'Dirección de la oficina',
                        officeMLS: 'ID de MLS de la oficina',
                        officePhone: 'Teléfono',
                        uploadPhoto: 'Subir foto',
                        remove: 'Eliminar'
                    },
                    placeholders: {
                        enterNumber: 'Ingresar número',
                        enterId: 'Ingrese identificación',
                        enterName: 'Ingrese el nombre de la oficina',
                        enterPhone: 'Introduzca el número de teléfono',
                        enterAddress: 'Ingresa la direccion'
                    },
                    phoneTypes: {
                        office: 'oficina',
                        mobile: 'móvil',
                        fax: 'fax'
                    }
                }
            },
            PersonalInfo: {
                main_title: 'Información personal',
                lic_number: 'Número de licencia',
                mls_id: 'MLS id',
                tooltip_mls_id:
                    'Su ID de MLS solo se utilizará para mantener sus listados de MLS sincronizados con sus transacciones en Jada',
                name_label: 'Nombre',
                email_label: 'Correo electrónico',
                phone_label: 'Teléfono',
                about_label: 'Sobre',
                add_phone_label: 'Añadir teléfono',
                placeholder_licence: 'Introduzca su número de licencia',
                placeholder_mls_id: 'Introduzca su identificación',
                placeholder_first_name: 'Introduzca su nombre de pila',
                placeholder_last_name: 'Introduzca su apellido',
                placeholder_email: 'Introduzca su correo electrónico',
                placeholder_phone: 'Introduzca su número',
                placeholder_editor: 'Introduzca el texto',
                dropdown_title: 'Móviles',
                dropdown_item_office: 'Oficina',
                dropdown_item_mobile: 'Móviles',
                dropdown_item_fax: 'Fax',
                modal_title: 'Confirme su contraseña',
                modal_password_placeholder: 'Introduzca su contraseña',
                modal_button_cancel: 'Cancelar',
                modal_button_save: 'Guardar cambios'
            },
            SecuritySettings: {
                main_heading: 'Configuraciones de seguridad',
                info_text: 'Actualice su contraseña para acceder a la cuenta.',
                newPass_label: 'Nueva contraseña',
                repeat_label: 'Reescriba nueva contraseña',
                save_label: 'Guardar cambios',
                cancel_label: 'Cancelar'
            },
            AppIntegrations: {},
            Billing: {},
            Preferences: {},
            Team: {
                main_title: 'Equipo',
                header: {
                    add_member_label: 'Añadir miembro'
                },
                teamList: {
                    name_label: 'Nombre de la oficina',
                    status_label: 'Estado',
                    type_label: 'Tipo de cuenta',
                    statuses: {
                        active_label: 'Activo',
                        inactive_label: 'Inactivo'
                    }
                }
            }
        },
        TransactionDetails: {
            Activity: {},
            ActivityInput: {
                added_task_label: `Añadida una nueva tarea: `,
                upload_a_img_label: `Subió una imagen: `,
                upload_a_file_label: `Subió un archivo: `,
                image_label: `Imagen`,
                file_label: `Archivo`,
                whats_up_placeholder: `Qué esta pasando...`,
                task_title_placeholder: `Título De La Tarea`,
                task_note_placeholder: `Nota De Tarea`,
                date_time_placeholder: `Fecha y hora`,
                attachment_button_label: `Adjunto`,
                task_button_label: `Tarea`,
                post_button_label: `Publicar`,
                add_task_label: `Agregar tarea`,
                uploaded_label: `subido:`
            },
            ActivityThread: {
                no_messages_label: `Aún no hay mensajes.`
            },
            AddSearch: {
                all_label: `Todos`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                add_label: `Agregar`,
                search_placeholder: `Buscar`
            },
            DetailsHeader: {
                TransactionStatus: {
                    active: 'Activo',
                    pending: 'Pendiente',
                    closed: 'Cerrado',
                    archived: 'Archivado'
                },
                edit_transaction: 'Editar transacción'
            },
            DocumentDrop: {
                drag_drop_label: `Arrastra los archivos aquí para subirlos`,
                add_label: `Agregar`,
                last_update_label: `Última actualización`
            },
            DocumentList: {
                view_label: `Ver`,
                download_label: `Descargar`,
                archive_label: `Archivo`,
                fill_label: `Rellenar Campos`,
                not_started_label: `No empezado`,
                completed_label: `Terminado`
            },
            Documents: {},
            DocumentView: {
                of_label: `de`
            },
            NoteDrop: {
                note_title_placeholder: `Título de la nota`,
                note_content_placeholder: `Contenido de la nota`,
                private_label: `Privado`,
                add_note_button_label: `Añadir Nota`
            },
            NoteList: {
                assurance_label: `¿Estás seguro de que quieres eliminar esta nota?`,
                remove_label: `Eliminar`,
                cancel_label: `Cancelar`
            },
            Notes: {},
            TabNavigation: {
                activity_label: `Actividad`,
                documents_label: `Documentos`,
                notes_label: `Notas`
            },
            Tasks: {
                complete_status_message: `Estado de la tarea actualizado a Completado: `,
                open_status_message: `Se actualizó el estado de una tarea a Abierto: `,
                updated_status_message: `Actualización de una tarea: `,
                deleted_status_message: `Eliminado una tarea: `,
                all_tasks_button_label: `Todas Tareas`,
                less_tasks_button_label: `Menos Tareas`,
                hide_history_button_label: `Ocultar Historial Tareas`,
                show_history_button_label: `Mostrar Historial Tareas`,
                add_task_button_label: `Agregar una Tarea`,
                no_tasks_label: `Sin tareas programadas`
            },
            TransactionInterface: {},
            TransactionMeta: {
                seller_agent_label: `Agente del Vendedor`,
                buyer_agent_label: `Agente del Comprador`,
                days_market_label: `Días en el mercado`,
                closing_in_label: `Cerrando en`,
                days_label: `días`,
                no_close_date_label: `Sin fecha de cierre`,
                team_label: `Miembros del equipo:`,
                seller_label: `Vendedor`,
                buyer_label: `Comprador`,
                primary_agent_label: `Agente del comprador`,
                primary_broker_label: `Corredor del comprador`
            },
            EditTransactionDetails: {
                price_label: `Editar precio`,
                price_placeholder: `Precio`,
                closingDate_label: `Editar fecha de cierre`,
                closingDate_placeholder: `Fecha de cierre`
            }
        },
        Transactions: {
            active_label: `Activo`,
            pending_label: `Pendiente`,
            closed_label: `Cerrado`,
            archived_label: `Archivado`,
            buyer_label: `Compradore`,
            seller_label: `Vendedore`,
            CardSkeleton: {},
            TransactionCard: {
                button_label: `Detalles`
            },

            TransactionList: {
                none_found_label: `¡No se encontraron transacciones!`
            },

            TransactionRow: {
                buyer_label: `Compradore`,
                seller_label: `Vendedore`,
                button_label: `Detalles`
            },

            TransactionsSearchFilter: {
                input_label: `Buscar transacciones`,
                all_label: `Todo Activo`,
                buyers_label: `Compradores`,
                sellers_label: `Vendedores`,
                closed_label: `Cerrado`,
                archived_label: 'Archivado',
                name_label: `Nombre`,
                address_label: `Dirección`
            }
        }
    }
};

export default library;

/**
  de: {
        lang_name:`German`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    fr: {
        lang_name:`French`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    it: {
        lang_name:`Italian`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    nl: {
        lang_name:`Dutch`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    uk: {
        lang_name:`Ukrainian`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    }
 
 */
