import {
    SET_USER_COLLECTION,
    SET_USER_DOCUMENTS,
    SET_ORG_DOCUMENTS,
    UPLOAD_STORAGE_DOCUMENT,
    UPLOAD_STORAGE_DOCUMENT_SUCCESS,
    UPLOAD_STORAGE_DOCUMENT_FAILURE,
    SET_DOCUMENT_LIST,
    SET_DOCUMENT_FILTER,
    SET_DOC_SEARCH_SUGGESTIONS,
    SET_LIBRARY_FILTER,
    SET_LIBRARY_LIST,
    UPDATE_DOCUMENT,
    ADD_DOCUMENT_TO_TRANSACTION,
    RESET_PROPERTY_DATA,
    SET_TRANSACTION_DOCUMENTS_FILTER
} from '../actions/types';

const INITIAL_STATE = {
    documentList: 'grid',
    libraryList: 'grid',
    userDocuments: [],
    orgDocuments: [],
    filteredUserDocuments: [],
    filteredOrgDocuments: [],
    filteredTransactionDocuments: [],
    loading: false,
    documentFilterType: {
        name: 'All Documents',
        value: 'all'
    },
    libraryFilterType: {
        name: 'All Documents',
        value: 'all'
    },
    trxDetailsFilterType: {
        name: 'All',
        value: 'all'
    },
    searchSuggestions: [],
    uploading: false,
    uploadError: false
};

const docFilter = (documents, filter) => {
    const initDocuments = [...documents];
    if (filter.value !== 'all') {
        const filtered = initDocuments.filter(doc => doc.upload_type === filter.value);
        return filtered;
    } else {
        return documents;
    }
};

const filterTypes = {
    en: {
        all: {
            name: 'All Documents',
            value: 'all'
        },
        buyer: {
            name: 'Buyer',
            value: 'buyer'
        },
        seller: {
            name: 'Seller',
            value: 'seller'
        },
        other: {
            name: 'Other',
            value: 'other'
        }
    },
    es: {
        all: {
            name: 'Todos Documentos',
            value: 'all'
        },
        buyer: {
            name: 'Comprador',
            value: 'buyer'
        },
        seller: {
            name: 'Vendedor',
            value: 'seller'
        },
        other: {
            name: 'Otro',
            value: 'other'
        }
    }
};

const trxFilterTypes = {
    en: {
        all: {
            name: 'All',
            value: 'all'
        },
        buyer: {
            name: 'Buyer',
            value: 'buyer'
        },
        seller: {
            name: 'Seller',
            value: 'seller'
        },
        other: {
            name: 'Other',
            value: 'other'
        }
    },
    es: {
        all: {
            name: 'Todos',
            value: 'all'
        },
        buyer: {
            name: 'Comprador',
            value: 'buyer'
        },
        seller: {
            name: 'Vendedor',
            value: 'seller'
        },
        other: {
            name: 'Otro',
            value: 'other'
        }
    }
};

const documentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                documentFilterType:
                    filterTypes[action.payload.language][state.documentFilterType.value],
                libraryFilterType:
                    filterTypes[action.payload.language][state.libraryFilterType.value],
                trxDetailsFilterType:
                    trxFilterTypes[action.payload.language][
                        state.trxDetailsFilterType.value
                    ]
            };
        case UPLOAD_STORAGE_DOCUMENT:
            return {
                ...state,
                uploading: true,
                loading: true,
                uploadError: false
            };
        case SET_USER_DOCUMENTS:
            return {
                ...state,
                userDocuments: action.payload,
                filteredUserDocuments: action.payload.length
                    ? docFilter([...action.payload], state.documentFilterType)
                    : [],
                filteredTransactionDocuments:
                    action.payload.length || state.orgDocuments.length
                        ? docFilter(
                              [].concat(action.payload, state.orgDocuments),
                              state.trxDetailsFilterType
                          )
                        : [],
                loading: false
            };
        case SET_ORG_DOCUMENTS:
            return {
                ...state,
                orgDocuments: action.payload,
                filteredOrgDocuments: action.payload.length
                    ? docFilter([...action.payload], state.documentFilterType)
                    : [],
                filteredTransactionDocuments:
                    state.userDocuments.length || action.payload.length
                        ? docFilter(
                              [].concat(state.userDocuments, action.payload),
                              state.trxDetailsFilterType
                          )
                        : [],
                loading: false
            };
        case UPLOAD_STORAGE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                uploading: false,
                uploadError: false
            };
        case UPLOAD_STORAGE_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                uplaoding: false,
                uploadError: true
            };
        case SET_DOCUMENT_FILTER:
            return {
                ...state,
                filteredUserDocuments: action.payload.filtered,
                documentFilterType: action.payload.filter
            };
        case SET_DOCUMENT_LIST:
            return {
                ...state,
                documentList: action.payload
            };
        case SET_LIBRARY_FILTER:
            return {
                ...state,
                filteredOrgDocuments: action.payload.filtered,
                libraryFilterType: action.payload.filter
            };
        case SET_LIBRARY_LIST:
            return {
                ...state,
                libraryList: action.payload
            };
        case SET_DOC_SEARCH_SUGGESTIONS:
            return {
                ...state,
                searchSuggestions: action.payload
            };
        case UPDATE_DOCUMENT:
            return {
                ...state,
                loading: true
            };
        case ADD_DOCUMENT_TO_TRANSACTION:
            return {
                ...state,
                loading: true
            };
        case SET_TRANSACTION_DOCUMENTS_FILTER:
            return {
                ...state,
                trxDetailsFilterType: action.payload,
                filteredTransactionDocuments:
                    state.userDocuments.length || state.orgDocuments.length
                        ? docFilter(
                              [].concat(state.userDocuments, state.orgDocuments),
                              action.payload
                          )
                        : []
            };
        case RESET_PROPERTY_DATA:
            return {
                ...state,
                trxDetailsFilterType: INITIAL_STATE.trxDetailsFilterType,
                filteredTransactionDocuments: INITIAL_STATE.filteredTransactionDocuments
            };
        default:
            return state;
    }
};

export default documentsReducer;
