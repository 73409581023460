import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    GET_USER_TRANSACTIONS,
    CREATE_TRANSACTION,
    LOGOUT_USER,
    UPDATE_TRANSACTION_NOTE,
    UPDATE_TRANSACTION_TASK,
    UPDATE_ACTIVITY_POST,
    GET_TRANSACTION_DETAILS,
    SET_CLIENT_TRANSACTION_DETAILS,
    CANCEL_DETAILS_LISTENER,
    UPDATE_TRANSACTION_STATUS,
    UPDATE_TRANSACTION_DETAILS
} from '../actions/types';

import { eventChannel } from 'redux-saga';
import { confirmSaga } from './Modal';
import { setConfirmModalType } from '../actions/Modal';

import {
    storeUserTransactions,
    storeClosedTransactions,
    storeArchivedTransactions,
    transactionWriteSuccess,
    noteWriteSuccess,
    taskWriteSuccess,
    taskWriteFailure,
    postWriteSuccess,
    settingTransactionDetails
} from '../actions/Transactions';

import { db, rtdb, timeStampNow, timeStampJs, storage } from '../../config/Firebase';

// import { refreshingTransactionDocuments } from './Documents';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

// Constants
import { confirmationDialogTypes } from '../../utils/Constants';
import { trxStatus } from '../../utils/Constants';

const transactions = db.collection('transactions');
const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Transactions ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* clientTransactionCollectionWatch(user) {
    let unsubcscribeClientTransactionData;
    const clientTransactionsCollectionChannel = eventChannel(emit => {
        unsubcscribeClientTransactionData = transactions
            .where('party_ids', 'array-contains', user.id)
            .where('status', '!=', trxStatus.archived)
            .onSnapshot(querySnapshot => {
                var clientTransactions = [];
                querySnapshot.forEach(function (doc) {
                    if (doc.data()) {
                        clientTransactions.push(doc.data());
                    }
                });
                emit(clientTransactions);
            });
        return unsubcscribeClientTransactionData;
    });
    try {
        while (true) {
            const { userSignOut, clientTransactions } = yield race({
                userSignOut: take(LOGOUT_USER),
                clientTransactions: take(clientTransactionsCollectionChannel)
            });
            if (userSignOut) {
                clientTransactionsCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(
                    storeClosedTransactions(
                        clientTransactions.filter(trx => trx.status === trxStatus.closed)
                    )
                );
                yield put(
                    storeUserTransactions(
                        clientTransactions.filter(trx => trx.status !== trxStatus.closed)
                    )
                );
            }
        }
    } catch (error) {
        log('User Error: getting client collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeClientTransactionData(); // Detach firebase listener
        if (yield cancelled()) {
            clientTransactionsCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeClientTransactionData(); // Detach firebase listener
        }
    }
}

export function* transactionCollectionWatch(user) {
    const activeRef = rtdb.ref(`transactions/${user.active_org_id}/active`);
    const closedRef = rtdb.ref(`transactions/${user.active_org_id}/closed`);
    const archivedRef = rtdb.ref(`transactions/${user.active_org_id}/archived`);

    const AddActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const ChangeActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id] === false) {
                        // Existing user on Transaction has been administratively removed
                        // resulting in their id key in transaction data to be set to false.
                        // We must locate the specific and previously watched/stored transaction
                        // from their current active list and remove it
                        // This will be TO-DO once we have that ability
                    }
                    if (childSnapshot.val()[user.id]) {
                        // console.log('changed child', childSnapshot.val());
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const AddClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const ChangeClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const AddArchivedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = archivedRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveArchivedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = archivedRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const detachSagaEmitters = () => {
        AddActiveTransactionChannel.close();
        ChangeActiveTransactionChannel.close();
        RemoveActiveTransactionChannel.close();
        AddClosedTransactionChannel.close();
        ChangeClosedTransactionChannel.close();
        RemoveClosedTransactionChannel.close();
        AddArchivedTransactionChannel.close();
        RemoveArchivedTransactionChannel.close();
    };

    const detachFBListeners = () => {
        activeRef.off('child_added');
        activeRef.off('child_changed');
        activeRef.off('child_removed');
        closedRef.off('child_added');
        closedRef.off('child_changed');
        closedRef.off('child_removed');
        archivedRef.off('child_added');
        archivedRef.off('child_removed');
    };

    try {
        while (true) {
            const currentActive = yield select(selectors._activeTransactions);
            const currentClosed = yield select(selectors._closedTransactions);
            const currentArchived = yield select(selectors._archivedTransactions);
            const transactions = currentActive ? [...currentActive] : [];
            const closedTransactions = currentClosed ? [...currentClosed] : [];
            const archivedTransactions = currentArchived ? [...currentArchived] : [];

            const getTrxIndex = (trx, type) => {
                const data = trx;
                let index;
                if (type === 'active') {
                    index = transactions.findIndex(trx => trx.id === data.id);
                } else if (type === 'closed') {
                    index = closedTransactions.findIndex(trx => trx.id === data.id);
                } else {
                    index = archivedTransactions.findIndex(trx => trx.id === data.id);
                }
                return index;
            };

            let index;
            const {
                userSignOut,
                addActiveTransactionData,
                changeActiveTransactionData,
                removeActiveTransactionData,
                addClosedTransactionData,
                changeClosedTransactionData,
                removeClosedTransactionData,
                addArchivedTransactionData,
                removeArchivedTransactionData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                addActiveTransactionData: take(AddActiveTransactionChannel),
                changeActiveTransactionData: take(ChangeActiveTransactionChannel),
                removeActiveTransactionData: take(RemoveActiveTransactionChannel),
                addClosedTransactionData: take(AddClosedTransactionChannel),
                changeClosedTransactionData: take(ChangeClosedTransactionChannel),
                removeClosedTransactionData: take(RemoveClosedTransactionChannel),
                addArchivedTransactionData: take(AddArchivedTransactionChannel),
                removeArchivedTransactionData: take(RemoveArchivedTransactionChannel)
            });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else if (addActiveTransactionData) {
                index = getTrxIndex(addActiveTransactionData, 'active');
                if (index < 0) transactions.push(addActiveTransactionData);
                yield put(storeUserTransactions(transactions));
            } else if (changeActiveTransactionData) {
                index = getTrxIndex(changeActiveTransactionData, 'active');
                if (index < 0) {
                    transactions.push(changeActiveTransactionData);
                } else {
                    Object.assign(transactions[index], changeActiveTransactionData);
                }
                yield put(storeUserTransactions(transactions));
            } else if (removeActiveTransactionData) {
                index = getTrxIndex(removeActiveTransactionData, 'active');
                if (index >= 0) transactions.splice(index, 1);
                yield put(storeUserTransactions(transactions));
            } else if (addClosedTransactionData) {
                index = getTrxIndex(addClosedTransactionData, 'closed');
                if (index < 0) closedTransactions.push(addClosedTransactionData);
                yield put(storeClosedTransactions(closedTransactions));
            } else if (changeClosedTransactionData) {
                index = getTrxIndex(changeClosedTransactionData, 'closed');
                if (index < 0) {
                    closedTransactions.push(changeClosedTransactionData);
                } else {
                    Object.assign(closedTransactions[index], changeClosedTransactionData);
                }
                yield put(storeClosedTransactions(closedTransactions));
            } else if (removeClosedTransactionData) {
                index = getTrxIndex(removeClosedTransactionData, 'closed');
                if (index >= 0) closedTransactions.splice(index, 1);
                yield put(storeClosedTransactions(closedTransactions));
            } else if (addArchivedTransactionData) {
                index = getTrxIndex(addArchivedTransactionData, 'archived');
                if (index < 0) archivedTransactions.push(addArchivedTransactionData);
                yield put(storeArchivedTransactions(archivedTransactions));
            } else if (removeArchivedTransactionData) {
                index = getTrxIndex(removeArchivedTransactionData, 'archived');
                if (index >= 0) archivedTransactions.splice(index, 1);
                yield put(storeArchivedTransactions(archivedTransactions));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('Transactions Error: getting active/closed/archived transactions (RTDB)', {
            error,
            user
        });
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Get Transaction Details /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* transactionDetailsWatch({ payload }) {
    const { trxId, detailsFlag } = payload;
    let unsubscribeTransactionDetails;
    const transactionDetailsChannel = eventChannel(emit => {
        unsubscribeTransactionDetails = transactions
            .doc(trxId)
            .onSnapshot(function (doc) {
                if (doc) {
                    emit(doc.data());
                } else {
                    doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeTransactionDetails;
    });

    try {
        while (true) {
            const { cancelledDetails, userSignOut, transactionDetails } = yield race({
                userSignOut: take(LOGOUT_USER),
                cancelledDetails: take(CANCEL_DETAILS_LISTENER),
                transactionDetails: take(transactionDetailsChannel)
            });

            if (cancelledDetails || userSignOut) {
                transactionDetailsChannel.close(); // Detach saga event emitter
            } else {
                yield put(settingTransactionDetails({ transactionDetails, detailsFlag }));
                // yield fork(
                //     refreshingTransactionDocuments,
                //     transactionDetails.documents,
                //     transactionDetails.id
                // );
            }
        }
    } catch (error) {
        log('Transactions Error: getting transaction details collection data (FS)', {
            error,
            trxId,
            detailsFlag
        });
    } finally {
        unsubscribeTransactionDetails(); // Detach firebase listener
        if (yield cancelled()) {
            transactionDetailsChannel.close(); // Detach saga event emitter
            unsubscribeTransactionDetails(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Form New Transactions ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getExistingParties = async ({ parties, user }) => {
    const processedParties = [];
    return new Promise((resolve, reject) => {
        parties.forEach((party, index) => {
            if (party.email !== '') {
                users
                    .where('email', '==', party.email)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.size > 0) {
                            querySnapshot.forEach(function (doc) {
                                const obj = Object.assign(parties[index], {
                                    ...parties[index],
                                    id: doc.id,
                                    pipeline_id: null,
                                    phone: doc.data().phone
                                        ? parties[index].phone !== ''
                                            ? parties[index].phone
                                            : doc
                                                  .data()
                                                  .phone.filter(
                                                      phone => phone.primary === true
                                                  )[0].number
                                        : parties[index].phone,
                                    legal_name: doc.data().legal_name
                                        ? parties[index].fullLegalName !== ''
                                            ? parties[index].fullLegalName
                                            : doc.data().legal_name
                                        : parties[index].fullLegalName
                                });
                                processedParties.push(obj);
                                if (processedParties.length === parties.length) {
                                    resolve({ processedParties });
                                }
                            });
                        } else {
                            const userPipeLineRef = rtdb
                                .ref(`pipeline/${user.id}`)
                                .push();
                            const tempId = userPipeLineRef.key;
                            const obj = Object.assign(parties[index], {
                                ...parties[index],
                                id: null,
                                pipeline_id: tempId
                            });
                            processedParties.push(obj);
                            if (processedParties.length === parties.length) {
                                resolve({ processedParties });
                            }
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } else {
                const userPipeLineRef = rtdb.ref(`pipeline/${user.id}`).push();
                const tempId = userPipeLineRef.key;
                const obj = Object.assign(parties[index], {
                    ...parties[index],
                    id: null,
                    pipeline_id: tempId
                });
                processedParties.push(obj);
                if (processedParties.length === parties.length) {
                    resolve({ processedParties });
                }
            }
        });
    });
};

const tsFromJsDate = date => {
    return timeStampJs.fromDate(date);
};

const formTaskDocTs = index => {
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + index);
    const ts = tsFromJsDate(currentDate);
    return ts;
};

const formTaskDate = (expire, date) => {
    const taskDate = date ? date : new Date();
    taskDate.setDate(taskDate.getDate() + expire.days);
    taskDate.setHours(expire.hours, expire.mins, 0, 0);
    return taskDate;
};

const buildTaskArray = ({ tasks, startDate }) => {
    return new Promise(resolve => {
        const date = startDate ? startDate : new Date();
        const formattedTasks =
            tasks && tasks.length
                ? tasks.map((task, index) => {
                      var dateCopy = new Date(date.getTime());
                      const taskDate = formTaskDate(task.expiration, dateCopy);
                      const taskTs = tsFromJsDate(taskDate);
                      const formatTask = Object.assign(task, {
                          ...task,
                          created_at: formTaskDocTs(index),
                          date_time: taskTs
                      });
                      return formatTask;
                  })
                : null;
        resolve(formattedTasks);
    });
};

const buildParties = parties => {
    return new Promise(resolve => {
        const formattedParties = [];
        parties.forEach(party => {
            const formatParty = Object.assign(
                {},
                {
                    id: party.id ? party.id : party.pipeline_id,
                    first_name: party.firstName,
                    last_name: party.lastName,
                    legal_name: party.fullLegalName,
                    email: party.email,
                    address: party.address,
                    address_2: party.address2,
                    phone: party.phone,
                    city: party.city,
                    state: party.state,
                    zip: party.zip,
                    pipeline: party.pipeline_id ? true : false,
                    invited: false,
                    invite_accepted: false,
                    invited_at: null
                }
            );
            formattedParties.push(formatParty);
            if (formattedParties.length === parties.length) {
                resolve(formattedParties);
            }
        });
    });
};

const buildActivity = ({ tasks, docs, ts, user }) => {
    return new Promise(resolve => {
        const activity = [
            {
                archived_at: null,
                attachments: [],
                automated: null,
                created_at: ts,
                creator_id: null,
                creator_type: 'system',
                edited_at: null,
                first_name: 'Jada',
                last_name: null,
                message:
                    'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
            }
        ];

        if (tasks && tasks.length) {
            tasks.forEach((task, index) => {
                const formatTask = Object.assign(
                    {},
                    {
                        archived_at: null,
                        attachments: [],
                        automated: {
                            type: 'task',
                            name: task.title
                        },
                        created_at: formTaskDocTs(index + 1),
                        creator_id: user.id,
                        creator_type: 'user',
                        edited_at: null,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        message: `Added a new task: `
                    }
                );
                activity.push(formatTask);
            });
        }

        if (docs && docs.length) {
            const i = tasks && tasks.length ? tasks.length : 0;
            docs.forEach((doc, index) => {
                const formatDoc = Object.assign(
                    {},
                    {
                        archived_at: null,
                        attachments: [
                            {
                                created_at: doc.created_at,
                                edited_at: doc.edited_at,
                                id: doc.id,
                                status: doc.status,
                                title: doc.title,
                                type: doc.type,
                                url: doc.url
                            }
                        ],
                        automated: {
                            type: 'document',
                            name: doc.title
                        },
                        created_at: formTaskDocTs(index + i),
                        creator_id: user.id,
                        creator_type: 'user',
                        edited_at: null,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        message: `Added a new document: `
                    }
                );
                activity.push(formatDoc);
            });
        }
        resolve(activity);
    });
};

const buildTransactionObjectRequest = async ({
    propertyData,
    formData,
    userData,
    org,
    tasks,
    docs
}) => {
    const docRef = transactions.doc();
    const tsNow = timeStampNow();

    const formattedTasks = await buildTaskArray({
        tasks,
        startDate: formData.trxMeta.fromDate
    });

    const formattedParties = await buildParties(formData.parties);
    const formatActivity = await buildActivity({
        tasks,
        docs,
        ts: tsNow,
        user: userData
    });

    const prefPath = userData.preferences[userData.active_org_id];
    const userSrc = prefPath[userData.active_location_id].sources;
    const orgSrc = org.preferences[userData.active_location_id].sources;
    const team = prefPath[userData.active_location_id].team;

    const checkNewSource = () => {
        const foundUserSrc =
            userSrc.indexOf(formData.trxMeta.businessSrc.value.toUpperCase()) > -1;
        const foundOrgSrc =
            orgSrc.indexOf(formData.trxMeta.businessSrc.value.toUpperCase()) > -1;
        if (foundUserSrc || foundOrgSrc) {
            return false;
        } else {
            return true;
        }
    };

    const orgData = userData.orgs.filter(org => {
        return org.id === userData.active_org_id;
    });

    const userSubType = orgData[0].sub_type;
    const userTitle = orgData[0].title;
    const partyUids = formData.parties.map(party =>
        party.id ? party.id : party.pipeline_id
    );
    const teamUids = team && team.length ? team.map(member => member.id) : [];

    const emptyUserData = false;

    const primaryBroker = () => {
        if (userSubType !== 'broker' && team.length) {
            const brokerData = team.filter(member => {
                return member.type === 'broker';
            });
            if (brokerData.length) {
                return {
                    email: brokerData[0].email,
                    first_name: brokerData[0].first_name,
                    id: brokerData[0].id,
                    last_name: brokerData[0].last_name,
                    phone: brokerData[0].phone
                };
            } else {
                return emptyUserData;
            }
        } else {
            return emptyUserData;
        }
    };

    const primaryAgent = () => {
        if (userSubType === 'agent') {
            const agentData = userData;
            return {
                email: agentData.email,
                first_name: agentData.first_name,
                id: agentData.id,
                last_name: agentData.last_name,
                phone: agentData.phone
                    ? agentData.phone.filter(phone => phone.primary === true)[0].number
                    : null
            };
        } else {
            return emptyUserData;
        }
    };

    const primaryUserData = primaryAgent() ? primaryAgent() : primaryBroker();

    const trxIncome = {
        commission: formData.trxMeta.commission,
        commission_percent: formData.trxMeta.commissionPercent,
        split: formData.trxMeta.split,
        split_percent: formData.trxMeta.splitPercent,
        trx_fee: formData.trxMeta.fee,
        trx_fee_percent: formData.trxMeta.feePercent
    };

    const trxObject = {
        address: {
            address_1: propertyData.location.address.address_1,
            address_2: propertyData.location.address.unit
                ? propertyData.location.address.unit
                : null,
            city: propertyData.location.address.city,
            latitude:
                propertyData.location.address.latitude ||
                propertyData.location.address.coordinate.lat ||
                '',
            longitude:
                propertyData.location.address.longitude ||
                propertyData.location.address.coordinate.lon ||
                '',
            state: propertyData.location.address.state,
            zip: propertyData.location.address.postal_code
        },
        avatars: [userData.id, ...partyUids, ...teamUids],
        closing_date: formData.trxMeta.closingDate
            ? tsFromJsDate(formData.trxMeta.closingDate)
            : null,
        created_at: tsNow,
        display: propertyData?.mlsSpec?.Media.length
            ? propertyData.mlsSpec.Media[0].MediaURL
            : 'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
        documents: [],
        id: docRef.id,
        income: trxIncome,
        listing_from: formData.trxMeta.fromDate
            ? tsFromJsDate(formData.trxMeta.fromDate)
            : null,
        listing_to: formData.trxMeta.toDate
            ? tsFromJsDate(formData.trxMeta.toDate)
            : null,
        location_id: userData.active_location_id,
        mls: formData.trxMeta.mlsNumber,
        notes: [],
        opposing: [],
        org_id: userData.active_org_id,
        parties: formattedParties,
        party_ids: partyUids,
        photos: propertyData?.mlsSpec?.Media?.length
            ? [...propertyData?.mlsSpec?.Media]
            : [],
        price: formData.trxMeta.listingPrice,
        primary_agent: primaryAgent(),
        primary_broker: primaryBroker(),
        primary_client: {
            email: formData.parties[0].email,
            first_name: formData.parties[0].firstName,
            id: formData.parties[0].id
                ? formData.parties[0].id
                : formData.parties[0].pipeline_id,
            last_name: formData.parties[0].lastName,
            phone: formData.parties[0].phone
        },
        primary_opposing: emptyUserData,
        prop_sub_type: null,
        // prop_type: address.metaData.rdi.toLowerCase(), //comm
        source: formData.trxMeta.businessSrc.value,
        status: propertyData?.mlsSpec?.StandardStatus
            ? propertyData?.mlsSpec?.StandardStatus.toLowerCase()
            : 'active',
        tasks: formattedTasks,
        team: [{ ...primaryUserData, title: userTitle, type: userSubType }, ...team],
        team_ids: [userData.id, ...teamUids],
        type: formData.trxMeta.type,
        under_contract_date: formData.trxMeta.underContractDate
            ? tsFromJsDate(formData.trxMeta.underContractDate)
            : null
    };
    return { trxObject, newSource: checkNewSource(), activity: formatActivity };
};

const fsWrite = async transaction => {
    return transactions
        .doc(transaction.id)
        .set(transaction)
        .then(() => {
            return { fsWriteSuccess: true };
        })
        .catch(error => {
            return { fsError: error };
        });
};

const rtdbWrite = ({ transaction, userData, activity }) => {
    return new Promise((resolve, reject) => {
        const {
            org_id,
            team_ids,
            party_ids,
            address,
            display,
            id,
            location_id,
            mls,
            price,
            primary_agent,
            primary_broker,
            primary_client,
            status,
            type,
            parties
        } = transaction;

        const trxRef = rtdb.ref(`transactions/${org_id}/active/${id}`);
        const trxActivityRef = rtdb.ref(`trx_activity/${id}`);
        const trxMemberIds = [...team_ids, ...party_ids];

        const rtdbTransaction = async () => {
            return await new Promise((resolve, reject) => {
                let transaction = {
                    address,
                    display: display ? display : null,
                    id: id,
                    location_id,
                    mls: mls ? mls : null,
                    org_id,
                    price,
                    primary_agent: primary_agent ? primary_agent : null,
                    primary_broker: primary_broker ? primary_broker : null,
                    primary_client,
                    status,
                    type
                };
                trxMemberIds.forEach((memberId, index) => {
                    transaction = Object.assign(transaction, {
                        ...transaction,
                        [memberId]: true
                    });
                    if (index + 1 === trxMemberIds.length) {
                        trxRef.set({ ...transaction }, error => {
                            if (error) {
                                reject({ error });
                            } else {
                                resolve(true);
                            }
                        });
                    }
                });
            });
        };

        const rtdbActivity = async () => {
            return await new Promise((resolve, reject) => {
                activity.forEach((post, index) => {
                    const postRef = trxActivityRef.push();
                    postRef.set({ ...post }, error => {
                        if (error) {
                            reject({ error });
                        } else {
                            if (index + 1 === activity.length) {
                                resolve(true);
                            }
                        }
                    });
                });
            });
        };

        const rtdbPipeLine = async () => {
            return await new Promise((resolve, reject) => {
                parties.forEach((party, index) => {
                    const contactRef = party.pipeline
                        ? rtdb.ref(`pipeline/${userData.id}/${party.id}`)
                        : rtdb.ref(`connections/${userData.id}/${party.id}`);
                    contactRef.set(
                        {
                            address: address,
                            email: party.email,
                            id: party.id,
                            first_name: party.first_name,
                            last_name: party.last_name,
                            phone: party.phone,
                            connection:
                                parties.length === 2
                                    ? {
                                          first_name:
                                              parties[index === 0 ? 1 : 0].first_name,
                                          last_name:
                                              parties[index === 0 ? 1 : 0].last_name,
                                          id: parties[index === 0 ? 1 : 0].id
                                      }
                                    : null
                        },
                        error => {
                            if (error) {
                                reject({ error });
                            } else {
                                if (index + 1 === parties.length) {
                                    resolve(true);
                                }
                            }
                        }
                    );
                });
            });
        };

        if (rtdbTransaction() && rtdbActivity() && rtdbPipeLine()) {
            resolve({ rtdbWriteSuccess: true });
        }
    });
};

const writeNewTransactionRequest = async ({ transaction, userData, activity }) => {
    const { fsWriteSuccess, fsError } = await fsWrite(transaction);
    const { rtdbWriteSuccess, rtdbError } = await rtdbWrite({
        transaction,
        userData,
        activity
    });
    if (fsWriteSuccess && rtdbWriteSuccess) {
        return { newTrxId: transaction.id };
    } else if (fsError || rtdbError) {
        return {
            error: {
                fsError,
                rtdbError
            }
        };
    }
};

const writeNewSrcRequest = ({ source, userData }) => {
    const ref = users.doc(userData.id);
    const org = userData.active_org_id;
    const location = userData.active_location_id;
    const path = `preferences.${org}.${location}.sources`;
    const existingSources = userData.preferences[org][location].sources;
    const newSources = [...existingSources, source.toUpperCase()];

    return new Promise((resolve, reject) => {
        ref.update({ [path]: newSources })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* formNewTransactions({ payload }) {
    const { propertyData, userData, tasks } = payload;
    let formData = { ...payload.formData };
    const org = yield select(selectors._activeOrg);
    const { processedParties, error } = yield call(() =>
        getExistingParties({ parties: formData.parties, user: userData })
    );

    if (processedParties) {
        formData = Object.assign(formData, {
            parties: [...processedParties]
        });

        const { trxObject, newSource, activity, error } = yield call(() =>
            buildTransactionObjectRequest({
                propertyData,
                formData,
                userData,
                org,
                tasks
            })
        );

        if (trxObject) {
            const { newTrxId, error } = yield call(() =>
                writeNewTransactionRequest({ transaction: trxObject, userData, activity })
            );
            if (newTrxId) {
                yield put(transactionWriteSuccess(newTrxId));
            } else {
                // Error Handling for sentry with put and maybe UI message
                log('Transactions Error: error writing new transaction (FS)', {
                    error,
                    trxObject
                });
            }
            if (newSource) {
                const { res, error } = yield call(() =>
                    writeNewSrcRequest({
                        source: formData.trxMeta.businessSrc.value,
                        userData
                    })
                );
                if (res) {
                    // Do nothing
                } else {
                    log(
                        'Transactions Error: error writing new user business sources (FS)',
                        {
                            error,
                            source: formData.trxMeta.businessSrc.value
                        }
                    );
                }
                // write new sources to user data object
            }
        } else {
            // Error Handling for sentry with put and maybe UI message
            log(
                'Transactions Error: error building trx object in forming new transaction',
                {
                    error,
                    processedParties,
                    formData
                }
            );
        }
    } else {
        // Error Handling for sentry with put and maybe UI message
        log(
            'Transactions Error: error processing transaction parties in forming new transaction',
            {
                error,
                payload
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Transaction Note /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionNoteRequest = async ({ notes, trxId }) => {
    return new Promise((resolve, reject) => {
        transactions
            .doc(trxId)
            .update({ notes: notes })
            .then(() => {
                resolve({ writtenNote: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionNote({ payload }) {
    const { notes, trxId } = payload;
    const { writtenNote, error } = yield call(() =>
        updateTransactionNoteRequest({ notes, trxId })
    );
    if (writtenNote) {
        yield put(noteWriteSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log(`Transactions Error: updating transaction notes @ trx: ${trxId} (FS)`, {
            error,
            notes,
            trxId
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Transaction Task /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionTaskRequest = async ({ tasks, trxId, posts }) => {
    const activityRef = rtdb.ref(`trx_activity/${trxId}`);
    const postRef = activityRef.push();
    return new Promise((resolve, reject) => {
        transactions
            .doc(trxId)
            .update({ tasks: tasks })
            .then(() => {
                postRef.set({ ...posts }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ writtenTask: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionTask({ payload }) {
    const { tasks, trxId, posts } = payload;
    const { writtenTask, error } = yield call(() =>
        updateTransactionTaskRequest({ tasks, trxId, posts })
    );
    if (writtenTask) {
        yield put(taskWriteSuccess());
    } else {
        yield put(taskWriteFailure());
        log(`Transactions Error: updating transaction tasks @ trx: ${trxId} (FS)`, {
            error,
            tasks,
            trxId,
            posts
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Add Activity Text Post /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateActivityPostRequest = async ({ posts, trxId }) => {
    return new Promise((resolve, reject) => {
        const activityRef = rtdb.ref(`trx_activity/${trxId}`);
        const postRef = activityRef.push();
        postRef.set({ ...posts }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ writtenPost: true });
            }
        });
        // transactions
        //     .doc(trxId)
        //     .update({ activity: fsFieldValue.arrayUnion() })
        //     .then(() => {
        //         resolve({ writtenPost: true });
        //     })
        //     .catch(error => {
        //         reject({ error });
        //     });
    });
};

export function* updateActivityPost({ payload }) {
    const { posts, trxId } = payload;
    const attachment = posts?.attachments?.length ? posts.attachments[0] : null;
    const { writtenPost, writtenFile, error } = yield call(() =>
        attachment
            ? uploadingTransactionFileRequest({ file: attachment, trxId })
            : updateActivityPostRequest({ posts, trxId })
    );
    if (writtenPost) {
        yield put(postWriteSuccess());
    } else if (writtenFile) {
        const data = posts;
        const attachments = [
            {
                id: writtenFile[1],
                path: writtenFile[0],
                title: attachment.title ? attachment.title : attachment.src.name,
                type: attachment.file_type
            }
        ];
        data.attachments = attachments;
        const { writtenPost, error } = yield call(() =>
            updateActivityPostRequest({ posts: data, trxId })
        );
        if (writtenPost) {
            yield put(postWriteSuccess());
        } else {
            log(
                `Transactions Error: adding activity post with attachment @ trx: ${trxId} (FS)`,
                {
                    error,
                    posts,
                    trxId
                }
            );
        }
    } else {
        log(
            `Transactions Error: adding activity post no attachment @ trx: ${trxId} (FS)`,
            {
                error,
                posts,
                trxId
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Upload Transaction Storage File /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const uploadingTransactionFileRequest = async ({ file, trxId }) => {
    const id = generateFirestoreUid();
    const storageRef = storage.ref();
    const fileRef = storageRef
        .child('transactions')
        .child(trxId)
        .child(file.storage_type)
        .child(id);
    try {
        const snapshot = await fileRef.put(file.src);
        const url = await snapshot.ref.getDownloadURL();
        return { writtenFile: [url, id] };
    } catch (error) {
        return { error };
    }
};

const generateFirestoreUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Transaction Status /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionStatusRequest = async ({
    oldStatus,
    newStatus,
    trxId,
    date,
    userData,
    transaction
}) => {
    const activity = {
        archived_at: null,
        attachments: [],
        automated: {
            name: newStatus
        },
        created_at: timeStampNow(),
        creator_id: userData.id,
        creator_type: 'user',
        edited_at: null,
        first_name: userData.first_name,
        last_name: userData.last_name,
        message: `Changed transaction status to `
    };

    return new Promise((resolve, reject) => {
        if (!Object.values(transaction).length) {
            reject({ error: 'Transaction object is empty' });
        } else {
            transactions
                .doc(trxId)
                .update({
                    status: newStatus,
                    closing_date:
                        newStatus === trxStatus.closed
                            ? timeStampJs.fromDate(new Date(date))
                            : ''
                })
                .then(() => {
                    if (
                        !(
                            newStatus === trxStatus.pending &&
                            oldStatus === trxStatus.active
                        ) &&
                        !(
                            newStatus === trxStatus.active &&
                            oldStatus === trxStatus.pending
                        )
                    ) {
                        return rtdb
                            .ref(
                                newStatus === trxStatus.pending
                                    ? `transactions/${userData.active_org_id}/active/${trxId}`
                                    : `transactions/${userData.active_org_id}/${newStatus}/${trxId}`
                            )
                            .set(
                                {
                                    ...transaction,
                                    status: newStatus,
                                    closing_date:
                                        newStatus === trxStatus.closed
                                            ? timeStampJs.fromDate(new Date(date))
                                            : ''
                                },
                                error => {
                                    if (error) {
                                        reject({ error });
                                    }
                                }
                            );
                    }
                })
                .then(() => {
                    if (
                        !(
                            newStatus === trxStatus.pending &&
                            oldStatus === trxStatus.active
                        ) &&
                        !(
                            newStatus === trxStatus.active &&
                            oldStatus === trxStatus.pending
                        )
                    ) {
                        return rtdb
                            .ref(
                                oldStatus === trxStatus.pending
                                    ? `transactions/${userData.active_org_id}/active/${trxId}`
                                    : `transactions/${userData.active_org_id}/${oldStatus}/${trxId}`
                            )
                            .remove();
                    } else {
                        return rtdb
                            .ref(`transactions/${userData.active_org_id}/active/${trxId}`)
                            .update({ status: newStatus });
                    }
                })
                .then(() => {
                    const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                    const postRef = activityRef.push();
                    postRef.set({ ...activity });
                })
                .then(() => {
                    resolve({ updatedStatus: true });
                })
                .catch(error => {
                    reject({ error });
                });
        }
    });
};

export function* updateTransactionStatus({ payload }) {
    const { oldStatus, newStatus, trxId, userData } = payload;

    const chooseModalType = () => {
        switch (newStatus) {
            case trxStatus.active:
                return oldStatus === trxStatus.closed
                    ? confirmationDialogTypes.reopenTrx
                    : confirmationDialogTypes.activateTrx;
            case trxStatus.archived:
                return confirmationDialogTypes.archiveTrx;
            case trxStatus.closed:
                return confirmationDialogTypes.closeTrx;
            default:
                return confirmationDialogTypes.reopenTrx;
        }
    };

    const { isConfirm, dataFromModal } =
        (newStatus === trxStatus.pending && oldStatus !== trxStatus.closed) ||
        (oldStatus === trxStatus.pending && newStatus === trxStatus.active)
            ? { isConfirm: true }
            : yield call(confirmSaga, {
                  modalType: chooseModalType()
              });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));

        const selector =
            oldStatus === trxStatus.pending
                ? `_activeTransactions`
                : `_${oldStatus}Transactions`;
        const transactions = yield select({ ...selectors }[selector]);
        const currentTransactionFromRtdb = transactions.find(el => el.id === trxId);

        const { updatedStatus, error } = yield call(() =>
            updateTransactionStatusRequest({
                oldStatus,
                newStatus,
                trxId,
                date: dataFromModal,
                userData,
                transaction: currentTransactionFromRtdb
            })
        );
        if (updatedStatus) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            // Error Handling for sentry with put and maybe UI message
            log(`Transactions Error: updating transaction status @ trx: ${trxId} (FS)`, {
                error,
                oldStatus,
                newStatus,
                trxId
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Transaction Details /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionDetailsRequest = async ({ trxId, values, trxStatus, userData }) =>
    new Promise((resolve, reject) => {
        transactions
            .doc(trxId)
            .update(values)
            .then(() => {
                return rtdb
                    .ref(`transactions/${userData.active_org_id}/${trxStatus}/${trxId}`)
                    .update({ price: values.price });
            })
            .then(() => {
                resolve({ updatedDetails: true });
            })
            .catch(error => {
                reject({ error });
            });
    });

export function* updateTransactionDetails({ payload }) {
    const { trxDetails, values, userData } = payload;

    const { updatedDetails, error } = yield call(() =>
        updateTransactionDetailsRequest({
            trxId: trxDetails.id,
            trxStatus: trxDetails.status,
            values,
            userData
        })
    );
    if (updatedDetails) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        // Error Handling for sentry with put and maybe UI message
        log(
            `Transactions Error: updating transaction details @ trx: ${trxDetails.id} (FS)`,
            {
                error,
                trxId: trxDetails.id
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getUserTransactions() {
    yield takeLatest(GET_USER_TRANSACTIONS, transactionCollectionWatch);
}

export function* formingNewTransactions() {
    yield takeLatest(CREATE_TRANSACTION, formNewTransactions);
}

export function* updatingNewTransactionNote() {
    yield takeLatest(UPDATE_TRANSACTION_NOTE, updateTransactionNote);
}

export function* updatingNewTransactionTask() {
    yield takeLatest(UPDATE_TRANSACTION_TASK, updateTransactionTask);
}

export function* updatingNewTransactionPosts() {
    yield takeLatest(UPDATE_ACTIVITY_POST, updateActivityPost);
}

export function* gettingTransactionDetails() {
    yield takeLatest(
        [GET_TRANSACTION_DETAILS, SET_CLIENT_TRANSACTION_DETAILS],
        transactionDetailsWatch
    );
}

export function* updatingTransactionStatus() {
    yield takeLatest(UPDATE_TRANSACTION_STATUS, updateTransactionStatus);
}

export function* updatingTransactionDetails() {
    yield takeLatest(UPDATE_TRANSACTION_DETAILS, updateTransactionDetails);
}

export default function* rootSaga() {
    yield all([
        fork(getUserTransactions),
        fork(formingNewTransactions),
        fork(updatingNewTransactionNote),
        fork(updatingNewTransactionTask),
        fork(updatingNewTransactionPosts),
        fork(gettingTransactionDetails),
        fork(updatingTransactionStatus),
        fork(updatingTransactionDetails)
    ]);
}

// const test = {
//     // Buyer & Seller
//     activity: [
//         {
//             archived_at: null,
//             attachments: [],
//             created_at: 'time_stamp',
//             creator_id: null,
//             creator_type: 'system',
//             edited_at: null,
//             first_name: 'Jada',
//             last_name: null,
//             message:
//                 'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
//         }
//     ],
//     // Buyer & Seller
//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     // Buyer & Seller
//     avatars: [],
//     // Buyer & Seller
//     closing_date: null,
//     // Buyer & Seller
//     created_at: 'timestamp',
//     // Buyer & Seller
//     display:
//         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
//     // Buyer & Seller
//     documents: [
//         // {
//         //     created_at: 'timestamp',
//         //     edited_at: null,
//         //     id: 'vT0tSVYy6i5TVX4leBXa',
//         //     status: 'incomplete',
//         //     title: 'Purchase and Sale Pack',
//         //     type: 'library',
//         //     url:
//         //         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fdocuments%2Ftestpdf.pdf?alt=media&token=bc1a2a5a-2198-4de8-bec3-d650a1994205'
//         // }
//     ],
//     // Buyer & Seller
//     id: 'Vhiju4PRLu9CnpPyLaFs', // trx id after creation
//     // Buyer & Seller
//     income: {
//         commission: 3,
//         commission_percent: true,
//         split: 80,
//         split_percent: true,
//         trx_fee: 195
//     },
//     // Buyer & Seller
//     location_id: 1,
//     // Buyer possible
//     mls: '2238745',
//     // Buyer & Seller
//     notes: [],
//     // Buyer & Seller
//     opposing: [],
//     // Buyer & Seller
//     org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//     // Buyer & Seller
//     parties: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: ''
//         }
//     ],
//     // Buyer & Seller
//     party_ids: [],
//     // Buyer & Seller
//     photos: [
//         {
//             created_at: 'timestamp',
//             source: 'upload',
//             url: ''
//         }
//     ],
//     // Buyer & Seller
//     price: '',
//     // Buyer & Seller
//     primary_agent: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_broker: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     primary_client: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_opposing: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     prop_sub_type: '',
//     // Buyer & Seller
//     prop_type: 'resi', //comm
//     // Buyer & Seller
//     source: '',
//     // Buyer & Seller
//     status: 'active',
//     // Buyer & Seller
//     tasks: [],
//     // Buyer & Seller
//     team: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: '',
//             title: '',
//             type: ''
//         }
//     ],
//     // Buyer & Seller
//     team_ids: [],
//     // Buyer & Seller
//     type: ''
// };

// const test2 = {
//     active: true,
//     active_location_id: null,
//     active_org_id: null,

//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     agent_lic: null,
//     broker_lic: null,
//     designation: null,
//     email: '',
//     facebook: null,
//     first_name: '',
//     id: '',
//     instagram: null,
//     language: 'english',
//     last_name: '',
//     linkedin: null,
//     orgs: null,
//     phone: [
//         {
//             number: '',
//             primary: null,
//             type: null
//         }
//     ],
//     role: 'user',
//     twitter: null,
//     type: 'client',
//     user_avatar: null,
//     website: null
// };

// const testjhjn = {
//     // Buyer & Seller
//     activity: [
//         {
//             archived_at: null,
//             attachments: [],
//             created_at: 'time_stamp',
//             creator_id: null,
//             creator_type: 'system',
//             edited_at: null,
//             first_name: 'Jada',
//             last_name: null,
//             message:
//                 'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
//         }
//     ],
//     // Buyer & Seller
//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     // Buyer & Seller
//     avatars: [], //Microservice
//     // Buyer & Seller
//     closing_date: null,
//     // Buyer & Seller
//     created_at: 'timestamp',
//     // Buyer & Seller
//     display:
//         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
//     // Buyer & Seller
//     documents: [
//         // {
//         //     created_at: 'timestamp',
//         //     edited_at: null,
//         //     id: 'vT0tSVYy6i5TVX4leBXa',
//         //     status: 'incomplete',
//         //     title: 'Purchase and Sale Pack',
//         //     type: 'library',
//         //     url:
//         //         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fdocuments%2Ftestpdf.pdf?alt=media&token=bc1a2a5a-2198-4de8-bec3-d650a1994205'
//         // }
//     ],
//     // Buyer & Seller
//     id: 'Vhiju4PRLu9CnpPyLaFs', // trx id after creation
//     // Buyer & Seller
//     income: {
//         commission: 3,
//         commission_percent: true,
//         split: 80,
//         split_percent: true,
//         trx_fee: 195
//     },
//     // Buyer & Seller
//     location_id: 1,
//     // Buyer possible
//     mls: '2238745',
//     // Buyer & Seller
//     notes: [],
//     // Buyer & Seller
//     opposing: [],
//     // Buyer & Seller
//     org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//     // Buyer & Seller
//     parties: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: ''
//         }
//     ],
//     // Buyer & Seller
//     party_ids: [],
//     // Buyer & Seller
//     photos: [
//         {
//             created_at: 'timestamp',
//             source: 'upload',
//             url: ''
//         }
//     ],
//     // Buyer & Seller
//     price: '',
//     // Buyer & Seller
//     primary_agent: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_broker: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     primary_client: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_opposing: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     prop_sub_type: '',
//     // Buyer & Seller
//     prop_type: 'resi', //comm
//     // Buyer & Seller
//     source: '',
//     // Buyer & Seller
//     status: 'active',
//     // Buyer & Seller
//     tasks: [],
//     // Buyer & Seller
//     team: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: '',
//             title: '',
//             type: ''
//         }
//     ],
//     // Buyer & Seller
//     team_ids: [],
//     // Buyer & Seller
//     type: ''
// };
