// React
import React, { useState, useEffect, useMemo } from 'react';

// Components
import { TransactionCard, TransactionRow } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { isTablet } from 'react-device-detect';

const TransactionsList = ({
    transactionList,
    match,
    filteredTransactions,
    searchSuggestions
}) => {
    const [modVar, setModVar] = useState(4);
    const [cardMargin, setCardMargin] = useState('15px');
    const iPadProPixels = 2390;
    const { windowHeight, windowWidth } = useWindowSize();
    const totalWindowPixels = windowHeight + windowWidth;
    const { none_found_label } = useLang()['Transactions']['TransactionList'];
    useEffect(() => {
        const iPadRegular = isTablet && totalWindowPixels < iPadProPixels;
        const mod3 = windowWidth < 992 || iPadRegular ? 3 : false;
        if (mod3) {
            setCardMargin('13.25px');
            setModVar(3);
        } else {
            if ((isTablet && !iPadRegular) || (!isTablet && windowWidth < 1200)) {
                setCardMargin('12px');
            } else {
                setCardMargin('15px');
            }
            setModVar(4);
        }
    }, [windowHeight, windowWidth, totalWindowPixels]);

    const filterBySuggestions = useMemo(
        () => searchSuggestions.map(suggestion => suggestion.transaction),
        [searchSuggestions]
    );

    const transactions = useMemo(
        () => (searchSuggestions.length ? filterBySuggestions : filteredTransactions),
        [filteredTransactions, searchSuggestions, filterBySuggestions]
    );

    return (
        <div className="mb-4" style={{ width: '100%' }}>
            {transactions?.length ? (
                <>
                    {transactionList === 'grid' ? (
                        <div
                            className="card-deck-flex d-block d-md-flex"
                            style={{ justifyContent: 'flex-start' }}
                        >
                            {transactions.map(({ ...rest }, index) => (
                                <div
                                    className="card card-flex mb-5"
                                    key={rest.id}
                                    style={{
                                        marginRight:
                                            (index + 1) % modVar !== 0 ? cardMargin : 0,
                                        maxHeight: '404px',
                                        height: '404px'
                                    }}
                                >
                                    <TransactionCard match={match} {...rest} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {transactions.map(({ ...rest }) => (
                                <TransactionRow {...rest} match={match} key={rest.id} />
                            ))}
                        </>
                    )}
                </>
            ) : (
                <Alert color="info" className="mb-0 mt-2">
                    {none_found_label}
                </Alert>
            )}
        </div>
    );
};

const mapStateToProps = ({ transactions }) => {
    const {
        transactionList,
        filteredTransactions,
        searchSuggestions,
        loading,
        transactionDetails,
        detailsFlag
    } = transactions;
    return {
        transactionList,
        filteredTransactions,
        searchSuggestions,
        loading,
        transactionDetails,
        detailsFlag
    };
};

export default withRouter(connect(mapStateToProps)(TransactionsList));
