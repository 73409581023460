// React
import React from 'react';

// Packages
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { setConfirmModalType } from '../../store/actions/Modal';
import { confirmationDialogTypes } from '../../utils/Constants';

const NewAddHeader = ({ setConfirmModalType, shouldConfirmDisplayed, returnRoute }) => {
    const history = useHistory();

    //TODO: Uncomment if "Go Back" button is needed
    // const { back_button_label } = useLang()['NewTransaction']['NewTransactionHeader'];

    return (
        <header id="header" className="u-header u-header--sticky-top">
            <div className="bg-primary">
                <div className="container">
                    <div
                        id="lg-nav"
                        className="d-flex justify-content-between align-items-center"
                    >
                        {/* //TODO: Uncomment if "Go Back" button is needed
                          <div className="u-header u-header-left-aligned-nav u-header--bg-transparent-lg u-header--white-nav-links z-index-4">
                            <div className="u-header__section bg-transparent">
                                <nav className="navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space">
                                    <ul className="navbar-nav u-header__navbar-nav">
                                        <li className="nav-item u-header__nav-item">
                                            <button
                                                className="btn transition-3d-hover"
                                                style={{
                                                    color: 'white',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                    history.push(
                                                        `${routes.AUTHENTICATED}${routes.DASHBOARD}`
                                                    )
                                                }
                                            >
                                                <span className="fas fa-chevron-left mr-1" />
                                                {back_button_label}
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                        <button
                            type="button"
                            className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover sweet-1 ml-auto"
                            onClick={() => {
                                shouldConfirmDisplayed
                                    ? setConfirmModalType(confirmationDialogTypes.cancel)
                                    : history.push(returnRoute);
                            }}
                        >
                            <span className="fas fa-times" />
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default connect(null, { setConfirmModalType })(NewAddHeader);
