import {
    STORE_CONNECTION_MATCHES,
    CHECK_CONNECTION_EXISTENCE,
    SET_CONNECTION_MATCH,
    CLEAR_CONNECTION_LINK,
    CLEAR_CONNECTION_PRIMARY,
    ADD_NEW_CONNECTION,
    ADD_NEW_CONNECTION_SUCCESS,
    ADD_NEW_CONNECTION_FAILURE,
    RESET_NEW_CONNECTION_DATA,
    SET_USER_CONNECTIONS
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    userConnections: [],
    error: null,
    matches: null,
    primaryConnection: null,
    primaryEmail: null,
    primaryPhone: null,
    linkConnection: null,
    linkEmail: null,
    linkPhone: null
};

const connectionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_CONNECTIONS:
            return {
                ...state,
                userConnections: action.payload
            };
        case CHECK_CONNECTION_EXISTENCE:
            return {
                ...state,
                loading: true,
                matches: INITIAL_STATE.matches,
                primaryEmail:
                    action.payload.connection.type === 'primary'
                        ? action.payload.connection.email
                        : state.primaryEmail,
                primaryPhone:
                    action.payload.connection.type === 'primary'
                        ? action.payload.connection.phone
                        : state.primaryPhone,
                linkEmail:
                    action.payload.connection.type === 'link'
                        ? action.payload.connection.email
                        : state.linkEmail,
                linkPhone:
                    action.payload.connection.type === 'link'
                        ? action.payload.connection.phone
                        : state.linkPhone
            };
        case STORE_CONNECTION_MATCHES:
            return {
                ...state,
                loading: false,
                matches: action.payload
            };
        case SET_CONNECTION_MATCH:
            return {
                ...state,
                primaryConnection:
                    action.payload.type === 'primary'
                        ? action.payload.match
                        : state.primaryConnection,
                linkConnection:
                    action.payload.type === 'link'
                        ? action.payload.match
                        : state.linkConnection
            };
        case CLEAR_CONNECTION_LINK:
            return {
                ...state,
                linkConnection: INITIAL_STATE.linkConnection,
                linkEmail: INITIAL_STATE.linkEmail,
                linkPhone: INITIAL_STATE.linkPhone
            };
        case CLEAR_CONNECTION_PRIMARY:
            return {
                ...state,
                primaryConnection: INITIAL_STATE.primaryConnection,
                primaryEmail: INITIAL_STATE.primaryEmail,
                primaryPhone: INITIAL_STATE.primaryPhone
            };
        case ADD_NEW_CONNECTION:
            return {
                ...state,
                loading: true
            };
        case ADD_NEW_CONNECTION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ADD_NEW_CONNECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RESET_NEW_CONNECTION_DATA:
            return {
                ...state,
                loading: false,
                error: INITIAL_STATE.error,
                matches: INITIAL_STATE.matches,
                primaryConnection: INITIAL_STATE.primaryConnection,
                primaryEmail: INITIAL_STATE.primaryEmail,
                primaryPhone: INITIAL_STATE.primaryPhone,
                linkConnection: INITIAL_STATE.linkConnection,
                linkEmail: INITIAL_STATE.linkEmail,
                linkPhone: INITIAL_STATE.linkPhone
            };
        default:
            return state;
    }
};

export default connectionsReducer;
