// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Components
import { ConnectionRow } from '../../components';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { Alert } from 'reactstrap';

const ConnectionList = ({ match, userData, searchSuggestions, connections }) => {
    const { windowWidth } = useWindowSize();
    const connectionsList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : connections;
    const { no_connections_label } = useLang()['Connections']['ConnectionList'];

    if (!userData) return null;

    return (
        <div className="mb-4" style={{ width: '100%' }}>
            {connectionsList && connectionsList.length ? (
                <>
                    {connectionsList.map(connection => (
                        <ConnectionRow
                            match={match}
                            connection={connection}
                            key={
                                connection.id
                                    ? connection.id
                                    : `${connection.first_name}${connection.last_name}${connection.phone_number}${connection.email}`
                            }
                            windowWidth={windowWidth}
                        />
                    ))}
                </>
            ) : (
                <Alert color="info" className="mb-0">
                    {no_connections_label}
                </Alert>
            )}
        </div>
    );
};

export default ConnectionList;
