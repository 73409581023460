import React from 'react';

// Context
import { useLang } from '../../../context/LangContext';

// Components
import { TeamList } from './TeamList';
import ActionButtons from '../shared/ActionButtons';
import { connect } from 'react-redux';

const Team = ({ userData }) => {
    const { main_title, header, teamList } = useLang()['Settings']['Team'];
    const { save_label, cancel_label } = useLang()['Settings'];

    return (
        <>
            <div className="card-header d-flex align-items-center">
                <h1 className="col-6 h5 font-weight-medium mb-0">{main_title}</h1>
                <div className="col-6 d-flex align-items-center justify-content-end card-header__actions">
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center mr-2"
                        style={{ maxHeight: '36px' }}
                    >
                        {header.add_member_label}
                    </button>
                </div>
            </div>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '0',
                    paddingBottom: '0'
                }}
            >
                <TeamList lang={teamList} orgs={userData?.preferences} />
            </div>
            <ActionButtons saveLabel={save_label} cancelLabel={cancel_label} />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps)(Team);
