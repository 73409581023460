// React
import React from 'react';

// Components
import {
    CompanyContacts,
    Dashboard,
    TransactionDetails,
    NewTransaction,
    NewDocument,
    NewConnection,
    Messages,
    Preferences,
    ContactDetails,
    ConnectionDetails,
    PdfViewer,
    EditTransaction,
    Settings
} from '../pages';

// Packages
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Router
import * as routes from '../router/config/routes';
import { userTypes } from '../utils/Constants';

const AuthenticatedRouter = ({ userTransactions, userData, ...props }) => {
    return (
        <Switch>
            <Redirect
                exact
                from={`${props.match.url}`}
                to={
                    userData?.type === userTypes.client && userTransactions?.length === 1
                        ? `${props.match.url}${routes.TRANSACTION_DETAIL}/${userTransactions[0].id}`
                        : `${props.match.url}${routes.DASHBOARD}`
                }
            />
            <Route
                path={`${props.match.url}${routes.MESSAGES}`}
                render={props => <Messages {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.COMPANY_CONTACTS}`}
                render={props => <CompanyContacts {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONTACT_DETAIL}`}
                render={props => <ContactDetails {...props} />}
            />
            <Route
                authUser={props.authUser}
                path={`${props.match?.url}${routes.DASHBOARD}`}
                render={props => <Dashboard {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.EDIT_TRANSACTION}`}
                render={props => <EditTransaction {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONNECTION_DETAIL}`}
                render={props => <ConnectionDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_CONNECTION}`}
                render={props => <NewConnection {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_DOCUMENT}`}
                render={props => <NewDocument {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_TRANSACTION}`}
                render={props => <NewTransaction {...props} />}
            />

            <Route
                path={`${props.match.url}${routes.VIEW_DOCUMENT}`}
                render={props => <PdfViewer {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.PREFERENCES}`}
                render={props => <Preferences {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.SETTINGS}`}
                render={props => <Settings {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.TRANSACTION_DETAIL}`}
                render={props => <TransactionDetails {...props} />}
            />
        </Switch>
    );
};

const mapStateToProps = ({ transactions, user }) => {
    const { userTransactions } = transactions;
    const { userData } = user;
    return { userTransactions, userData };
};

export default withRouter(connect(mapStateToProps)(AuthenticatedRouter));
